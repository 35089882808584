import React, { useEffect, useState } from 'react';
import { Box, HStack, Pressable, Text } from 'native-base';
import { SizePixel } from '../../utils/pixcel.convert';
import BackIcon from '../../assets/icons/back.icon';
import { TouchableOpacity } from 'react-native';
import { Ar18SbBlack, Ar21Sb, Ar36B } from '../../themes/font.style';
import { useColors } from '../../hooks/useColors';
import { CreditCard } from '../collection/credit/credit.card';
import { PlusIconWithBold } from '../collection/credit.screen';
import useAxiosServices from '../../hooks/axiosHooks';
import { IPaymentListType } from '../../@types/payment.types';
import { useNavigate } from 'react-router-dom';
import { PageSetting } from '../../utils/pageSetting';
const PADDING = 32;
export const PaymentScreen = () => {
    const { mainColor } = useColors();
    const { axiosService } = useAxiosServices();
    const navigation = useNavigate();
    const [paypal, setPaypal] = useState<{ check: boolean; info: IPaymentListType | null }>({
        check: false,
        info: null,
    });
    const [card, setCard] = useState<{ check: boolean; info: IPaymentListType | null }>({
        check: false,
        info: null,
    });

    const getPaymentList = async () => {
        const api = await axiosService.post('/payment/list');
        const { status, data } = api.data;
        if (status) {
            const { paypalCheck, paypal, cardCheck, card } = data;
            setPaypal({
                check: paypalCheck,
                info: paypal,
            });
            setCard({
                check: cardCheck,
                info: card,
            });
        }
    };
    useEffect(() => {
        getPaymentList();
    }, []);
    return (
        <Box bg={'white.100'} flex={1} alignItems={'center'}>
            <Box w={'100%'}>
                <Box safeAreaTop pb={SizePixel(8)}>
                    <HStack
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mt={SizePixel(18.1)}
                        pl={SizePixel(20)}
                        pr={SizePixel(35)}
                    >
                        <Pressable
                            w={SizePixel(32)}
                            h={SizePixel(32)}
                            alignItems={'center'}
                            justifyContent={'center'}
                            onPress={() => navigation(-1)}
                            hitSlop={{ top: 30, left: 30, right: 30, bottom: 30 }}
                        >
                            <BackIcon />
                        </Pressable>
                    </HStack>
                </Box>
                <Box {...PageSetting} alignSelf={'center'} w={'100%'} px={SizePixel(PADDING)}>
                    <Text mt={SizePixel(59)} {...Ar36B} lineHeight={SizePixel(39.17)} color={'black.100'}>
                        Payment Methods
                    </Text>
                    <CreditCard modify={false} content={'PayPal'} creditType={'P'} onPress={() => null} />
                    {card.check ? (
                        <CreditCard
                            modify={false}
                            content={`**** **** ${card.info?.last4}`}
                            creditType={card.info?.brand as any}
                            onPress={() => navigation('/CollectionPayment')}
                        />
                    ) : (
                        <Pressable
                            flexDirection={'row'}
                            onPress={() => navigation('/CollectionPayment')}
                            w={'100%'}
                            h={SizePixel(88)}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Box flexDirection={'row'} alignItems={'center'}>
                                <PlusIconWithBold />
                                <Text ml={SizePixel(16)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)}>
                                    Add Credit or Debit Card
                                </Text>
                            </Box>
                        </Pressable>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
