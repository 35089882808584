import React from 'react';
import { Box, HStack, Image, Text } from 'native-base';
import { Linking, TouchableOpacity } from 'react-native';
import MenuIcon from '../../assets/icons/menu.icon';
import HelpIcon from '../../assets/icons/help.icon';
import { Img } from 'react-image';
interface Props {
    navigation: any;
    optional?: string;
    setOpenLink?: any;
    setSideOpen: any;
}

import MainLogo from '../../assets/icons/mainLogo.png';
import { SizePixel } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';
const MainHeader = ({ navigation, optional, setOpenLink, setSideOpen }: Props) => {
    const openLink = async (url: any) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
            await Linking.openURL(url);
        }
    };
    // openLink('https://recan.co')
    const { height, sizePix } = useWindowDimensions();

    return (
        <Box mt={sizePix(22.9)} mx={sizePix(18.5)}>
            <HStack alignItems={'center'} justifyContent={'space-between'}>
                <TouchableOpacity onPress={() => setSideOpen(true)}>
                    <MenuIcon />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation('/main')}>
                    <Img
                        style={{ objectFit: 'fill', marginTop: 1, width: sizePix(78), height: sizePix(21) }}
                        src={MainLogo}
                        alt={'mainLogo'}
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setOpenLink(true)}>
                    <HelpIcon />
                </TouchableOpacity>
            </HStack>
        </Box>
    );
};

export default MainHeader;
