import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ScreenWidth, SizePixel } from '../../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING } from '../index';
import { Box, ScrollView, Text } from 'native-base';
import { Ar21B, Ar36B } from '../../../themes/font.style';
import { useColors } from '../../../hooks/useColors';
import { bagType, IPhotoListTypes } from '../../../@types/collection.types';
import { addressListType } from '../../../@types/userTypes';
import { MapWithMarker } from '../../../components/map/map.withMarker';
import { BagCaseSwitch } from '../../../utils/helper/bag.helper';
import { DetailCard } from './detail.card';
import { DateIcon, PencilIcon, PetIcon, PhotoIcon, RecanBagIcon, RecyleIcon } from './detail.icons';
import { dateFormatDays2 } from '../../../utils/times';
import { ProductAllAmountRender, ProductAllQtyRender, ProductQtyRender } from '../../../utils/product.helper';
import { IProductsTypes } from '../temp.const';
import { NumberFormatting } from '../../../utils/helper/number.helper';
import { PickTypeConvert } from '../../../utils/helper/pick.helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
interface IProps {
    idx?: number | null;
    modify: boolean;
    bagData: bagType[];
    pickDate: string | null;
    pickTime: string | null;
    pickType: number;
    photoList: IPhotoListTypes[];
    dogCheck: boolean;
    moveStep: Dispatch<SetStateAction<number>>;
    addressData: null | addressListType;
    product: IProductsTypes;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
    description: string | null;
}
export const DetailScreen = ({
    addressData,
    idx,
    modify,
    dogCheck,
    bagData,
    pickDate,
    pickType,
    pickTime,
    photoList,
    moveStep,
    product,
    setPageType,
    description,
}: IProps) => {
    const { mainColor } = useColors();
    useEffect(() => {
        setPageType('Detail');
    }, []);
    const BagDataConvert = () => {
        return bagData.map((v) => `${v.value} ${BagCaseSwitch(v.type)} ${v.value > 1 ? 'bags' : 'bag'}`);
    };

    const OrderDataConvert = () => {
        const qty = ProductQtyRender(product);
        const temp = [`${qty} ${qty > 1 ? 'items' : 'item'} / $${NumberFormatting(ProductAllAmountRender(product))}`];
        const tempArray = product.filter((v) => v.qty > 0);
        const addTemp = tempArray.map((v, i) => `(${i + 1}) ${v.title}`);
        return [...temp, ...addTemp];
    };
    const user = useSelector((state: RootState) => state.auth.user);
    const userRefry = user?.userType === 2;
    return (
        <Box mt={SizePixel(44.37)}>
            <Box px={SizePixel(MAIN_COLLECTION_PADDING)}>
                <Text {...Ar36B} color={'black.100'} lineHeight={SizePixel(39.17)}>
                    Your Pickup
                </Text>
                <Text mt={SizePixel(8)} {...Ar21B} color={mainColor} mb={SizePixel(16.65)} lineHeight={SizePixel(22.85)}>
                    {modify ? PickTypeConvert('R', idx) : ' '}
                </Text>
            </Box>
            <ScrollView showsVerticalScrollIndicator={false} maxH={'65vh'}>
                <MapWithMarker width={390} height={SizePixel(176)} addressData={addressData} />
                <Box px={SizePixel(MAIN_COLLECTION_PADDING)} alignSelf={'center'}>
                    <DetailCard
                        cardType={'Bag'}
                        Icon={RecyleIcon}
                        arrayData={BagDataConvert()}
                        editPress={() => moveStep(userRefry ? 3 : 2)}
                    />
                    <DetailCard
                        cardType={'Date'}
                        Icon={DateIcon}
                        stringData={dateFormatDays2(pickDate ?? '')}
                        editPress={() => moveStep(userRefry ? 4 : 3)}
                    />
                    <DetailCard
                        cardType={'Description'}
                        stringData={description ?? '-'}
                        Icon={PencilIcon}
                        editPress={() => moveStep(userRefry ? 6 : 5)}
                    />
                    {photoList.length > 0 && (
                        <DetailCard
                            cardType={'Photo'}
                            photoData={photoList.slice(0, 2)}
                            Icon={PhotoIcon}
                            editPress={() => moveStep(userRefry ? 7 : 6)}
                        />
                    )}
                    {user?.userType === 1 && (
                        <DetailCard
                            cardType={'Pet'}
                            Icon={PetIcon}
                            editPress={() => moveStep(userRefry ? 7 : 6)}
                            stringData={dogCheck ? 'Checked' : 'Unchecked'}
                        />
                    )}

                    {ProductQtyRender(product) > 0 && (
                        <DetailCard
                            cardType={'Bag'}
                            Icon={RecanBagIcon}
                            editPress={() => moveStep((p) => p - 2)}
                            arrayData={OrderDataConvert()}
                        />
                    )}
                </Box>
                <Box h={SizePixel(200)} />
            </ScrollView>
        </Box>
    );
};
