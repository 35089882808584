import React, { ReactElement } from 'react';
import { VariantType } from 'native-base/src/components/types/utils';
import { ColorType } from 'native-base/lib/typescript/components/types';
import { IButtonGroupProps, IButtonProps } from 'native-base/lib/typescript/components/primitives/Button';
import { Button, Text } from 'native-base';
import { Ar22M } from '../../themes/font.style';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IBoxProps } from 'native-base/src/components/primitives/Box/index';
import { useColors } from '../../hooks/useColors';
import { IButtonComponentType } from 'native-base/lib/typescript/components/primitives/Button/types';
import useWindowDimensions from '../../hooks/useDimensions';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';

interface Props extends IButtonProps {
    bg?: ColorType;
    condition?: boolean;
    children: ReactElement;
}
export const LongButton = ({ bg, condition, children, ...props }: Props) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const { mainColor } = useColors();
    const { height, sizePix } = useWindowDimensions();

    return (
        <Button {...props} bg={condition ? 'gray.200' : mainColor} h={sizePix(53)} maxW={InitWidthSizeScreen} w={'100%'} rounded={'full'}>
            {children}
        </Button>
    );
};
