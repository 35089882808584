export const BagCaseSwitch = (status: number) => {
    switch (status) {
        case 1:
            return 'Recan';
        case 2:
            return 'Non Recan';
        case 3:
            return 'Wheelie Bin';
        case 4:
            return 'Bulk Bag';
        default:
            return 'Recan';
    }
};
