import React, { useEffect, useState } from 'react';
import { Actionsheet, Box, Button, Center, Divider, HStack, Spinner, Text, VStack } from 'native-base';
import { ActivityIndicator, Dimensions, Linking, StyleSheet, TouchableOpacity } from 'react-native';
import useAxiosServices, { payPalRedirect } from '../../hooks/axiosHooks';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, profileSetting } from '../../store/authReducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RootState } from '../../store';
import PaypalLoginIcon from '../../assets/icons/paypal.loginIcon';
import { Ar21B } from '../../themes/font.style';
import { useNavigate } from 'react-router-dom';
import { LongButton } from '../buttons/long.button';
import useWindowDimensions from '../../hooks/useDimensions';
import { FontPixel } from '../typographys/font.pixel';

const { width, height } = Dimensions.get('window');

const paypalUrl = `https://www.sandbox.paypal.com/signin/authorize?flowEntry=static&client_id=AZGiiuz7QtNs3E-EroUOjpTm34-Y6K6ASqrFshw3XdcoY1b6eDt9VIx6dQVSul16kc39yI7POEFnrjUd&scope=openid email&redirect_uri=${payPalRedirect}`;
interface Props {
    render?: (props: { open: () => void; disabled?: boolean }) => JSX.Element;
}
const PaypalLogin = ({ render }: Props) => {
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const { appToken } = useSelector((state: RootState) => state.auth);
    const navigation = useNavigate();
    const { sizePix } = useWindowDimensions();
    useEffect(() => {
        handler();
    }, [window.location.search]);

    const handler = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log(code);
        if (code) {
            try {
                const api = await axiosService.post('/users/login/paypal', { code: code });
                const { result, data } = api.data;
                console.log('Echeck1');
                if (result) {
                    const { accessToken, refreshToken } = data;
                    await AsyncStorage.setItem('accessToken', accessToken);
                    await AsyncStorage.setItem('refreshToken', refreshToken);
                    const getProfile = await axiosService.post('/users/app/profile', { appToken });
                    const { data: profileData, status: profileStatus } = getProfile.data;
                    dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                    dispatch(loginAction());
                    navigation('/main', { replace: true });
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const open = async () => {
        window.location.replace(paypalUrl);
    };
    if (typeof render === 'function') {
        return render({ open });
    }

    return (
        <Button h={sizePix(53)} colorScheme={'blue.100'} mb={sizePix(14)} onPress={() => open()} variant={'basicButton'}>
            <HStack alignItems={'center'}>
                <Box position={'absolute'} width={'100%'} right={6} top={'2px'}>
                    <PaypalLoginIcon />
                </Box>
                <Box alignItems={'center'} width={'100%'} left={2}>
                    <FontPixel {...Ar21B} color={'white.100'}>
                        Continue with Paypal
                    </FontPixel>
                </Box>
            </HStack>
        </Button>
    );
};

export default PaypalLogin;
