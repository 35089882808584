const PickTypeConst = [
    { value: 1, label: 'Booked' },
    { value: 2, label: 'Assigned' },
    { value: 3, label: 'Collected' },
    { value: 4, label: 'Delivered' },
    { value: 5, label: 'Credited' },
];

export const PickProfileConst = [
    { value: 'R', label: 'Recan' },
    { value: 'D', label: 'Depot' },
    { value: 'C', label: 'Coex' },
];

export const PickTypeConvert = (prefix, number) => {
    const convert = number > 99 ? String(number) : `0${String(number)}`;
    return `${prefix ?? 'R'}-${convert}`;
};

export const FindPickTypeConst = (value) => PickTypeConst.find((v) => v.value === value);
export const FindProfileTypeConst = (value) => PickProfileConst.find((v) => v.value === value);
