import { lazy } from 'react';
import NavMotion from './nav.Motion';
import GuestGuard from './guest.guard';
import OutletLayout from './outlet.layout';
import Loadable from './loadable';
import LoginSelectScreen from '../containers/auth/login.select';
import LoginSingUp from '../containers/auth/login.signUp';
import EmailValidate from '../containers/auth/email.validate';
import LoginSignIn from '../containers/auth/login.signIn';
import EmailComplete from '../containers/auth/emailComplete';
import PasswordValidate from '../containers/auth/password.validate';
import PasswordFind from '../containers/auth/password.find';
import PasswordChange from '../containers/auth/password.change';
import AddressInfo from '../containers/user/address/address.info';
import AccountChange from '../containers/auth/account.change';
import AccountLoginCheck from '../containers/auth/accout.login.check';
import BeforeSendFind from '../containers/auth/before.send.find';
import BeforeSendCheck from '../containers/auth/before.send.check';
// project imports

// login routing

// ==============================|| AUTH ROUTING ||============================== //

const ChangeNavigation = {
    path: '/',
    element: (
        <NavMotion>
            <OutletLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/accountChange',
            element: <AccountChange />,
        },
        {
            path: '/accountLoginCheck',
            element: <AccountLoginCheck />,
        },
        {
            path: '/accountChange/find/send',
            element: <BeforeSendFind />,
        },
        {
            path: '/accountChange/find/new',
            element: <BeforeSendCheck />,
        },
    ],
};

export default ChangeNavigation;
