import React from 'react';
import { Svg, Path } from 'react-native-svg';

const HelpIcon = () => {
    return (
        <Svg width="33" height="33" viewBox="0 0 33 33" fill="none">
            <Path
                d="M16.8264 5.70261C10.8886 5.70261 6.07505 10.5162 6.07505 16.454C6.07505 22.3918 10.8886 27.2053 16.8264 27.2053C22.7642 27.2053 27.5778 22.3918 27.5778 16.454C27.5778 10.5162 22.7642 5.70261 16.8264 5.70261ZM3.38721 16.454C3.38721 9.03171 9.40414 3.01477 16.8264 3.01477C24.2487 3.01477 30.2656 9.03171 30.2656 16.454C30.2656 23.8762 24.2487 29.8932 16.8264 29.8932C9.40414 29.8932 3.38721 23.8762 3.38721 16.454Z"
                fill="white"
            />
            <Path
                d="M16.8264 19.1418C16.0842 19.1418 15.4825 18.5401 15.4825 17.7979V16.454C15.4825 15.7117 16.0842 15.1101 16.8264 15.1101C17.5686 15.1101 18.1703 15.7117 18.1703 16.454V17.7979C18.1703 18.5401 17.5686 19.1418 16.8264 19.1418Z"
                fill="white"
            />
            <Path
                d="M14.8105 22.5016C14.8105 21.3883 15.7131 20.4857 16.8264 20.4857C17.9397 20.4857 18.8423 21.3883 18.8423 22.5016C18.8423 23.615 17.9397 24.5175 16.8264 24.5175C15.7131 24.5175 14.8105 23.615 14.8105 22.5016Z"
                fill="white"
            />
            <Path
                d="M17.3504 10.8248C16.0643 10.7635 14.9858 11.482 14.7922 12.6432C14.6702 13.3753 13.9778 13.8699 13.2457 13.7479C12.5135 13.6258 12.019 12.9334 12.141 12.2013C12.6194 9.33067 15.2367 8.03325 17.4783 8.13999C18.6266 8.19467 19.8066 8.60681 20.7153 9.45589C21.6465 10.3261 22.2021 11.5725 22.2021 13.0942C22.2021 14.8296 21.5193 16.1165 20.3437 16.9002C19.2643 17.6198 17.9412 17.7979 16.8264 17.7979C16.0842 17.7979 15.4825 17.1962 15.4825 16.454C15.4825 15.7117 16.0842 15.1101 16.8264 15.1101C17.7275 15.1101 18.4202 14.9522 18.8528 14.6638C19.1891 14.4396 19.5142 14.0466 19.5142 13.0942C19.5142 12.2639 19.2299 11.7465 18.8802 11.4198C18.5079 11.0719 17.9661 10.8541 17.3504 10.8248Z"
                fill="white"
            />
        </Svg>
    );
};

export default HelpIcon;
