import React from 'react';
import { Box, Pressable, Text } from 'native-base';
import { TouchableOpacity, Image as RNImage } from 'react-native';
import BackGray from '../../../assets/icons/back.gray';
import { Ar18M, Ar21B } from '../../../themes/font.style';
import { Img } from 'react-image';
interface Props {
    type: 0 | 1 | 2 | 3 | number;
    navigation: any;
}
import voucher from '../../../assets/icons/voucher-icon.png';
import donateIcon from '../../../assets/icons/donate-icon.png';
import paypalIcon from '../../../assets/icons/paypal-icon.png';
import bankIcon from '../../../assets/icons/bank-icon.png';
import { useColors } from '../../../hooks/useColors';
import { FontPixel } from '../../../components/typographys/font.pixel';

const imageCase = (type: any) => {
    switch (type) {
        case 0:
            return voucher;
        case 1:
            return paypalIcon;
        case 2:
            return donateIcon;
        case 3:
            return bankIcon;
        default:
            return voucher;
    }
};

const textCase = (type: any) => {
    switch (type) {
        case 0:
            return 'Voucher';
        case 1:
            return 'Paypal';
        case 2:
            return 'Donate';
        case 3:
            return 'Bank Transfer';
        default:
            return 'Voucher';
    }
};

const RedeemCard = ({ type, navigation }: Props) => {
    const { mainColor } = useColors();
    return (
        <Pressable onPress={() => navigation('/redeemSelect')}>
            <Box h={'111px'} p={4} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Img style={{ width: 30, height: 30, marginRight: 0, objectFit: 'contain' }} src={imageCase(type)} />
                    <Box ml={'32px'}>
                        <FontPixel {...Ar18M} color={'black.100'}>
                            {'Your active payout'}
                        </FontPixel>
                        <FontPixel {...Ar21B} color={mainColor}>
                            {`${textCase(type)}`}
                        </FontPixel>
                    </Box>
                </Box>
                <Box justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <TouchableOpacity onPress={() => navigation('/redeemSelect')}>
                        <BackGray />
                    </TouchableOpacity>
                </Box>
            </Box>
        </Pressable>
    );
};

export default RedeemCard;
