import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Center,
    Input,
    Stack,
    IconButton,
    Button,
    HStack,
    Divider,
    ScrollView,
    Image,
    Heading,
    useToast,
    Pressable,
} from 'native-base';
import PasswordViewIcon from '../../assets/icons/passwordView.icon';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from '../../components/customInput/defaultInput';
import ErrorHelp from '../../components/customInput/error.help';
import DefaultHeader from '../header/header.default';
import SelectAccountSheet from '../../components/bottomSheet/selectAccount.sheet';
import useAxiosServices from '../../hooks/axiosHooks';
import { Ar17B, Ar17M, Ar17R, Ar21B, Ar36B } from '../../themes/font.style';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { loginAction, profileSetting } from '../../store/authReducer';
import { RootState } from '../../store';
import { loadingEndAction } from '../../store/commonReducer';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import JoinSelect from '../../components/bottomSheet/join.select';

const SingInSchema = Yup.object().shape({
    accountType: Yup.string().required('Please select account Type'),
    email: Yup.string().email('Please enter a valid email').min(4, 'Password Too short').required('Please enter your email'),
    password: Yup.string()
        .min(8, 'This password is too short. It must contain at least 8 characters.')
        .required('Please enter your password'),
    passwordCheck: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match"),
});

const passwordView = require('../../assets/icons/password.png');
import paypalIcon from '../../assets/icons/paypal.png';
import { MAXWIDTH } from '../../themes';
import { FontPixel } from '../../components/typographys/font.pixel';
import useWindowDimensions from '../../hooks/useDimensions';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import { LongButton } from '../../components/buttons/long.button';
const AccountChange = () => {
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [searchParams] = useSearchParams();
    const id = location?.state?.id;
    const email = location?.state?.email;
    const password = location?.state?.password;

    const { appToken } = useSelector((state: RootState) => state.auth);
    const { axiosService } = useAxiosServices();
    const { handleChange, errors, touched, values, setValues, setFieldValue, handleSubmit } = useFormik({
        validationSchema: SingInSchema,
        initialValues: {
            accountType: '',
            email: '',
            password: '',
            passwordCheck: '',
        },
        onSubmit: async (value) => {
            submit(value);
        },
    });
    const navigation = useNavigate();
    const [sheetOpen, setSheetOpen] = useState(false);
    const inputHandler = async (type: 1 | 2) => {
        await setFieldValue('accountType', type === 1 ? 'Personal' : 'Company');
        setSheetOpen(false);
    };
    const goToEmailValidateScreen = (code: number, email: string, password: string) => {
        // @ts-ignore
        navigation('/emailValidate', { state: { email, password, code, accountType: values.accountType } });
    };
    const submit = async (value: any) => {
        try {
            const { email, passwordCheck, password, accountType } = value;
            const aType = accountType === 'Personal' ? 1 : 2;
            const api = await axiosService.post('before-store/change', { id: id, pw: passwordCheck, accountType: aType, email });
            const { status, data } = api.data;
            if (!status) {
                toast(data);
            } else {
                toast.info(data);
                await loginHandler({ id: email, email, password }, 1);
            }
        } catch (e) {
            toast('Error.');
        }
    };
    const loginHandler = async (value: { id; email: string; password: string }, type) => {
        const { id, email, password } = value;
        try {
            const api = await axiosService.post('/users/login/sns', { username: email, password });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', { appToken });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigation('/main', { replace: true });
            } else {
                toast('error');
            }
        } catch (e) {
            setSelectOpen(true);
            setTempEmail(email);
            setTempId(id);
            setTempType(type);
        }
    };

    const [view, setView] = useState(false);
    const [view2, setView2] = useState(false);

    useEffect(() => {
        getData();
    }, [id]);
    const getData = async () => {
        try {
            const api = await axiosService.post('/before-store/info', { id });
            const { status, info } = api.data;
            if (status) {
                const { userEmail, _id } = info;
                await setFieldValue('email', userEmail);
                await setFieldValue('password', password);
            } else {
                navigate('/login', { replace: true });
            }
        } catch (e) {
            navigate('/login', { replace: true });
        }
    };

    const dispatch = useDispatch();

    const [tempId, setTempId] = useState('');
    const [tempEmail, setTempEmail] = useState('');
    const [tempType, setTempType] = useState('1');
    const [selectOpen, setSelectOpen] = useState(false);

    const loginJoinHandler = async (value: { email: string; password: string }) => {
        const { email, password } = value;
        try {
            const api = await axiosService.post('/users/login/sns', {
                username: email,
                password,
            });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', {
                appToken,
            });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigate('/main', { replace: true });
            } else {
                toast('Error');
            }
        } catch (e) {
            console.log(e);
            toast('Not a registered member. Please sign up and proceed.');
        }
    };

    const [paypalOpen, setPaypalOpen] = useState(false);
    const responseGoogle = (res) => {
        console.log(res);
    };
    const { sizePix: SizePixel, height } = useWindowDimensions();

    return (
        <>
            <Box bg={'white.100'} alignItems={'center'} width={'100vw'} h={height}>
                <DefaultHeader navigation={navigation} bg={'white.100'} />
                <Box flex={1} px={SizePixel(29)} maxW={InitWidthSizeScreen} width={'100%'} flexGrow={1} justifyContent={'space-between'}>
                    <Box>
                        <Box mb={SizePixel(81)} mt={SizePixel(28)} alignItems={'center'}>
                            <FontPixel {...Ar36B} color={'black.100'}>
                                Account Change
                            </FontPixel>
                        </Box>
                        <Box>
                            <Box width={'100%'}>
                                <ErrorHelp errors={errors} />
                                <Pressable onPress={() => setSheetOpen(true)}>
                                    <CustomInput
                                        isDisabled={true}
                                        value={values.accountType}
                                        error={errors.accountType}
                                        touched={touched.accountType}
                                        onChangeText={handleChange('accountType')}
                                        placeholder="Account Type"
                                    />
                                </Pressable>
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    isDisabled={true}
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChangeText={handleChange('email')}
                                    placeholder="Email"
                                />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                    onChangeText={handleChange('password')}
                                    type={view ? 'text' : 'password'}
                                    InputRightElement={
                                        <IconButton
                                            onPress={() => setView((p) => !p)}
                                            _pressed={{ bg: 'transfer', opacity: 0.5 }}
                                            icon={<PasswordViewIcon color={'#ACACAC'} boolean={view} />}
                                        />
                                    }
                                    placeholder="Password"
                                />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.passwordCheck}
                                    error={errors.passwordCheck}
                                    touched={touched.passwordCheck}
                                    onChangeText={handleChange('passwordCheck')}
                                    type={view2 ? 'text' : 'password'}
                                    InputRightElement={
                                        <IconButton
                                            onPress={() => setView2((p) => !p)}
                                            _pressed={{ bg: 'transfer', opacity: 0.5 }}
                                            icon={<PasswordViewIcon color={'#ACACAC'} boolean={view2} />}
                                        />
                                    }
                                    placeholder="Confirm password"
                                />
                            </Box>
                        </Box>
                        <Box mt={SizePixel(74)}>
                            <Box width={'100%'}>
                                <LongButton my={2} colorScheme={'blue.200'} onPress={() => handleSubmit()} variant={'basicButton'}>
                                    <HStack alignItems={'center'}>
                                        <Box alignItems={'center'} width={'100%'}>
                                            <FontPixel color={'white.100'} fontFamily={'Arch'} fontWeight={'700'} fontSize={21}>
                                                Account Change
                                            </FontPixel>
                                        </Box>
                                    </HStack>
                                </LongButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <SelectAccountSheet value={values.accountType} open={sheetOpen} onHandler={setSheetOpen} inputHandler={inputHandler} />
        </>
    );
};

export default AccountChange;
