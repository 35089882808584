import React, { useState } from 'react';
import { Actionsheet, Box, Center, Divider, Spinner, Text, VStack } from 'native-base';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { ActivityIndicator, Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import CloseIcon from '../../assets/icons/close.icon';
import Iframe from 'react-iframe';
interface Props {
    open: boolean;
    setOpen: any;
    url: string;
    option?: boolean;
}
const { width, height } = Dimensions.get('window');
const barHeight = 10;
const WebSheet = ({ open, setOpen, url, option }: Props) => {
    const [loading, setLoading] = useState(true);
    return (
        <BottomSheet maxHeight={height} open={open} onDismiss={() => setOpen(false)}>
            <Box w={'100%'} height={height}>
                <Box m={4} flexDirection={'row'} justifyContent={option ? 'flex-start' : 'flex-end'} alignItems={'center'}>
                    <TouchableOpacity
                        style={{ bottom: 5 }}
                        hitSlop={{ top: 30, left: 30, right: 30, bottom: 30 }}
                        onPress={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </TouchableOpacity>
                </Box>
                <Box height={height}>
                    <Iframe url={url} id="myId" className="myClassname" height={'100%'} />
                </Box>
            </Box>
        </BottomSheet>
    );
};

export default WebSheet;
