import React from 'react';
import { Text } from 'native-base';
import { ITextProps } from 'native-base/src/components/primitives/Text/types';
import useWindowDimensions from '../../hooks/useDimensions';
interface Props extends ITextProps {}

export const FontPixel = ({ children, fontSize, ...props }: Props) => {
    const { sizePix } = useWindowDimensions();
    const convertSize = () => {
        if (typeof fontSize === 'number') {
            return sizePix(fontSize as number);
        } else if (typeof fontSize === 'string') {
            return fontSize;
        }
    };

    return (
        <Text {...props} fontSize={convertSize()}>
            {children}
        </Text>
    );
};
