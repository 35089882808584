import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Image as RNImage } from 'react-native';
import { Box, Button, Divider, Image, Pressable, StatusBar, Text, useToast } from 'native-base';
import HeaderOnlyBack from '../../header/header.onBack';
import { TouchableOpacity } from 'react-native';
import useAxiosServices from '../../../hooks/axiosHooks';
import { logoutAction, payoutChange } from '../../../store/authReducer';
import { Ar17N, Ar19N, Ar21B, Ar21N, Ar36B } from '../../../themes/font.style';
import BankSheet from '../../../components/bottomSheet/bank.sheet';
import { SubNumberHandler, textSizing } from '../../../utils/gup';
import BackGray from '../../../assets/icons/back.gray';

import voucher from '../../../assets/icons/voucher-icon.png';
import arrow from '../../../assets/icons/back-gray.png';
import recanShop from '../../../assets/recan-shop.png';
import sampleImage from '../../../assets/sample-image.png';
import check from '../../../assets/icons/simpleCheck.png';
import unCheck from '../../../assets/icons/unChecked.png';
import donateIcon from '../../../assets/icons/donate-icon.png';
import paypalIcon from '../../../assets/icons/paypal-icon.png';
import bankIcon from '../../../assets/icons/bank-icon.png';
import { Img } from 'react-image';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MAXWIDTH } from '../../../themes';
const sampleData = [
    { title: 'Bottles for Brushies', image: sampleImage, subTitle: 'Queensland Trust for Nature' },
    { title: 'Bottles for Brushies', image: sampleImage, subTitle: 'Queensland Trust for Nature' },
    { title: 'Bottles for Brushies', image: sampleImage, subTitle: 'Queensland Trust for Nature' },
];
const RedeemSelect = () => {
    const navigation = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const [paypalOpen, setPaypalOpen] = useState(false);
    const [bankOpen, setBankOpen] = useState(false);

    const modalSwitch = async (type: 1 | 2) => {
        if (type === 1) {
            if (user?.paypal) {
                await payoutUpdate(1);
                setPaypalOpen(true);
            } else {
                await payoutUpdate(3);
                setBankOpen(true);
            }
        }
    };

    const payoutUpdate = async (type: any) => {
        if (type === 1 || type === 3) {
            return;
        }

        try {
            const api = await axiosService.post('/users/payout/update', { type });
            const { status } = api.data;
            if (status) {
                dispatch(payoutChange({ payType: type }));
                toast('update');
            }
        } catch (e) {}
    };

    const getMyDonatin = async () => {
        const api = await axiosService.post('charity/my');
        const { data, status } = api.data;
        console.log(api);
        if (status) {
            console.log(data);
            setTitle(data);
        }
    };

    const [title, setTitle] = useState(null);
    useEffect(() => {
        getMyDonatin();
    }, []);

    const checkFontStyle = (value: string | null | undefined) => {
        if (!value) {
            return {
                ...Ar17N,
                color: 'gray.200',
            };
        } else {
            return {
                ...Ar17N,
                color: 'gray.300',
            };
        }
    };

    const CheckIconPayout = (value: boolean) => {
        if (!value) {
            return <BackGray />;
        } else {
            return <Img style={{ width: 27, height: 27, left: 10, objectFit: 'contain' }} src={check} />;
        }
    };

    return (
        <>
            <StatusBar barStyle={'light-content'} />
            <Box flex={1} bg={'white.100'} w={'100vw'} h={'100vh'} alignItems={'center'}>
                <Box bg={'blue.200'} w={'100vw'} alignItems={'center'}>
                    <Box safeAreaTop p={2} pb={'35px'} w={'100%'} maxW={MAXWIDTH}>
                        <HeaderOnlyBack navigation={navigation} />
                        <Box px={6} w={'100%'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                            <Text mt={8} {...Ar36B} color={'white.100'}>
                                {'My payout'}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Box maxW={MAXWIDTH}>
                    <Box px={6} my={4}>
                        <Pressable
                            onPress={() => setPaypalOpen(true)}
                            p={4}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            w={'100%'}
                        >
                            <Box w={'70%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                                <Img
                                    style={{ width: 29, height: 29, objectFit: 'contain', top: 1, marginRight: 10 }}
                                    src={paypalIcon}
                                    alt={'voucher'}
                                />
                                <Box w={'100%'}>
                                    <Text {...Ar21B} color={'black.100'} mb={'18px'}>
                                        {'Paypal'}
                                    </Text>
                                    <Text {...checkFontStyle(user?.paypal)}>{user?.paypal ?? 'Get your refund via your PayPal'}</Text>
                                </Box>
                            </Box>
                            <Box top={2}>
                                <TouchableOpacity onPress={() => null}>{CheckIconPayout(user?.payType === 1)}</TouchableOpacity>
                            </Box>
                        </Pressable>
                        <Divider />
                        <Pressable
                            onPress={() => navigation('/redeemCharitied' as never)}
                            p={4}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            w={'100%'}
                        >
                            <Box flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                                <Img
                                    style={{ width: 29, height: 29, objectFit: 'contain', top: 1, marginRight: 10 }}
                                    src={donateIcon}
                                    alt={'voucher'}
                                />
                                <Box w={'100%'}>
                                    <Text {...Ar21B} color={'black.100'} mb={'18px'}>
                                        {'Donation'}
                                    </Text>
                                    <Text {...checkFontStyle(title)}>{title ?? 'Browse available charities'}</Text>
                                </Box>
                            </Box>
                            <Box top={2}>
                                <TouchableOpacity onPress={() => null}>{CheckIconPayout(user?.payType === 2)}</TouchableOpacity>
                            </Box>
                        </Pressable>
                        <Divider />
                        <Pressable
                            w={'100%'}
                            onPress={() => payoutUpdate(0)}
                            p={4}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                        >
                            <Box w={'70%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                                <Img
                                    style={{ width: 29, height: 29, objectFit: 'contain', top: 1, marginRight: 10 }}
                                    src={voucher}
                                    alt={'voucher'}
                                />
                                <Box w={'100%'}>
                                    <Text {...Ar21B} color={'black.100'} mb={'18px'}>
                                        {'Vocher'}
                                    </Text>
                                    <Text {...checkFontStyle('vo')}>
                                        {'You will need at least $10.00 of available funds to request a GiftPay eGift card.'}
                                    </Text>
                                </Box>
                            </Box>
                            <Box top={2}>
                                <TouchableOpacity onPress={() => payoutUpdate(0)}>{CheckIconPayout(user?.payType === 0)}</TouchableOpacity>
                            </Box>
                        </Pressable>
                        <Divider />
                        <Pressable
                            onPress={() => setBankOpen(true)}
                            p={4}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                        >
                            <Box w={'70%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                                <Img
                                    style={{ width: 29, height: 29, objectFit: 'contain', top: 1, marginRight: 10 }}
                                    src={bankIcon}
                                    alt={'voucher'}
                                />
                                <Box w={'100%'}>
                                    <Text {...Ar21B} color={'black.100'} mb={'18px'}>
                                        {'Bank Transfer'}
                                    </Text>
                                    <Text {...checkFontStyle(user?.bankNumber)}>
                                        {' '}
                                        {textSizing(user?.bankName, 15) ?? 'Link your bank account'}
                                    </Text>
                                    <Text {...checkFontStyle(user?.bankNumber)}> {SubNumberHandler(user?.bankNumber)}</Text>
                                </Box>
                            </Box>
                            <Box top={2}>
                                <TouchableOpacity onPress={() => setBankOpen(true)}>
                                    {CheckIconPayout(user?.payType === 3)}
                                </TouchableOpacity>
                            </Box>
                        </Pressable>
                        <Divider />
                    </Box>
                </Box>
            </Box>
            {/*<InputModal title={'PayPal'} type={1} onClose={() => setPaypalOpen(false)} open={paypalOpen} />*/}
            <BankSheet open={bankOpen} setOpen={setBankOpen} />
            {/*<InputModal title={'Bank'} type={2} onClose={() => setBankOpen(false)} open={bankOpen} />*/}
        </>
    );
};

export default RedeemSelect;
