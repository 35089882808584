import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Heading, Image, Popover, ScrollView, StatusBar, Text, useToast, VStack } from 'native-base';
import HeaderBack from '../../header/header.back';
import { addressListType } from '../../../@types/userTypes';
import AddressCard from '../../../components/card/address.card';
import useAxiosServices from '../../../hooks/axiosHooks';
import ModalWrapper from '../../commons/modal/modal.wrapper';
import { Ar16Sb, Ar18M, Ar22M, Ar25SbBlack, Ar36B } from '../../../themes/font.style';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction, profileSetting } from '../../../store/authReducer';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { TouchableOpacity } from 'react-native';
import { MAXWIDTH } from '../../../themes';
import { useColors } from '../../../hooks/useColors';
import HeaderBackOption from '../../header/headerback.option';
import useWindowDimensions from '../../../hooks/useDimensions';
import { InitWidthSizeScreen, SizePixel } from '../../../utils/pixcel.convert';
import { FontPixel } from '../../../components/typographys/font.pixel';
import { LongButton } from '../../../components/buttons/long.button';
import GoogleInput from '../../../components/map/google.input';
import axios from 'axios';
import { PredictionType } from '../../../components/map/google.search';
import { FormattingAddress } from '../../../utils/helper/address.helper';
import postcodeImage from '../../../assets/image/postcode.png';
import { addressAdd } from '../../../store/commonReducer';
import useAuth from '../../../hooks/useAuth';
import { PageSetting } from '../../../utils/pageSetting';

const GOOGLE_PACES_API_BASE_URL = 'https://maps.googleapis.com/maps/api/place';
const GOOGLE_API_KEY = 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI';

const AddressInfo = () => {
    const toast = useToast();
    const { logout } = useAuth();
    const { axiosService } = useAxiosServices();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [mainList, setMainList] = useState<addressListType[]>([]);
    const [subList, setSubList] = useState<addressListType[]>([]);
    const [postCodeList, setPostCodeList] = useState<any[]>([]);
    const { isLogin, user } = useSelector((state: RootState) => state.auth);
    const noAuth = user?.addressCheck === 1;
    const addressCheck = user?.addressCheck === 1;
    const { mainColor } = useColors();
    const [removeOpen, setRemoveOpen] = useState(false);
    const [target, setTarget] = useState(0);
    const [showPredictions, setShowPredictions] = useState<boolean>(false);
    const [search, setSearch] = useState({ term: '', fetchPredictions: false });
    const [predictions, setPredictions] = useState<PredictionType[]>([]);

    const { sizePix, height } = useWindowDimensions();
    useEffect(() => {
        getAddressList();
        getPostCodeList();
    }, []);

    const getAddressList = async () => {
        try {
            const api = await axiosService.post('/users/app/address/list');
            const { status, main, sub } = api.data;
            if (status) {
                setMainList([...main]);
                setSubList([...sub]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getPostCodeList = async () => {
        const api = await axiosService.post('/pick/location/postcode');
        const { status, data } = api.data;
        console.log(data);
        if (status) {
            setPostCodeList([...data]);
        }
    };

    const deleteAddress = async (idx: number) => {
        setTarget(idx);
        setRemoveOpen(true);
    };

    const deleteAddressComplete = async () => {
        try {
            const api = await axiosService.post('/users/app/address/delete', { idx: target });
            const { status } = api.data;
            if (status) {
                await getAddressList();
                await addressChangeProfileGet();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRemoveOpen(false);
            setTarget(0);
        }
    };

    const mainSelect = async (idx: any) => {
        try {
            const api = await axiosService.post('/users/app/address/mainCheck', { addressNumber: idx });
            const { status } = api.data;
            if (status) {
                await getAddressList();
                await addressChangeProfileGet();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const addressChangeProfileGet = async () => {
        const getProfile = await axiosService.post('/users/app/profile');
        const { data: profileData, status: profileStatus } = getProfile.data;
        if (profileStatus) {
            dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
        }
    };
    const onChangeText = async (search) => {
        try {
            if (search.term.trim() === '') {
                setShowPredictions(false);
                return;
            }
            if (!search.fetchPredictions) {
                return;
            }
            const apiUrl = `${GOOGLE_PACES_API_BASE_URL}/autocomplete/json?key=${GOOGLE_API_KEY}&input=${search.term}&components=country:AU`;
            // &type=route
            const result = await axiosService.post('/pick/address/searchService', { code: apiUrl });
            if (result) {
                const {
                    data: { predictions },
                } = result;
                setPredictions(predictions);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const [modalOpen, setModalOpen] = useState(false);
    const onPredictionTapped = async (placeId: string, description: string) => {
        const apiUrl = `${GOOGLE_PACES_API_BASE_URL}/details/json?key=${GOOGLE_API_KEY}&place_id=${placeId}`;

        try {
            const result = await axiosService.post('/pick/address/searchService', { code: apiUrl });

            if (result) {
                const {
                    data: {
                        result: {
                            address_components,
                            geometry: { location },
                        },
                    },
                } = result;

                const { lat, lng } = location;
                const addressData = FormattingAddress(address_components);
                console.log(postCodeList);
                console.log(addressData);
                setAddressName({ ...addressData, lat, lot: lng });
                setShowPredictions(false);
                setSearch((p) => ({ ...p, term: '' }));
                dispatch(addressAdd({ addressTemp: { ...addressData, lat, lot: lng } }));
                navigation('/addressAdd');
                // if (postCodeList.includes(addressData.postcode ?? '')) {
                //     setAddressName({ ...addressData, lat, lot: lng });
                //     setShowPredictions(false);
                //     setSearch((p) => ({ ...p, term: '' }));
                //     dispatch(addressAdd({ addressTemp: { ...addressData, lat, lot: lng } }));
                //     navigation('/addressAdd');
                // } else {
                //     setModalOpen(true);
                // }
            }
        } catch (e) {
            console.log(e);
        }
    };
    const [addressName, setAddressName] = useState({ address: '', addressMore: '', postcode: '', route: '', lat: 0, lot: 0 });

    return (
        <>
            <StatusBar barStyle={'dark-content'} />
            <Box bg={'white.100'} p={sizePix(20)} w={'100%'} h={height}>
                {noAuth ? (
                    <HeaderBackOption
                        navigation={navigation}
                        backOption={showPredictions ? () => setShowPredictions(false) : () => navigation(-1)}
                        noClose={true}
                    />
                ) : (
                    <Box safeAreaTop px={sizePix(30)} bg={'white.100'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                        <TouchableOpacity onPress={() => logout()}>
                            <Text {...Ar18M} color={'blue.300'}>
                                Cancel
                            </Text>
                        </TouchableOpacity>
                    </Box>
                )}

                <Box {...PageSetting} px={sizePix(20)} w={'100%'} bg={'white.100'} alignItems={'center'} height={'100%'}>
                    <Box w={'100%'} maxW={InitWidthSizeScreen}>
                        <Box py={4} bg={'white.100'} w={'100%'}>
                            <Box mt={sizePix(50)} mb={sizePix(30)}>
                                <FontPixel {...Ar36B}>{'Pickup address'}</FontPixel>
                            </Box>
                            <GoogleInput
                                onFocus={setShowPredictions}
                                value={search.term}
                                onChangeText={(text) => {
                                    console.log(text);
                                    setSearch({ term: text, fetchPredictions: true });
                                    onChangeText({ term: text, fetchPredictions: true });
                                }}
                                showPredictions={showPredictions}
                                predictions={predictions}
                                onPredictionTapped={onPredictionTapped}
                            />
                            {!showPredictions && (
                                <Box w={'100%'} pt={SizePixel(40)} maxW={InitWidthSizeScreen}>
                                    {mainList.map((v, i) => (
                                        <AddressCard
                                            main={true}
                                            makeMain={mainSelect}
                                            key={`address_card_${i}`}
                                            address={v}
                                            deleteAddress={deleteAddress}
                                        />
                                    ))}

                                    {mainList.length !== 0 && subList.length !== 0 && <Box mt={'60px'} />}

                                    {mainList.length === 0 && subList.length === 0 && (
                                        <Box alignItems={'center'} my={'30px'}>
                                            <Text {...Ar16Sb}>Please search for the address and add it.</Text>
                                        </Box>
                                    )}
                                    {subList.map((v, i) => (
                                        <AddressCard
                                            makeMain={mainSelect}
                                            key={`address_card_${i}`}
                                            address={v}
                                            deleteAddress={deleteAddress}
                                        />
                                    ))}
                                </Box>
                            )}
                            {/*<Box flexDirection={'row'} w={'100%'} mt={'20px'} px={sizePix(30)} justifyContent={'space-between'}>*/}
                            {/*    <LongButton*/}
                            {/*        shadow={8}*/}
                            {/*        onPress={() => navigation('/addressAdd' as never)}*/}
                            {/*        _disabled={{ bg: 'gray.100' }}*/}
                            {/*        width={'100%'}*/}
                            {/*    >*/}
                            {/*        <Text {...Ar22M} color={'white.100'}>*/}
                            {/*            Add*/}
                            {/*        </Text>*/}
                            {/*    </LongButton>*/}
                            {/*</Box>*/}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ModalWrapper
                open={removeOpen}
                onClose={() => setRemoveOpen(false)}
                onHandler={[
                    { text: 'Cancel', onPress: () => setRemoveOpen(false), color: 'gray.200' },
                    { text: 'Remove', onPress: deleteAddressComplete, color: 'blue.200' },
                ]}
                title={'Are you sure?'}
                content={'Are you sure you want to\n' + 'remove this location?'}
            />
            <ModalWrapper
                image={postcodeImage}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onHandler={[{ text: 'Got it!', onPress: () => setModalOpen(false), color: mainColor }]}
                title={'Sorry...'}
                content={'Not available in your area right now. Please confirm the available location for pickup.'}
            />
        </>
    );
};
export default AddressInfo;
