import React from 'react';
import Svg, { ClipPath, G, Path, Defs, Rect } from 'react-native-svg';
import { SizePixel } from '../../utils/pixcel.convert';

export const PlainMapIcon = () => {
    return (
        <Svg width={SizePixel(50)} height={SizePixel(50)} viewBox="0 0 51 51" fill="none">
            <G clipPath="url(#clip0_981_11630)">
                <Path
                    d="M39.9861 6.6453C35.9899 2.78125 30.7225 0.767884 25.1502 0.950918C15.002 1.30682 6.62312 9.18743 5.64694 19.2746C5.46391 21.2168 5.54526 23.159 5.91133 25.0707C5.92149 25.152 5.96217 25.3859 6.05369 25.752C6.36891 27.1247 6.81632 28.467 7.4061 29.7482C9.47031 34.6088 13.9648 42.0928 23.6758 50.1463C24.3164 50.675 25.0892 50.9394 25.8722 50.9394C26.6551 50.9394 27.4279 50.675 28.0686 50.1463C37.7795 42.0826 42.274 34.6088 44.3382 29.7482C44.928 28.467 45.3856 27.1247 45.6906 25.752C45.7821 25.3859 45.8228 25.152 45.833 25.0707C46.0669 23.8199 46.1889 22.5285 46.1889 21.2575C46.1889 15.6953 43.9823 10.5093 39.9861 6.6453ZM42.2232 24.4199C42.2232 24.4504 42.213 24.4707 42.213 24.5012C42.2028 24.5521 42.1723 24.6944 42.1215 24.9181C42.1215 24.9283 42.1215 24.9283 42.1215 24.9385C41.8673 26.0672 41.491 27.1857 41.0029 28.2433C40.9928 28.2636 40.9826 28.2839 40.9826 28.2941C39.0913 32.7581 34.9425 39.6524 25.8823 47.2177C16.8222 39.6625 12.6734 32.7581 10.7821 28.2941C10.7719 28.2738 10.7617 28.2534 10.7617 28.2433C10.2736 27.1857 9.89739 26.0774 9.64318 24.9385C9.64318 24.9283 9.64318 24.9283 9.64318 24.9181C9.59233 24.6944 9.56183 24.5521 9.55166 24.5012C9.55166 24.4707 9.54149 24.4504 9.54149 24.4199C9.2161 22.8438 9.13475 21.227 9.28728 19.6203C10.0804 11.3432 16.9544 4.88614 25.2722 4.60142C29.8379 4.43872 34.1595 6.0962 37.444 9.26878C40.7284 12.4414 42.5282 16.6918 42.5282 21.2575C42.5282 22.315 42.4265 23.3827 42.2232 24.4199Z"
                    fill="black"
                />
                <Path
                    d="M25.8722 10.6416C19.9134 10.6416 15.0529 15.492 15.0529 21.4609C15.0529 27.4298 19.9134 32.2802 25.8722 32.2802C26.8789 32.2802 27.7025 31.4566 27.7025 30.4499C27.7025 29.4432 26.8789 28.6196 25.8722 28.6196C21.9268 28.6196 18.7135 25.4063 18.7135 21.4609C18.7135 17.5155 21.9268 14.3023 25.8722 14.3023C29.8176 14.3023 33.0308 17.5155 33.0308 21.4609C33.0308 23.1692 32.4207 24.8165 31.3123 26.1079C30.6514 26.8807 30.7429 28.0298 31.5157 28.6907C32.2885 29.3517 33.4376 29.2602 34.0985 28.4874C35.7763 26.535 36.6915 24.0336 36.6915 21.4609C36.6915 15.5022 31.8411 10.6416 25.8722 10.6416Z"
                    fill="black"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_981_11630">
                    <Rect width="50" height="50" fill="white" transform="translate(0.87085 0.939453)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};
