import React from 'react';
import { Center, Spinner } from 'native-base';
import { StyleSheet } from 'react-native';
import { useColors } from '../../hooks/useColors';

export const LoadingScreen = () => {
    const { mainColor } = useColors();
    return (
        <Center style={[StyleSheet.absoluteFillObject, { width: '100%', height: '100vh', backgroundColor: 'rgba(255,255,255,0.4)' }]}>
            <Spinner size="lg" color={mainColor} />
        </Center>
    );
};
