import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Text } from 'native-base';
import { PlainMapIcon } from './plain.map.icon';
import { ScreenWidth, SizePixel } from '../../utils/pixcel.convert';
import { MapWithMarker } from '../../components/map/map.withMarker';
import { addressListType } from '../../@types/userTypes';
import { Ar18M, Ar22M, Ar21N, Ar21R, Ar36B } from '../../themes/font.style';
import { CollectionPageType, MAIN_COLLECTION_PADDING } from './index';
import { addressConvert } from '../../utils/helper/address.helper';
import { useColors } from '../../hooks/useColors';
interface IProps {
    addressData?: addressListType | null;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
}
export const ConfirmMapScreen = ({ addressData, setPageType }: IProps) => {
    useEffect(() => {
        setPageType('Map');
    }, []);
    const { mainColor } = useColors();
    return (
        <Box pt={SizePixel(46.38)}>
            <Box px={SizePixel(MAIN_COLLECTION_PADDING)}>
                <PlainMapIcon />
                <Text mt={SizePixel(16)} mb={SizePixel(40)} {...Ar36B} color={'black.100'} lineHeight={SizePixel(39)}>
                    Confirm your location
                </Text>
            </Box>
            <MapWithMarker width={390} height={SizePixel(234)} addressData={addressData} />
            <Box px={SizePixel(MAIN_COLLECTION_PADDING)} mt={SizePixel(16)}>
                <Text {...Ar21R} color={mainColor} numberOfLines={1} lineHeight={SizePixel(28)}>
                    {addressConvert([addressData?.address, addressData?.addressMore, addressData?.postcode])}
                </Text>
                <Text {...Ar22M} mt={SizePixel(16)} lineHeight={SizePixel(23.94)}>
                    Is this the correct address for pick up?
                </Text>
            </Box>
        </Box>
    );
};
