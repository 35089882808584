import { lazy } from 'react';
import NavMotion from './nav.Motion';
import GuestGuard from './guest.guard';
import OutletLayout from './outlet.layout';
import Loadable from './loadable';
import LoginSelectScreen from '../containers/auth/login.select';
import LoginSingUp from '../containers/auth/login.signUp';
import EmailValidate from '../containers/auth/email.validate';
import LoginSignIn from '../containers/auth/login.signIn';
import EmailComplete from '../containers/auth/emailComplete';
import PasswordValidate from '../containers/auth/password.validate';
import PasswordFind from '../containers/auth/password.find';
import PasswordChange from '../containers/auth/password.change';
import AddressInfo from '../containers/user/address/address.info';
import AccountChange from '../containers/auth/account.change';
// project imports

// login routing

// ==============================|| AUTH ROUTING ||============================== //

const AuthNavigation = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <OutletLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <LoginSelectScreen />,
        },
        {
            path: '/signUp',
            element: <LoginSingUp />,
        },
        {
            path: '/signIn',
            element: <LoginSignIn />,
        },
        {
            path: '/emailValidate',
            element: <EmailValidate />,
        },
        {
            path: '/emailComplete',
            element: <EmailComplete />,
        },
        {
            path: '/passwordFind',
            element: <PasswordFind />,
        },
        {
            path: '/passwordValidate',
            element: <PasswordValidate />,
        },
        {
            path: '/passwordChange',
            element: <PasswordChange />,
        },
    ],
};

export default AuthNavigation;
