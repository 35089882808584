import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, ScrollView, Text } from 'native-base';
import { SizePixel } from '../../utils/pixcel.convert';
import { PlainOneIcon, PlainTwoIcon } from './plain.icon';
import { Ar21R, Ar28Bold, La56Sb } from '../../themes/font.style';

import { CollectionPageType } from './index';
interface IProps {
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
}
export const PlainScreen = ({ setPageType }: IProps) => {
    useEffect(() => {
        setPageType('Plain');
    }, []);
    return (
        <ScrollView showsVerticalScrollIndicator={false} pt={SizePixel(24)} maxH={'68vh'} overflowY={'auto'}>
            <Box>
                <PlainOneIcon />
                <Text {...Ar28Bold} mt={SizePixel(20)} lineHeight={SizePixel(30)}>
                    100 eligible containers
                </Text>
                <Text {...Ar21R} mt={SizePixel(21)} lineHeight={SizePixel(28)}>
                    Make sure your bag is full with more than 100 eligible containers (you can crush cans, plastic bottles and poppers).
                </Text>
            </Box>
            <Box mt={SizePixel(55)}>
                <PlainTwoIcon />
                <Text {...Ar28Bold} mt={SizePixel(20)} lineHeight={SizePixel(30)}>
                    Find a secure place
                </Text>
                <Text {...Ar21R} mt={SizePixel(20)} lineHeight={SizePixel(28.41)}>
                    Find a secure place to put your bag out and leave a detailed instruction when you book
                </Text>
            </Box>
        </ScrollView>
    );
};
