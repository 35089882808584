import React, { useMemo, useState } from 'react';
import { Box, Text } from 'native-base';
import { Image } from 'react-native';
import { addressListType } from '../../@types/userTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Img } from 'react-image';
import NEWPIN from '../../assets/icons/new-pin.png';
import NEWPIN_P from '../../assets/icons/new-pin-p.png';
interface IProps {
    width: number;
    height: number;
    addressData?: addressListType | null;
}
export const GOOGLE_API_KEY = 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI';

export const MapWithMarker = ({ width, height, addressData }: IProps) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const PIN_IMAGE = user?.userType === 2 ? NEWPIN : NEWPIN_P;
    const mapUrl = useMemo(() => {
        if (!addressData) {
            return '';
        }
        const { lat, lot } = addressData;
        return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lot}&maptype=terrain&zoom=16&size=${width}x${Math.round(
            height
        )}&key=${GOOGLE_API_KEY}`;
    }, [addressData]);
    return (
        <Box w={width} h={height} bg={'gray.50'}>
            {addressData && <Image onError={() => console.log('errpr', mapUrl)} source={{ uri: mapUrl }} style={{ width, height }} />}
            <Box style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Img onError={() => console.log('errpr', mapUrl)} src={PIN_IMAGE} style={{ width: 39, height: 50, top: -18 }} />
            </Box>
        </Box>
    );
};
