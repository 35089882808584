import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Image, Modal, Pressable, ScrollView, Text, useToast, VStack } from 'native-base';
import DefaultHeader from '../header/header.default';
import OTPInputView from '../../components/otpInput';
import useAxiosServices from '../../hooks/axiosHooks';

import { Ar17RBlack, Ar18M, Ar18SbBlack, Ar22M, Ar25SbBlack, Ar28BoldBlack } from '../../themes/font.style';
import mailIcon from '../../assets/icons/sendMail.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Img } from 'react-image';
import { MAXWIDTH } from '../../themes';
import useWindowDimensions from '../../hooks/useDimensions';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import { FontPixel } from '../../components/typographys/font.pixel';

const PasswordValidate = () => {
    const navigation = useNavigate();
    const { axiosService } = useAxiosServices();
    const route: any = useLocation();
    const state: { code: any; email: any; accountType: any; password: any } = route.state;
    console.log('ee', state);
    const [code, setCode] = useState('');
    const [fulfilled, setFulfilled] = useState(false);
    const [verifyCode, setVerifyCode] = useState(state?.code);
    const email = state?.email;
    console.log(verifyCode);

    const verifyHandler = () => {
        setFulfilled(code.length === 6);
    };

    const goToVerify = async () => {
        console.log(verifyCode);
        console.log(code);
        if (String(verifyCode) !== String(code)) {
            toast('Please check the code.');
        } else {
            await goToPasswordChange();
        }
    };

    const goToPasswordChange = async () => {
        navigation('/passwordChange', { state: { email } });
    };

    useEffect(() => {
        verifyHandler();
    }, [code]);

    const reSendCode = async () => {
        try {
            const api = await axiosService.post('users/re/email', { email: state?.email });
            const { status, data } = api.data;
            if (!status) {
                toast(data);
            } else {
                setModalOpen(true);
                setVerifyCode(data);
            }
        } catch (e) {
            toast('error');
        }
    };

    const [modalOpen, setModalOpen] = useState(false);
    const { sizePix } = useWindowDimensions();

    return (
        <>
            <Box w={'100%'} bg={'white.100'} alignItems={'center'} height={'100vh'}>
                <DefaultHeader navigation={navigation} bg={'white.100'} />
                <Box
                    bg={'white.100'}
                    px={sizePix(28)}
                    maxW={InitWidthSizeScreen}
                    w={'100%'}
                    safeAreaBottom
                    flexGrow={1}
                    justifyContent={'space-between'}
                >
                    <Box>
                        <Box mt={sizePix(70)} pt={0}>
                            <FontPixel mb={3} textAlign={'left'} {...Ar28BoldBlack}>
                                Verify your Email
                            </FontPixel>
                            <FontPixel {...Ar18M}>We sent the verification code to</FontPixel>
                            <FontPixel {...Ar18M}>
                                <FontPixel {...Ar18SbBlack} color={'blue.100'}>
                                    {state?.email ?? 'test@naver.com'}
                                </FontPixel>
                            </FontPixel>
                            <FontPixel {...Ar18M}>{'Which expires in 15 minutes.\nPlease enter it below.'}</FontPixel>
                        </Box>
                        <OTPInputView
                            style={{ width: '100%', height: 200 }}
                            pinCount={6}
                            placeholderTextColor={'#222'}
                            codeInputFieldStyle={{ borderWidth: 0, borderBottomWidth: 2, color: '#16A6DA' }}
                            codeInputHighlightStyle={{ borderBottomColor: '#16A6DA', borderBottomWidth: 2 }}
                            code={code}
                            onCodeChanged={(codeInput) => setCode(codeInput)}
                            onCodeFilled={() => console.log(code)}
                            // code={this.state.code} //You can supply this prop or not. The component will be used as a controlled / uncontrolled component respectively.
                            // onCodeChanged = {code => { this.setState({code})}}
                            autoFocusOnLoad
                        />
                    </Box>
                    <Flex mb={2}>
                        <Box justifyContent={'flex-end'}>
                            <Box justifyContent={'center'} alignItems={'center'}>
                                <Pressable onPress={() => reSendCode()}>
                                    <FontPixel {...Ar22M} mb={3} color={'#4C4C4C'}>
                                        Send Code again
                                    </FontPixel>
                                </Pressable>
                                <Button
                                    h={sizePix(53)}
                                    onPress={() => goToVerify()}
                                    disabled={!fulfilled}
                                    bg={fulfilled ? 'blue.200' : 'gray.200'}
                                    variant={'shadowBasic'}
                                >
                                    <FontPixel {...Ar22M} color={'white.100'}>
                                        Continue
                                    </FontPixel>
                                </Button>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Modal p={0} isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Content maxWidth="400px" borderRadius={10}>
                    <Modal.Body>
                        <Center mt={10}>
                            <Img width={'100px'} height={'100px'} src={mailIcon} alt={'houseimage'} style={{ objectFit: 'contain' }} />
                            <VStack space={2} mt={4} alignItems={'center'}>
                                <Text fontFamily={'Arch'} fontWeight={700} fontSize={'21px'} color={'black.100'}>
                                    Check your email
                                </Text>
                                <Text textAlign={'center'} fontFamily={'Arch'} fontWeight={700} fontSize={'14px'} color={'black.100'}>
                                    {'check your email\n' + 'instruction to reset your password.\n'}
                                </Text>
                            </VStack>
                        </Center>
                    </Modal.Body>
                    <Modal.Footer width={'100%'} p={0} bg={'blue.200'} alignItems={'center'} justifyContent={'center'}>
                        <Button.Group>
                            <Button borderRadius={0} bg={'blue.200'} w={'100%'} variant="basicButton" onPress={() => setModalOpen(false)}>
                                <Text textAlign={'center'} {...Ar25SbBlack} color={'white.100'}>
                                    Got it!
                                </Text>
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default PasswordValidate;
