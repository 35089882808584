import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { AppRegistry, Platform } from 'react-native';
import { Navigation } from './navigation';

import 'react-spring-bottom-sheet/dist/style.css';
import 'rc-drawer/assets/index.css';
import 'react-calendar/dist/Calendar.css';
import 'react-medium-image-zoom/dist/styles.css';
import './themes/fonts.css';
import 'swiper/swiper-bundle.css';
import { BrowserRouter } from 'react-router-dom';

export function App(): ReactElement {
    return (
        <BrowserRouter>
            <Navigation />
        </BrowserRouter>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));
