import React, { useState } from 'react';
import { VStack, Box, HStack, Text, ScrollView, Pressable } from 'native-base';
import { Dimensions, RefreshControl } from 'react-native';
import { collectionTypes } from '../../@types/collection.types';
import { BookStatusCase } from '../../etc/bookStatusCase';
import { dateFormatDays } from '../../utils/times';
import { Ar15B } from '../../themes/font.style';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useDimensions';
import { useColors } from '../../hooks/useColors';
import { FontPixel } from '../../components/typographys/font.pixel';
import { FindPickTypeConst, PickTypeConvert } from '../../utils/helper/pick.helpers';
const { width, height } = Dimensions.get('window');

interface Props {
    list?: collectionTypes[];
    onRefresh: () => void;
    refreshing: boolean;
}
const TableDashboard = ({ list, refreshing, onRefresh }: Props) => {
    const navigation = useNavigate();
    const { sizePix } = useWindowDimensions();
    const { mainColor } = useColors();
    return (
        <>
            <Box mt={sizePix(18)} mx={sizePix(20)}>
                <VStack flex={1}>
                    <HStack
                        borderBottomWidth={1.52}
                        borderRadius={1}
                        borderColor={mainColor}
                        py={2}
                        w="100%"
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        {['Status'].map((v) => (
                            <Box alignItems={'flex-start'} w={'30%'}>
                                <FontPixel {...Ar15B} color={'black.100'}>
                                    {v}
                                </FontPixel>
                            </Box>
                        ))}
                        <Box alignItems={'flex-start'} w={'30%'} left={3}>
                            <FontPixel {...Ar15B} color={'black.100'}>
                                ID
                            </FontPixel>
                        </Box>
                        <Box alignItems={'flex-end'} w={'40%'}>
                            <FontPixel {...Ar15B} color={'black.100'}>
                                Pick up date
                            </FontPixel>
                        </Box>
                    </HStack>
                    <ScrollView
                        refreshControl={<RefreshControl progressViewOffset={10} refreshing={refreshing} onRefresh={onRefresh} />}
                        pb={20}
                        showsVerticalScrollIndicator={false}
                    >
                        {list
                            ? list.map((v, i) => (
                                  <Pressable key={`didfj123g+${i}`} onPress={() => navigation(`/detailCollection/${v.idx}`)}>
                                      <HStack
                                          borderBottomWidth={0.5}
                                          borderColor={'gray.200'}
                                          py={4}
                                          w="100%"
                                          alignItems={'center'}
                                          justifyContent={'space-between'}
                                      >
                                          <Box alignItems={'flex-start'} w={'30%'}>
                                              <FontPixel {...Ar15B} color={'black.100'}>
                                                  {FindPickTypeConst(v.status)?.label}
                                              </FontPixel>
                                          </Box>
                                          <Box alignItems={'flex-start'} w={'30%'} left={3}>
                                              <Text {...Ar15B} color={'black.100'}>
                                                  {PickTypeConvert(v.pickSave, v.idx)}
                                              </Text>
                                          </Box>
                                          <Box alignItems={'flex-end'} w={'40%'}>
                                              <FontPixel {...Ar15B} color={'black.100'}>
                                                  {dateFormatDays(v.pickDate)}
                                              </FontPixel>
                                          </Box>
                                      </HStack>
                                  </Pressable>
                              ))
                            : ['Booked', 'EC-0168', '10 Mar 21', '3'].map((v) => (
                                  <Box alignItems={'center'} w={'25%'}>
                                      <Text fontFamily={'Arch'} fontSize={15} fontWeight={900}>
                                          {v}
                                      </Text>
                                  </Box>
                              ))}
                        <Box my={40} />
                    </ScrollView>
                </VStack>
            </Box>
        </>
    );
};

export default TableDashboard;
