import React from 'react';
import { AmericaMIcon, MasterMIcon, PayPalMIcon, VisaMIcon } from './credit.icons';
import { Box, Pressable, Text } from 'native-base';
import { SizePixel } from '../../../utils/pixcel.convert';
import { LeftIcon } from '../../../assets/icons/left.icon';
import { Ar18M, Ar18SbBlack } from '../../../themes/font.style';

interface IProps {
    creditType: 'A' | 'P' | 'V' | 'M';
    content?: string;
    onPress: any;
    modify: boolean;
}

export const CreditCard = ({ creditType, content, onPress, modify }: IProps) => {
    const renderTypeIcon = () => {
        switch (creditType) {
            case 'A':
                return [<AmericaMIcon />, '-'];
            case 'M':
                return [<MasterMIcon />, '-'];
            case 'P':
                return [<PayPalMIcon />, 'Paypal'];
            case 'V':
                return [<VisaMIcon />, content];
            default:
                return [<VisaMIcon />, ''];
        }
    };

    return (
        <Pressable
            disabled={modify}
            flexDirection={'row'}
            onPress={() => onPress()}
            w={'100%'}
            h={SizePixel(88)}
            alignItems={'center'}
            justifyContent={'space-between'}
            borderBottomWidth={'1px'}
            borderColor={'gray.100'}
        >
            <Box flexDirection={'row'} alignItems={'center'}>
                {renderTypeIcon()[0]}
                {content && (
                    <Text ml={SizePixel(12)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)}>
                        {renderTypeIcon()[1]}
                    </Text>
                )}
            </Box>
            <Pressable onPress={() => onPress()}>
                {creditType === 'P' ? (
                    <LeftIcon />
                ) : (
                    <Text {...Ar18M} lineHeight={SizePixel(18 * 1.27)} color={'gray.300'}>
                        Edit
                    </Text>
                )}
            </Pressable>
        </Pressable>
    );
};
