import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { StyleSheet } from 'react-native';
import { Box, Pressable } from 'native-base';

interface Props {
    children: React.ReactElement;
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
}

export const BottomView = ({ children, onClose, isOpen }: Props) => {
    // const progressValue = useSharedValue(0);
    // const topSize = SizePixel(50.22);
    // const translateArray = () => {
    //     const refry = ScreenHeight / 20;
    //     const refry2 = 1 / 20;
    //     const diff = new Array(21).fill('-').map((_, i) => refry * i);
    //     const diff2 = new Array(21).fill('-').map((_, i) => refry2 * i);
    //     return [diff.reverse(), diff2];
    // };
    //
    // const refryArray = translateArray();
    // const showModal = () => {
    //     progressValue.value = withTiming(1, { duration: 300, easing: Easing.inOut(Easing.ease) });
    //     onClose(true);
    // };
    // const closeModal = () => {
    //     onClose(false);
    // };
    // const panGesture = Gesture.Pan()
    //     .onUpdate((e) => {
    //         if (e.translationY > 0) {
    //             progressValue.value = interpolate(e.translationY, refryArray[0], refryArray[1], {
    //                 extrapolateRight: Extrapolation.EXTEND,
    //             });
    //         }
    //     })
    //     .onEnd((e) => {
    //         if (progressValue.value > 0.9) {
    //             progressValue.value = withTiming(1, { duration: 300, easing: Easing.inOut(Easing.ease) });
    //         } else {
    //             // @ts-ignore
    //             progressValue.value = withTiming(0, { duration: 300, easing: Easing.inOut(Easing.ease) });
    //             runOnJS(closeModal)();
    //         }
    //     });
    //
    // useEffect(() => {
    //     if (isOpen) {
    //         showModal();
    //     } else {
    //         progressValue.value = withTiming(0, { duration: 300, easing: Easing.inOut(Easing.ease) });
    //         runOnJS(closeModal)();
    //     }
    // }, [isOpen]);
    //
    // const containerStyle = useAnimatedStyle(() => {
    //     const progressY = interpolate(progressValue.value, [0, 1], [ScreenHeight + 200, topSize]);
    //     const opacity = interpolate(progressValue.value, [0, 1], [0, 1]);
    //     return {
    //         transform: [{ translateY: progressY }],
    //         height: ScreenHeight,
    //         opacity: 1,
    //         position: 'absolute',
    //         bottom: 0,
    //         backgroundColor: '#FFF',
    //         width: ScreenWidth,
    //         overflow: 'hidden',
    //     };
    // }, []);
    //
    // return (
    //     <AnimatedView style={[containerStyle, [StyleSheet.absoluteFillObject, { zIndex: 100 }]]}>
    //         <GestureDetector gesture={panGesture}>
    //             <Box w={'100%'}>
    //                 <Box w={'100%'} bg={'transfer.100'} style={{ height: '100%' }}>
    //                     {/*<Pressable*/}
    //                     {/*    hitSlop={hitSlopConst}*/}
    //                     {/*    onPress={() => onClose(false)}*/}
    //                     {/*    w={'52px'}*/}
    //                     {/*    h={'6px'}*/}
    //                     {/*    bg={'white.100'}*/}
    //                     {/*    borderRadius={10}*/}
    //                     {/*    position={'absolute'}*/}
    //                     {/*    top={'8px'}*/}
    //                     {/*    alignSelf={'center'}*/}
    //                     {/*/>*/}
    //                     {children}
    //                 </Box>
    //             </Box>
    //         </GestureDetector>
    //     </AnimatedView>
    // );
    return <div />;
};
