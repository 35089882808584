import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import UserProfile from './user.profile';
import BusinessProfile from './profile.business';
import { StatusBar } from 'native-base';

const ProfileWrapper = () => {
    // @ts-ignore
    const user = useSelector((state: RootState) => state.auth.user);
    console.log('USERTYPE', user);
    const check = user?.userType === 1 ? user?.profileCheck === 1 : user?.businessCheck === 1;
    const addressCheck = user?.addressCheck === 1;
    return (
        <>
            <StatusBar barStyle={'dark-content'} />
            {/*@ts-ignore*/}
            <UserProfile check={check} />
        </>
    );
};

export default ProfileWrapper;
