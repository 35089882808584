import React, { useEffect, useState } from 'react';
import { Box, Divider, Image, Pressable, Text } from 'native-base';
import HeaderOnlyBack from '../../header/header.onBack';
import { Dimensions, StatusBar, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import CharityList from './charity.list';
import useAxiosServices from '../../../hooks/axiosHooks';
import { CharityListTypes } from '../../../@types/charity.types';
import { Ar16Sb } from '../../../themes/font.style';
import TabView2 from '../../../components/tab.view2';
import { useNavigate } from 'react-router-dom';
import { MAXWIDTH } from '../../../themes';

const RedeemCharitied = () => {
    const navigation = useNavigate();
    const user = useSelector((state: RootState) => state.auth.user);
    const initialLayout = { width: Dimensions.get('window').width };

    const { axiosService } = useAxiosServices();
    const [data, setData] = useState<CharityListTypes[]>([]);
    const [myData, setMyData] = useState<CharityListTypes[]>([]);

    useEffect(() => {
        getFetch();
        getMyFetch();
    }, []);

    const getFetch = async () => {
        const api = await axiosService.post('/charity/list', { option: '1', skip: 0, take: 10 });
        const { list } = api.data;
        if (list) {
            setData([...list]);
        }
    };

    const getMyFetch = async () => {
        const api = await axiosService.post('/charity/user/list', { option: '2', skip: 0, take: 10 });
        const { list } = api.data;
        console.log(list);
        if (list) {
            setMyData([...list]);
        }
    };

    return (
        <Box flex={1} bg={'white.100'} w={'100vw'} h={'100vh'} alignItems={'center'}>
            <StatusBar barStyle={'light-content'} />
            <Box bg={'blue.200'} w={'100vw'} alignItems={'center'}>
                <Box safeAreaTop p={2} pb={4} w={'100%'} maxW={MAXWIDTH}>
                    <HeaderOnlyBack navigation={navigation} />
                    <Box mt={8} flexDirection={'row'} px={6} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Text fontWeight={700} fontSize={'30px'} fontFamily={'Lato'} color={'white.100'}>
                            Charities
                        </Text>
                        <Text fontWeight={700} fontSize={'14px'} fontFamily={'Arch'} color={'white.100'}>
                            {data.length} campaigns
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box flex={1} p={4} px={4} maxW={MAXWIDTH} h={'100%'}>
                <TabView2 list={data} myList={myData} />
            </Box>
        </Box>
    );
};

export default RedeemCharitied;
