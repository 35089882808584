import React, { useState } from 'react';
// import Tabs, { TabPane } from 'rc-tabs';
import { collectionTypes } from '../@types/collection.types';
import '../assets/css/tabsStyle.css';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar';
import { Box, ScrollView, Text, useTheme } from 'native-base';
import styled from 'styled-components';
import { Ar16Sb } from '../themes/font.style';
import DashBoardReport from '../containers/user/dashBoard.report';
import TableDashboard from '../containers/commons/table.dashboard';
import { SizePixel } from '../utils/pixcel.convert';
import useWindowDimensions from '../hooks/useDimensions';
import { Tab, Tabs } from './tabs';
import { useColors } from '../hooks/useColors';
import { NotScrollBox } from './customUI/not.scroll.box';

const CustomTabs = styled(Tabs)`
    .rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav : {
        height: 40px;
    }
`;
interface Props {
    list: collectionTypes[];
    onRefresh: () => void;
    setView: any;
    refreshing: boolean;
    rData: { one: number; two: number; three: number; four: number };
}

const contentStyle = {
    display: 'flex',
};

const contentStyle2 = {
    display: 'flex',
};

const TabView = ({ list, onRefresh, refreshing, setView, rData }: Props) => {
    const [tabIndex, setTabIndex] = useState<any>('Tab1');
    const theme = useTheme();
    const { mainColor } = useColors();
    const tabTitle = (key) => {
        return (
            <div data-extra="tab-bar-title">
                <Text {...Ar16Sb} color={tabIndex === key ? 'black.100' : 'white.100'}>{`${
                    key === 0 ? 'Impact Report' : 'Collections'
                }`}</Text>
            </div>
        );
    };
    console.log(tabIndex);
    // const changeTab: (selectedTab: { label: string; key: string | number }) => void = (updatedTab) => {
    //     setTabIndex(updatedTab.key);
    // };
    const { sizePix } = useWindowDimensions();
    const [selectedTab, setSelectedTab] = React.useState('Impact Report');
    const changeTab: (selectedTab: { label: string; key: string | number }) => void = (updatedTab) => {
        console.log(updatedTab);
        // @ts-ignore
        setSelectedTab(updatedTab.label);
    };

    return (
        <Box>
            <Tabs
                // @ts-ignore
                value={selectedTab}
                onChange={changeTab}
                styleProps={{
                    barColor: mainColor,
                    headerTextColor: mainColor,
                    selectedHeaderTextColor: '#4C4C4C',
                    activeInkBarColor: '#fff',
                    height: sizePix(40),
                }}
            >
                <Tab label="Impact Report" key={0}>
                    <Box>
                        <DashBoardReport rData={rData} />
                    </Box>
                </Tab>
                <Tab label="Collections" key={1}>
                    <ScrollView showsVerticalScrollIndicator={false} maxHeight={sizePix(431.95)}>
                        <TableDashboard list={list} refreshing={refreshing} onRefresh={onRefresh} />
                    </ScrollView>
                </Tab>
            </Tabs>
            {/*<CustomTabs*/}
            {/*    onChange={(e) => setTabIndex(Number(e))}*/}
            {/*    data-extra="tabs"*/}
            {/*    renderTabBar={() => <SwipeableInkTabBar pageSize={2} speed={5} data-extra="tabbar" />}*/}
            {/*    renderTabContent={() => <TabContent animated={false} />}*/}
            {/*    defaultActiveKey="0"*/}
            {/*>*/}
            {/*    <TabPane tab={tabTitle(0)} data-extra="tabpane" key={`${0}`}>*/}
            {/*        <div style={{ ...contentStyle }}>*/}
            {/*            <DashBoardReport rData={rData} />*/}
            {/*        </div>*/}
            {/*    </TabPane>*/}
            {/*    <TabPane tab={tabTitle(1)} data-extra="tabpane" key={`${1}`}>*/}
            {/*        <Box>*/}
            {/*            <TableDashboard list={list} refreshing={refreshing} onRefresh={onRefresh} />*/}
            {/*        </Box>*/}
            {/*    </TabPane>*/}
            {/*</CustomTabs>*/}
        </Box>
    );
};

export default TabView;
