import React from 'react';
import { Svg, G, Path, Defs, ClipPath, Rect } from 'react-native-svg';
import useWindowDimensions from '../../hooks/useDimensions';

const AppleIcon = () => {
    const { sizePix } = useWindowDimensions();

    return (
        <Svg width={sizePix(25)} height={sizePix(25)} viewBox="0 0 25 25" fill="none">
            <G clip-path="url(#clip0_126_3220)">
                <Path
                    d="M16.7176 1.04115C16.774 1.04115 16.8304 1.04115 16.89 1.04115C17.0284 2.72031 16.376 3.97496 15.5832 4.88355C14.8052 5.78586 13.7399 6.66098 12.017 6.5282C11.902 4.87309 12.5555 3.71149 13.3472 2.805C14.0815 1.96019 15.4278 1.20844 16.7176 1.04115Z"
                    fill="white"
                />
                <Path
                    d="M21.934 18.5186C21.934 18.5354 21.934 18.55 21.934 18.5657C21.4498 20.0064 20.7591 21.2412 19.9163 22.3872C19.1468 23.4275 18.2039 24.8275 16.5203 24.8275C15.0656 24.8275 14.0992 23.9084 12.6083 23.8833C11.0311 23.8582 10.1638 24.6518 8.72174 24.8515C8.55678 24.8515 8.39183 24.8515 8.23007 24.8515C7.17117 24.701 6.3166 23.8771 5.69403 23.1347C3.85825 20.9412 2.43965 18.1077 2.17572 14.4818C2.17572 14.1263 2.17572 13.7718 2.17572 13.4163C2.28746 10.8213 3.57091 8.71137 5.27686 7.68882C6.17719 7.14514 7.41487 6.68196 8.79304 6.88898C9.38368 6.9789 9.98709 7.17755 10.516 7.37411C11.0173 7.56336 11.6441 7.89898 12.2379 7.8812C12.6402 7.8697 13.0403 7.66373 13.4458 7.5184C14.6335 7.09704 15.7977 6.614 17.3323 6.84088C19.1766 7.11482 20.4856 7.91989 21.2944 9.162C19.7343 10.1375 18.5009 11.6075 18.7116 14.1179C18.8989 16.3983 20.2483 17.7324 21.934 18.5186Z"
                    fill="white"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_126_3220">
                    <Rect width="24" height="24" fill="white" transform="translate(0.0546875 0.946289)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export default AppleIcon;
