import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Center, StatusBar, Text } from 'native-base';

import { CollectionHeader } from './collection.header';
import { ScreenWidth, SizePixel } from '../../utils/pixcel.convert';
import { PlainScreen } from './plain.screen';
import { CollectionBottom } from './collection.bottom';
import { ConfirmMapScreen } from './confirm.map.screen';
import { addressListType } from '../../@types/userTypes';
import useAxiosServices, { baseUrl, STRIPE_DEMO_KEY } from '../../hooks/axiosHooks';
import { bagType, IPhotoListTypes } from '../../@types/collection.types';
import { BoxDetailScreen } from './box.detail.screen';
import { DateCollectionScreen } from './date.collection.screen';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { RegularCollectionScreen } from './regular.collection.screen';
import { DescriptionScreen } from './description.screen';
import { PhotoCollectionScreen } from './photo.collection.screen';
import { ShopScreen } from './shop.screen';
import { IProductsTypes, ProductsArray, TCreditBrandTypes, TPayTypes } from './temp.const';
import { BottomView } from './shop.sheet';
import { ProductDetail } from './product.detail';
import { CreditScreen } from './credit.screen';
import { ProductAllAmountRender, ProductQtyRender } from '../../utils/product.helper';
import { DetailScreen } from './detail/detail.screen';
import ModalWrapper2 from '../commons/modal/modal.wrapper2';
import { useColors } from '../../hooks/useColors';
import { v4 as uuid } from 'uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModalWrapper from '../commons/modal/modal.wrapper';
import { ActivityIndicator, Alert } from 'react-native';
import ModalWrapper3 from '../commons/modal/modal.wrapper3';
import { HouseCancelIcon } from '../../assets/icons/house.cancel.icon';
import { UserCreditRender, UserTypePersonal } from '../../utils/helper/user.helper';
import { toast } from 'react-toastify';
import { IPaymentListType } from '../../@types/payment.types';
import { creditChange } from '../../store/authReducer';
import { collectionStepAction } from '../../store/commonReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { PageSetting } from '../../utils/pageSetting';
import { useStripe } from '@stripe/react-stripe-js';
import getStripe from '../payment/get.stripe';

export const MAIN_COLLECTION_PADDING = 32;
export const MAIN_COLLECTION_PADDING_V2 = 28;
export type CollectionPageType =
    | 'Plain'
    | 'Map'
    | 'Collection'
    | 'Date'
    | 'Regular'
    | 'Description'
    | 'Photo'
    | 'Shop'
    | 'Credit'
    | 'Detail';

const limitImage = require('../../assets/image/low.png');
const checked = require('../../assets/icons/checked.png');

const CollectionScreen = () => {
    const route = useParams();
    const idx = route?.idx;
    const { user } = useSelector((state: RootState) => state.auth);
    const [modify, setModify] = useState(false);
    const { mainColor } = useColors();
    const navigation = useNavigate();
    const { axiosService } = useAxiosServices();
    const [step, setStep] = useState(0);
    const [collectionId, setCollectionId] = useState<null | number>(null);
    const [addressData, setAddressData] = useState<null | addressListType>(null);
    const [weekNo, setWeekNo] = useState<string[]>([]);
    const [dateNo, setDateNo] = useState<string[]>([]);
    const [bagLimit, setBagLimit] = useState(0);
    const { collectionStep } = useSelector((state: RootState) => state.common);
    const dispatch = useDispatch();
    //BAG DATA
    const [bagData, setBagData] = useState<bagType[]>([{ type: 1, value: 0 }]);

    //DATE
    const [pickDate, setPickDate] = useState<string | null>(null);
    const [pickTime, setPickTime] = useState<string | null>(null);
    const calenderHandler = (date: string) => {
        setPickDate(date);
    };

    //REGULAR
    const [pickType, setPickType] = useState(1);
    //DESCRIP
    const [description, setDescription] = useState<string | null>('');
    console.log('description', pickType);
    const typePickHandler = (index: number, typeValue: any) => {
        setPickType(typeValue);
    };
    //PHOTO
    const [photoList, setPhotoList] = useState<IPhotoListTypes[]>([]);
    const [saveDefault, setSaveDefault] = useState<boolean>(false);
    const [dogCheck, setDogCheck] = useState<boolean>(false);

    //SHOP
    const [products, setProducts] = useState<IProductsTypes>([]);

    //SHOP SHEET
    const [shopOpen, setShopOpen] = useState<boolean>(false);
    const [targetShop, setShopTarget] = useState(0);
    const handleOpenSheet = (index: number) => {
        setShopTarget(index);
        setShopOpen(true);
    };

    //CREDIT

    const [payType, setPayType] = useState<TPayTypes>('UnCheck');
    //All = card
    //With =credit + card
    //Pay = paypal
    const [creditType, setCreditType] = useState<TCreditBrandTypes>('UnCheck');
    const handlePayType = (type: TPayTypes, credit: TCreditBrandTypes) => {
        setPayType(payType === type ? 'UnCheck' : type);
        setCreditType(credit);
    };

    const [paypal, setPaypal] = useState<{ check: boolean; info: IPaymentListType | null }>({
        check: false,
        info: null,
    });
    const [card, setCard] = useState<{ check: boolean; info: IPaymentListType | null }>({
        check: false,
        info: null,
    });

    const getPaymentList = async () => {
        const api = await axiosService.post('/payment/list');
        const { status, data } = api.data;
        if (status) {
            const { paypalCheck, paypal, cardCheck, card } = data;
            setPaypal({
                check: paypalCheck,
                info: paypal,
            });
            setCard({
                check: cardCheck,
                info: card,
            });
        }
    };
    console.warn('cart', card);

    //VOLUME
    const [limitModalOpen, setLimitModalOpen] = useState(false);

    //BOTTOMSETTING
    const [pageType, setPageType] = useState<CollectionPageType>('Plain');
    const [modalComplete, setModalComplete] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [modalCancelComplete, setModalCancelComplete] = useState(false);
    const handleStep = (type: 'down' | 'up') => {
        // if (step === 3) {
        //     let number = 0;
        //     bagData.forEach((v) => {
        //         const { value, type } = v;
        //         number += value;
        //     });
        //     if (number < bagLimit) {
        //         setLimitModalOpen(true);
        //         return;
        //     }
        // }

        if (type === 'down') {
            if (step === 1) {
                return;
            }
            console.log('collectionStep', collectionStep);
            dispatch(collectionStepAction({ step: step - 1 }));
            setStep((p) => p - 1);
        } else if (type === 'up') {
            if (step >= MAXSTEP) {
                return;
            }
            dispatch(collectionStepAction({ step: step + 1 }));
            setStep((p) => p + 1);
        }
    };
    useEffect(() => {
        if (idx) {
            console.warn('NO');
            setModify(true);
            setCollectionId(Number(idx));
            getUserMain('detail');
            getDetail();
        } else {
            console.warn('NEW');
            getUserMain('noDetail');
            setStep(collectionStep);
        }
        getPaymentList();
        return () => {
            dispatch(collectionStepAction({ step: 1 }));
        };
    }, [route]);

    const handleHeaderBackButtonSwitchInteraction = () => {
        switch (step) {
            case 1:
                return navigation(-1);
            default:
                dispatch(collectionStepAction({ step: step - 1 }));
                return setStep((p) => p - 1);
        }
    };
    console.log('STEP', step);
    const handleFooterBackButtonSwitchInteraction = () => {
        switch (step) {
            case 1:
                return navigation(-1);
            case 2:
                return navigation('/addressInfo', {
                    state: { addressChange: true },
                });
            default:
                return setStep((p) => p - 1);
        }
    };

    const getUserMain = async (type: 'noDetail' | 'detail') => {
        try {
            const api = await axiosService.post('/users/app/address/main');
            const { status, main, locationBags, weekDisabled, dateDisabled, photoList, productList } = api.data;
            if (status) {
                if (type === 'noDetail') {
                    setProducts([...productList]);
                    setAddressData({ ...main });
                    setDescription(main?.description ?? '');
                    setPhotoList([...photoList]);
                }
                setWeekNo([...weekDisabled]);
                setDateNo([...dateDisabled]);
                setBagLimit(locationBags);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const convertImagePath = (list) => {
        return list?.map((v) => ({ url: v?.file?.path ?? '', modify: false, key: v?.key }));
    };
    const getDetail = async () => {
        const api = await axiosService.post('/pick/app/detail', { idx });
        const { status, detail, locationBags, weekDisabled, dateDisabled } = api.data;
        if (status) {
            setAddressData({ ...detail.address });
            setBagData([...detail.bagData]);
            setDescription(detail.description ?? '');
            setDogCheck(detail.dogCheck);
            setProducts([...detail.products]);
            setPickDate(detail.pickDate);
            setPickTime(detail.pickTime);
            console.log(detail.photoList);
            setPhotoList([...convertImagePath(detail.photoList)]);
            // setCodeList([...detail.codeList]);
            // setCreatedTime(detail.createdTime);
            setWeekNo([...weekDisabled]);
            setDateNo([...dateDisabled]);
            setBagLimit(locationBags);
            // setUpdateAt(new Date(detail.updateTime));
            console.log('MAXSTEPMAXSTEPMAXSTEPMAXSTEP', MAXSTEP);
            if (detail.creditInfo) {
                setStep(MAXSTEP + 1);
                const creditInfo = detail.creditInfo;
                setPayType(creditInfo.payType);
                setCreditType(creditInfo.creditInfo);
            } else {
                setStep(MAXSTEP);
            }
        }
    };

    const updateProductsQty = (type: 'up' | 'down' | 'step', index, value = 0) => {
        if (type === 'up') {
            const temp = [...products];
            temp[index].qty = temp[index].qty + 1;
            setProducts([...temp]);
        } else if (type === 'down') {
            const temp = [...products];
            if (temp[index].qty > 0) {
                temp[index].qty = temp[index].qty - 1;
                setProducts([...temp]);
            }
        } else {
            const temp = [...products];
            if (value > 0) {
                temp[index].qty = value;
                setProducts([...temp]);
            }
        }
    };
    useEffect(() => {
        if (ProductAllAmountRender(products) === 0) {
            setPayType('UnCheck');
            setCreditType('UnCheck');
        }
    }, [products]);

    const updateProductsRemove = (index) => {
        const temp = [...products];
        if (temp[index].qty > 0) {
            temp[index].qty = 0;
            setProducts([...temp]);
        }
    };
    console.log('CREDIT TYPE', creditType);
    console.log('PAY YUPR', payType);
    const headerRef = useRef();
    const bottomRef = useRef();
    //RENDER
    const renderArrayConvert = (array: any[]) => {
        const amount = ProductAllAmountRender(products);
        console.log('amount', amount);
        if (amount > 0) {
            const temp = [
                ...array,
                <CreditScreen
                    card={card}
                    paypal={paypal}
                    modify={modify}
                    setPageType={setPageType}
                    creditType={creditType}
                    payType={payType}
                    products={products}
                    setPayType={setPayType}
                    setCreditType={setCreditType}
                    handlePayType={handlePayType}
                />,
                // <StripeProvider publishableKey={STRIPE_DEMO_KEY} merchantIdentifier="merchant.identifier">
                <DetailScreen
                    idx={collectionId}
                    setPageType={setPageType}
                    product={products}
                    modify={modify}
                    bagData={bagData}
                    pickDate={pickDate}
                    pickTime={pickTime}
                    description={description}
                    pickType={pickType}
                    photoList={photoList}
                    dogCheck={dogCheck}
                    moveStep={setStep}
                    addressData={addressData}
                />,
                // </StripeProvider>,
            ];
            return temp;
        } else {
            const temp = [
                ...array,
                <DetailScreen
                    idx={collectionId}
                    setPageType={setPageType}
                    product={products}
                    modify={modify}
                    bagData={bagData}
                    pickDate={pickDate}
                    pickTime={pickTime}
                    pickType={pickType}
                    photoList={photoList}
                    dogCheck={dogCheck}
                    moveStep={setStep}
                    description={description}
                    addressData={addressData}
                />,
            ];
            return temp;
        }
    };
    const creditGenerator = () => {
        const allAmount = ProductAllAmountRender(products);
        const userCredit = UserCreditRender(user);
        const creditRefry = allAmount - userCredit > 0;

        const creditInfo =
            payType === 'All' || payType === 'Pay'
                ? { card: ProductAllAmountRender(products), credit: 0 }
                : { card: creditRefry ? allAmount - userCredit : 0, credit: creditRefry ? userCredit : allAmount };
        return creditInfo;
    };
    console.log('payType', creditGenerator());
    //DATA
    const dataGenerator = () => {
        const creditInfo = creditGenerator();
        const data = {
            address: addressData,
            bagData,
            products,
            pickTime: pickTime ?? '',
            pickDate,
            description,
            dogCheck,
            pickType,
            saveDefault,
            idx,
            creditType,
            payType,
            creditInfo,
            paymentKey: paymentKey,
        };

        const form = new FormData();
        for (const [key, value] of Object.entries(data)) {
            form.append(key, value as any);
        }
        photoList.map((v, i) => {
            if (v.modify) {
                // const { path, mime } = v.file;
                // const type = mime.split('/')[1] ? mime.split('/')[1] : 'jpg';
                // const imageData = {
                //     uri: path,
                //     type: mime,
                //     name: uuid.v4() + type,
                //     modify: true,
                // };
                // form.append(`file_${i}`, imageData as any);
            } else {
                // const { path } = v.file;
                // const imageData = {
                //     uri: path,
                //     type: 'jpg',
                //     name: path,
                //     modify: false,
                // };
                // form.append(`file_${i}`, imageData as any);
            }
        });
        return data;
    };

    // const { initPaymentSheet, presentPaymentSheet, confirmPaymentSheetPayment } = useStripe();

    const fetchPaymentStripe = async (amount) => {
        const response = await axiosService.post('payment/checkout', { amount });
        const { paymentIntent, ephemeralKey, customer, paymentIntentId } = response.data;
        console.log('ERROR', response.data);
        return {
            paymentIntent,
            ephemeralKey,
            customer,
            paymentIntentId,
        };
    };
    const [paymentKey, setPaymentKey] = useState<null | string>(null);

    const initializePaymentSheet = async () => {
        if (payType === 'Pay') {
            return;
        }
        if (payType === 'UnCheck') {
            return;
        }
        if (modify) {
            return;
        }
        const allAmount = ProductAllAmountRender(products);
        const userCredit = UserCreditRender(user);
        const creditRefry = allAmount - userCredit > 0;
        const creditInfo =
            payType === 'All'
                ? { card: ProductAllAmountRender(products), credit: 0 }
                : { card: creditRefry ? allAmount - userCredit : 0, credit: creditRefry ? userCredit : allAmount };
        const { paymentIntent, ephemeralKey, customer, paymentIntentId } = await fetchPaymentStripe(creditInfo.card);

        // const { error } = await initPaymentSheet({
        //     customerId: customer,
        //     customerEphemeralKeySecret: ephemeralKey,
        //     paymentIntentClientSecret: paymentIntent,
        //     allowsDelayedPaymentMethods: false,
        //     customFlow: true,
        //     merchantDisplayName: 'Recan Bag',
        // } as any);
        setPaymentKey(paymentIntentId);
        // if (error) {
        //     Alert.alert(`Error code: ${error.code}`, error.message);
        //     throw 'ERROR';
        // }
    };

    const openPaymentSheet = async () => {
        // see below
        // @ts-ignore
        // const { error, p } = await confirmPaymentSheetPayment();
        // console.log('ERROR', error);
        // if (error) {
        //     Toast.show({
        //         type: 'info',
        //         text1: error.message,
        //     });
        //     return { payment: false };
        // }
        // return { payment: true };
    };

    const paypalPurchase = async (key) => {
        setPaymentKey(key);
        setTimeout(async () => {
            await saveAPI();
        }, 1000);
        await saveAPI();
    };
    const [paypalOpen, setPaypalOpen] = useState(false);
    const saveRefry = async () => {
        creditType === 'P' ? setPaypalOpen(true) : await saveAPI();
    };

    const purchasePaymentWithStripe = async () => {
        const amount = ProductAllAmountRender(products);
        if (!card?.info) {
            return false;
        }
        if (amount === 0) {
            return false;
        }
        const api = await axiosService.post('payment/stripe/web', { amount, idx: card.info?.idx });
        const { result } = api.data;
        return result;
    };
    const saveAPI = async () => {
        try {
            const form = dataGenerator();
            if (form.creditInfo.card + form.creditInfo.card > 0) {
                if (form.creditType === 'P') {
                } else {
                    const payment = await purchasePaymentWithStripe();
                    if (!payment) {
                        toast('Payment Error');
                        return;
                    }
                }
            }

            const api = await axiosService.post(`${baseUrl}/pick/user/insert`, form);
            console.log('api', api);
            const { status, idx } = api.data;
            if (status) {
                await photoApi(idx);
                await sendEmail(idx);
            }
        } catch (e) {
            console.log(e);
        } finally {
        }
    };

    const updateAPi = async () => {
        try {
            const form = dataGenerator();
            const api = await axiosService.post(`${baseUrl}/pick/user/update`, form);
            await photoApi(collectionId);
            const { status, idx } = api.data;
            if (status) {
                await photoApi(collectionId);
                setModalComplete(true);
            }
        } catch (e) {
            console.log(e);
        } finally {
        }
    };

    const photoApi = async (idx) => {
        try {
            const form = new FormData();
            photoList.forEach((v, i) => {
                if (v.modify) {
                    form.append(`file_${i}`, v.file as any);
                } else {
                    const { url } = v;
                    form.append('photoList[]', url);
                }
            });
            const accessToken = await AsyncStorage.getItem('accessToken');
            console.log('photoList', photoList.length);
            form.append('idx', idx);
            form.append('type', 'Pick');

            const api = await axiosService.post(`${baseUrl}/pick/user/photoSave`, form, {
                data: form,
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` },
                transformRequest: (data, headers) => {
                    // !!! override data to return formData
                    // since axios converts that to string
                    return form;
                },
            });
            const { status } = api.data;
            return status;
        } catch (e) {
            console.log(e);
        }
    };

    const renderComponentWithStep = useMemo(() => {
        const userType = user?.userType === 1 ? 'personal' : 'business';
        const UserRender = [
            <Box px={SizePixel(MAIN_COLLECTION_PADDING)}>
                <PlainScreen setPageType={setPageType} />
            </Box>,
            <ConfirmMapScreen addressData={addressData} setPageType={setPageType} />,
            <BoxDetailScreen
                headerRef={headerRef}
                bottomRef={bottomRef}
                bagData={bagData}
                setBagData={setBagData}
                setPageType={setPageType}
            />,
            <DateCollectionScreen
                weekNo={weekNo}
                dateNo={dateNo}
                pickDate={pickDate}
                calenderHandler={calenderHandler}
                pickTime={pickTime}
                setPickTime={setPickTime}
                setPageType={setPageType}
            />,
            <DescriptionScreen setDescription={setDescription} description={description} setPageType={setPageType} />,
            <PhotoCollectionScreen
                photoList={photoList}
                setPhotoList={setPhotoList}
                saveDefault={saveDefault}
                setSaveDefault={setSaveDefault}
                dogCheck={dogCheck}
                setDogCheck={setDogCheck}
                setPageType={setPageType}
            />,
            <ShopScreen
                modify={modify}
                products={products}
                updateProduct={updateProductsQty}
                removeProduct={updateProductsRemove}
                handleOpenSheet={handleOpenSheet}
                setPageType={setPageType}
            />,
        ];
        const BusinessRender = [
            <Box px={SizePixel(MAIN_COLLECTION_PADDING)}>
                <PlainScreen setPageType={setPageType} />
            </Box>,
            <ConfirmMapScreen addressData={addressData} setPageType={setPageType} />,
            <BoxDetailScreen
                headerRef={headerRef}
                bottomRef={bottomRef}
                setPageType={setPageType}
                bagData={bagData}
                setBagData={setBagData}
            />,
            <DateCollectionScreen
                setPageType={setPageType}
                weekNo={weekNo}
                dateNo={dateNo}
                pickDate={pickDate}
                calenderHandler={calenderHandler}
                pickTime={pickTime}
                setPickTime={setPickTime}
            />,
            <RegularCollectionScreen pickType={pickType} typePickHandler={typePickHandler} setPageType={setPageType} />,
            <DescriptionScreen setPageType={setPageType} setDescription={setDescription} description={description} />,
            <PhotoCollectionScreen
                setPageType={setPageType}
                photoList={photoList}
                setPhotoList={setPhotoList}
                saveDefault={saveDefault}
                setSaveDefault={setSaveDefault}
                dogCheck={dogCheck}
                setDogCheck={setDogCheck}
            />,
            <ShopScreen
                modify={modify}
                setPageType={setPageType}
                products={products}
                updateProduct={updateProductsQty}
                removeProduct={updateProductsRemove}
                handleOpenSheet={handleOpenSheet}
            />,
        ];
        const renderArray = userType === 'personal' ? UserRender : BusinessRender;
        const convert = renderArrayConvert(renderArray);
        return { render: convert[step - 1], max: convert.length, all: convert };
    }, [
        step,
        user,
        addressData,
        card,
        paypal,
        bagData,
        description,
        pickType,
        pickDate,
        pickTime,
        photoList,
        dogCheck,
        saveDefault,
        products,
        payType,
        creditType,
        bottomRef,
        headerRef,
    ]);
    const MAXSTEP = renderComponentWithStep.max;

    //REDNER REFRY
    const renderDisabled = () => {
        switch (pageType) {
            case 'Plain':
                return false;
            case 'Map':
                return !addressData;
            case 'Collection':
                let collectionCheck = false;
                bagData.forEach((v) => {
                    const { value, type } = v;
                    console.log('TYPE', typeof type);
                    if (type === 5) {
                        collectionCheck = true;
                    }
                    if (value <= 0) {
                        collectionCheck = true;
                    }
                });
                return collectionCheck;
            case 'Date':
                return !pickDate;
            case 'Description':
                return description === '';
            case 'Photo':
                return false;
            case 'Shop':
                return false;
            case 'Credit':
                return payType === 'UnCheck';
            case 'Regular':
                return pickType === 99;
            case 'Detail':
                return false;
            default:
                return false;
        }
    };
    console.warn('STEP', step);

    const deletePick = async (type: 1 | 2) => {
        try {
            const api = await axiosService.post('/pick/app/delete', { idx, type });
            const { status } = api;
            if (status) {
                setModalCancelComplete(true);
            }
        } catch (e) {
            console.log(e);
            toast('Sorry, error');
        }
    };

    const sendEmail = async (id) => {
        const api = await axiosService.post('/pick/user/pick/email', { idx: id, bagData: bagData });
        const { status, idx } = api.data;
        if (status) {
            setModalComplete(true);
        }
    };
    console.log(headerRef);
    return (
        <>
            <Box {...PageSetting} height={'100vh'} overflow={'hidden'} width={'100%'}>
                <Box safeAreaTop>
                    <CollectionHeader
                        propsRef={headerRef}
                        maxStep={MAXSTEP}
                        step={step}
                        modify={modify}
                        cancelOption={() => setModalCancel(true)}
                        backOption={handleHeaderBackButtonSwitchInteraction}
                    />
                    {renderComponentWithStep.render ?? (
                        <Box w={'100%'} h={'70vh'} alignItems={'center'} justifyContent={'center'}>
                            <ActivityIndicator color={mainColor} />
                        </Box>
                    )}
                    {step === 0 && (
                        <Box w={'100%'} h={'70vh'} alignItems={'center'} justifyContent={'center'}>
                            <ActivityIndicator color={mainColor} />
                        </Box>
                    )}
                </Box>
                {renderComponentWithStep.render && (
                    <CollectionBottom
                        propsRef={bottomRef}
                        initializePaymentSheet={initializePaymentSheet}
                        saveAPI={modify ? updateAPi : saveRefry}
                        renderDisabled={renderDisabled()}
                        modify={modify}
                        product={products}
                        pageType={pageType}
                        MAXSTEP={MAXSTEP}
                        step={step}
                        handleStep={handleStep}
                        backOption={handleFooterBackButtonSwitchInteraction}
                    />
                )}
            </Box>
            <BottomView isOpen={shopOpen} onClose={setShopOpen}>
                <ProductDetail
                    isOpen={shopOpen}
                    product={products[targetShop]}
                    index={targetShop}
                    updateProduct={updateProductsQty}
                    onClose={setShopOpen}
                />
            </BottomView>
            <ModalWrapper
                open={modalComplete}
                onClose={() => {
                    setModalComplete(false);
                    navigation('/main', { replace: true });
                }}
                image={checked}
                title={'Congrats!'}
                content={'Your order will be confirmed\n' + 'from or of your driver'}
                onHandler={[
                    {
                        text: 'Got it!',
                        onPress: () => {
                            setModalComplete(false);
                            navigation('/main', { replace: true });
                        },
                        color: mainColor,
                    },
                ]}
            />
            <ModalWrapper3
                open={modalCancel}
                onClose={() => {
                    setModalCancel(false);
                }}
                image={<HouseCancelIcon color={mainColor} />}
                title={'Cancel Your Pickup?'}
                content={
                    UserTypePersonal(user)
                        ? 'Do you want to cancel this pickup? '
                        : 'Do you want to cancel this and all future pickups of this event, or only a selected pickup?'
                }
                onHandler={
                    UserTypePersonal(user)
                        ? [
                              {
                                  text: 'Delete This Pickup',
                                  onPress: () => {
                                      setModalCancel(false);
                                      deletePick(1);
                                  },
                                  color: mainColor,
                              },
                          ]
                        : [
                              {
                                  text: 'Delete Only This Pickup',
                                  onPress: () => {
                                      setModalCancel(false);
                                      deletePick(1);
                                  },
                                  color: mainColor,
                              },
                              {
                                  text: 'Delete All Futue Pickup',
                                  onPress: () => {
                                      setModalCancel(false);
                                      deletePick(2);
                                  },
                                  color: 'gray.200',
                              },
                          ]
                }
            />
            <ModalWrapper2
                open={limitModalOpen}
                onClose={() => {
                    setLimitModalOpen(false);
                }}
                image={limitImage}
                title={'Low volume'}
                content={`Your volume is under the minimum ${bagLimit}. Please check about the Minimum volume to pick up.`}
                onHandler={[
                    {
                        text: 'Got it!',
                        onPress: () => {
                            setLimitModalOpen(false);
                        },
                        color: mainColor,
                    },
                ]}
            />
            <ModalWrapper
                open={modalCancelComplete}
                onClose={() => {
                    setModalCancelComplete(false);
                    navigation('/main', { replace: true });
                }}
                image={checked}
                title={'The pickup has been cancelled'}
                onHandler={[
                    {
                        text: 'Got it!',
                        onPress: () => {
                            setModalCancelComplete(false);
                            navigation('/main', { replace: true });
                        },
                        color: mainColor,
                    },
                ]}
            />
            {/*<PaypalSheet amount={creditGenerator().card} setOpen={setPaypalOpen} open={paypalOpen} submitHandler={paypalPurchase} />*/}
        </>
    );
};

export default CollectionScreen;
