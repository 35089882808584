import React, { useState } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { collectionTypes } from '../@types/collection.types';
import '../assets/css/tabsStyle.css';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar';
import { Box, Text } from 'native-base';
import styled from 'styled-components';
import { Ar16Sb } from '../themes/font.style';
import DashBoardReport from '../containers/user/dashBoard.report';
import TableDashboard from '../containers/commons/table.dashboard';
import CharityList from '../containers/user/redeem/charity.list';
import { CharityListTypes } from '../@types/charity.types';
const CustomTabs = styled(Tabs)`
    .rc-tabs-ink-bar : {
        height: 100%;
    }
`;
interface Props {
    list: CharityListTypes[];
    myList: CharityListTypes[];
}

const contentStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: '-10px',
};

const contentStyle2 = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-10px',
};

const TabView2 = ({ list, myList }: Props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const tabTitle = (key) => {
        return (
            <div data-extra="tab-bar-title">
                <Text {...Ar16Sb} color={tabIndex === key ? 'black.100' : 'white.100'}>{`${
                    key === 0 ? 'My Charity' : 'All Charities'
                }`}</Text>
            </div>
        );
    };
    console.log(tabIndex);

    return (
        <CustomTabs
            onChange={(e) => setTabIndex(Number(e))}
            data-extra="tabs"
            renderTabBar={() => <SwipeableInkTabBar pageSize={2} speed={5} data-extra="tabbar" />}
            renderTabContent={() => <TabContent animated={false} />}
            defaultActiveKey="0"
        >
            <TabPane tab={tabTitle(0)} data-extra="tabpane" key={`${0}`}>
                <div style={contentStyle}>
                    <CharityList list={myList} my={true} />
                </div>
            </TabPane>
            <TabPane tab={tabTitle(1)} data-extra="tabpane" key={`${1}`}>
                <Box mx={'12px'} {...contentStyle2}>
                    <CharityList list={list} />
                </Box>
            </TabPane>
        </CustomTabs>
    );
};

export default TabView2;
