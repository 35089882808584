import React, { useState } from 'react';
import { Box, Center, HStack, Text, VStack } from 'native-base';
import DashboardCard from '../../components/card/Dashboard.card';
import WasteIcon from '../../assets/icons/waste.icon';
import EcoCarIcon from '../../assets/icons/EcoCar.icon';
import LightIcon from '../../assets/icons/Light.icon';
import BottleIcon from '../../assets/icons/bottle.icon';
import { Grid } from '@mui/material';
import { SizePixel } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';
import { useColors } from '../../hooks/useColors';

interface Props {
    rData: { one: number; two: number; three: number; four: number };
}

const DashBoardReport = ({ rData }: Props) => {
    const value = { one: 0, two: 0, three: 0, four: 0 };
    const { sizePix } = useWindowDimensions();
    const { mainColor } = useColors();
    return (
        <Box mt={sizePix(32.65)} flex={1} justifyContent={'center'} overflowX={'hidden'}>
            <VStack justifyContent={'space-between'} alignItems={'center'} space={sizePix(17.16)}>
                <HStack justifyContent={'space-between'} alignItems={'center'} space={sizePix(18.56)}>
                    <DashboardCard
                        Icon={<WasteIcon width={sizePix(70)} height={sizePix(71)} color={mainColor} />}
                        value={`${rData.one} mins`}
                        subTitle={'Total recycling time\n' + 'landfills or the '}
                    />
                    <DashboardCard
                        Icon={<EcoCarIcon width={sizePix(71)} height={sizePix(71)} color={mainColor} />}
                        value={`${rData.two} kg`}
                        subTitle={'Greenhouse gas\n Avoiding 13 km of driving'}
                    />
                </HStack>
                <HStack justifyContent={'space-between'} alignItems={'center'} space={sizePix(18.56)}>
                    <DashboardCard
                        Icon={<LightIcon width={sizePix(70)} height={sizePix(70)} color={mainColor} />}
                        value={`${rData.three} kWh`}
                        subTitle={'50W light bulb on\n for 8,580 hours'}
                    />
                    <DashboardCard
                        Icon={<BottleIcon width={sizePix(70)} height={sizePix(71)} color={mainColor} />}
                        value={`${rData.four} kg`}
                        subTitle={'Waste diverted from\n landfills or the oceans'}
                    />
                </HStack>
            </VStack>
        </Box>
    );
};

export default DashBoardReport;
