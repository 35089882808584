import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Pressable, Text } from 'native-base';

import { Ar16Sb } from '../../themes/font.style';
import { useColors } from '../../hooks/useColors';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { Img } from 'react-image';
import useWindowDimensions from '../../hooks/useDimensions';
import { MapMarker } from '../../assets/icons/map.marker';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import { FontPixel } from '../typographys/font.pixel';

interface Props {
    moveAdjust: () => void;
    marker: { lat: any; lot: any }[];
}
const mapLib: any = ['places'];
const pin = require('../../assets/icons/address-pin.png');
const MapScreen = ({ marker, moveAdjust }: Props) => {
    console.log('maker.', marker);
    const mapRef = useRef(null);
    const { mainColor } = useColors();
    useEffect(() => {
        mapChnage();
    }, [marker]);

    const mapChnage = () => {
        const latitude = marker[0].lat;
        const longitude = marker[0].lot;
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI',
        libraries: mapLib,
    });

    const [center, setCenter] = useState({
        lat: 51.512501,
        lng: -0.088137,
    });

    const onLoad = (m) => {
        init(m);
    };

    const init = (m: google.maps.Map) => {
        // @ts-ignore
        setMap(m);
        const latitude = marker[0].lat;
        const longitude = marker[0].lot;
        makeBounds(m, latitude, longitude);
    };
    const [map, setMap] = React.useState<any>(null);
    const [markerList, setMarkerList] = useState<google.maps.LatLng[]>([]);
    const makeBounds = (m: google.maps.Map, lat: number, lng: number): void => {
        // eslint-disable-next-line no-undef
        const make = new google.maps.LatLngBounds({ lat, lng }, { lat, lng });
        // eslint-disable-next-line no-undef
        const aosition = new google.maps.LatLng({ lat, lng });
        m.fitBounds(make);
        m.setCenter({ lat, lng });
        setCenter({ lat, lng });
        setMap(m);
        setMarkerList([aosition]);
    };
    const mapOptions: google.maps.MapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
        rotateControl: false,
        scrollwheel: false,
        panControl: false,
        scaleControl: false,
        maxZoom: 16,
        minZoom: 15,
        draggable: false,
    };

    const onUnmount = React.useCallback(() => {
        setMap(null);
    }, []);
    const { width, height, sizePix } = useWindowDimensions();
    if (isLoaded && marker.length > 0) {
        return (
            <>
                <Box w={'100%'}>
                    <Box w={width} maxWidth={InitWidthSizeScreen} h={sizePix(234)} justifyContent={'center'}>
                        <GoogleMap
                            options={mapOptions}
                            ref={mapRef}
                            mapContainerStyle={{ flex: 1 }}
                            center={center}
                            zoom={15}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            {markerList.map((v) => (
                                <>
                                    <MapMarker disabled={false} />
                                </>
                            ))}
                        </GoogleMap>
                        {marker.map(({ lat, lot }) => (
                            <Pressable
                                onPress={() => moveAdjust()}
                                style={{
                                    borderRadius: 100,
                                    width: sizePix(120),
                                    height: sizePix(41),
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                backgroundColor={mainColor}
                                position={'absolute'}
                                bottom={4}
                                zIndex={2}
                                alignSelf={'center'}
                            >
                                <FontPixel {...Ar16Sb} color={'#fff'}>
                                    Adjust pin
                                </FontPixel>
                            </Pressable>
                        ))}
                    </Box>
                </Box>
            </>
        );
    } else {
        return <Text>Loading</Text>;
    }
};
export default MapScreen;
