export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const NumberFixed = (n: number) => {
    if (isNaN(n)) {
        return (0.0).toFixed(2);
    }
    if (n === 0) {
        return (0.0).toFixed(2);
    }
    return n.toFixed(2);
};

export const NumberFormatting = (n: number) => {
    return numberWithCommas(NumberFixed(n));
};
