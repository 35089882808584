import React, { useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Center,
    Input,
    Stack,
    IconButton,
    Button,
    HStack,
    Divider,
    ScrollView,
    Image,
    Heading,
    useToast,
    Modal,
    VStack,
    StatusBar,
} from 'native-base';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DefaultHeader from '../header/header.default';

import useAxiosServices from '../../hooks/axiosHooks';
import { useDispatch } from 'react-redux';
import LabelInput from '../../components/customInput/Label.input';
import { Ar17R, Ar25SbBlack } from '../../themes/font.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MAXWIDTH } from '../../themes';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';
import { FontPixel } from '../../components/typographys/font.pixel';

const SingInSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'This password is too short. It must contain at least 8 characters.')
        .required('Please enter your password'),
    passwordCheck: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match"),
});
const mailIcon = require('../../assets/icons/sendMail.png');
const PasswordChange = () => {
    const { handleChange, handleBlur, handleSubmit, errors, touched, initialValues, resetForm, values } = useFormik({
        validationSchema: SingInSchema,
        initialValues: {
            password: '',
            passwordCheck: '',
        },
        onSubmit: async (value) => {
            await resetPassword();
        },
    });
    const route: any = useLocation();
    const state: { code: any; email: any; accountType: any; password: any } = route.state;
    const navigation = useNavigate();

    const email = state?.email;
    console.log('EMAIL', state);
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const mailHandler = () => {
        toast('Password Change complete');
        navigation('/login');
    };

    const resetPassword = async () => {
        try {
            const api = await axiosService.post('/users/reset/password', {
                email,
                password: values.password,
                passwordCheck: values.passwordCheck,
            });
            const { status } = api.data;
            if (status) {
                mailHandler();
            } else {
                toast('error');
            }
        } catch (e) {
            toast('error');
        }
    };
    const { sizePix } = useWindowDimensions();
    return (
        <>
            <StatusBar barStyle={'dark-content'} />
            <Box w={'100%'} bg={'white.100'} alignItems={'center'} height={'100vh'}>
                <DefaultHeader navigation={navigation} bg={'white.100'} />
                <Box maxW={InitWidthSizeScreen} w={'100%'} px={sizePix(28)} alignItems={'center'} bg={'white.100'}>
                    <Box safeAreaBottom flexGrow={1} justifyContent={'space-between'} w={'100%'}>
                        <ScrollView>
                            <Box pb="10" mt={sizePix(70)}>
                                <FontPixel mb={2} fontFamily={'Arch'} fontWeight={700} fontSize={28} color={'black.100'}>
                                    Reset password
                                </FontPixel>
                            </Box>
                            <Stack space={8} width={'100%'}>
                                <LabelInput
                                    bg={'white.100'}
                                    type={'password'}
                                    label={'New Password'}
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                    onChangeText={handleChange('password')}
                                    placeholder="password"
                                />
                                <LabelInput
                                    bg={'white.100'}
                                    type={'password'}
                                    label={'Confirm Password'}
                                    value={values.passwordCheck}
                                    error={errors.passwordCheck}
                                    touched={touched.passwordCheck}
                                    onChangeText={handleChange('passwordCheck')}
                                    placeholder="Confirm Password"
                                />
                            </Stack>

                            <Center mt={'30px'}>
                                <Stack space={4} width={'100%'}>
                                    <Button
                                        h={sizePix(53)}
                                        my={2}
                                        colorScheme={'blue.200'}
                                        onPress={() => handleSubmit()}
                                        variant={'basicButton'}
                                    >
                                        <HStack alignItems={'center'}>
                                            <Box alignItems={'center'} width={'100%'}>
                                                <FontPixel color={'white.100'} fontFamily={'Arch'} fontWeight={'700'} fontSize={19}>
                                                    Reset
                                                </FontPixel>
                                            </Box>
                                        </HStack>
                                    </Button>
                                </Stack>
                            </Center>
                        </ScrollView>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PasswordChange;
