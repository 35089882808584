import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from '../hooks/useAuth';
import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export type GuardProps = {
    children: ReactElement | null;
};

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLogin, user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const check = user?.userType === 1 ? user?.profileCheck === 1 : user?.businessCheck === 1;
    const addressCheck = user?.addressCheck === 1;
    console.log('check', check);
    console.log('check', user);

    useEffect(() => {
        if (!isLogin) {
            navigate('login', { replace: true });
        }
        if (isLogin) {
            if (!check) {
                navigate('/profile', { replace: true });
            }
            if (!addressCheck) {
                navigate('/addressInfo', { replace: true });
            }
        }
    }, [isLogin, user]);

    return children;
};

export default AuthGuard;
