import React, { useMemo, useState } from 'react';
import { Box, Button, Flex, Heading, HStack, KeyboardAvoidingView, ScrollView, Text, useToast, VStack } from 'native-base';
import DefaultHeader from '../header/header.default';
import LabelInput from '../../components/customInput/Label.input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Appearance, Platform, TouchableOpacity } from 'react-native';
import DatePicker from 'react-datepicker';
import { dateFormat, dateFormatNotTime } from '../../utils/times';
import SelectInput from '../../components/customInput/select.input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import useAxiosServices from '../../hooks/axiosHooks';
import { loginAction, logoutAction, profileSetting } from '../../store/authReducer';
import BackIcon from '../../assets/icons/back.icon';
import { Ar18M, Ar18SbBlack, Ar21Sb, Ar36B } from '../../themes/font.style';
import ProfileCard from '../commons/profile.card';
import BackGray from '../../assets/icons/back.gray';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MAXWIDTH } from '../../themes';
import useAuth from '../../hooks/useAuth';
import { SizePixel } from '../../utils/pixcel.convert';
import { PageSetting } from '../../utils/pageSetting';
import CustomDatePicker from '../../components/customInput/custome.datePicker';
import DefaultDown from '../../components/dropdown/default.down';
import { TransactionBusinessCase, TransactionBusinessCase2 } from '../../etc/bookStatusCase';
import { FontPixel } from '../../components/typographys/font.pixel';
import useWindowDimensions from '../../hooks/useDimensions';
import { useColors } from '../../hooks/useColors';
import { addressConvert } from '../../utils/helper/address.helper';

const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your firstName'),
    email: Yup.string().email('Please enter a valid email').min(4, 'email Too short').required('Please enter your email'),
    birthDate: Yup.string().required('Please enter your birthdate'),
    mobile: Yup.string().required('Please enter your Mobile'),
    gender: Yup.string().required('Please enter your gender'),
});

interface Props {
    check: boolean;
}

const UserProfile = ({ check }: Props) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const { mainColor } = useColors();
    const {
        isValid,
        setTouched,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        initialValues,
        resetForm,
        values,
    } = useFormik({
        validationSchema: ProfileSchema,
        initialValues:
            user?.userType === 1
                ? {
                      firstName: user?.userFirstName ?? '',
                      LastName: user?.userName ?? '',
                      email: user?.userEmail ?? user?.userId,
                      birthDate: user?.userBirth,
                      // birthDate: new Date('1993-06-29'),

                      mobile: user?.userPhone ?? '',
                      gender: user?.userGender ?? '',
                  }
                : {
                      abn: user?.abn ?? '',
                      businessType: user?.businessType ?? '',
                      businessName: user?.businessName ?? '',
                      firstName: user?.userFirstName ?? '',
                      LastName: user?.userName ?? '',
                      email: user?.userEmail ?? user?.userId,
                      birthDate: user?.userBirth,
                      // birthDate: new Date('1993-06-29'),

                      mobile: user?.userPhone ?? '',
                      gender: user?.userGender ?? '',
                  },
        onSubmit: async (value) => {
            console.log(value);
            await profileUpdate(value);
        },
    });
    const { axiosService } = useAxiosServices();
    const [openBirth, setOpenBirth] = useState<boolean>(false);
    const [edit, setEdit] = useState(false);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const profileUpdate = async (value: any) => {
        try {
            const api =
                user?.userType === 2
                    ? await axiosService.post('/users/app/business/update', value)
                    : await axiosService.post('/users/app/profile/update', value);
            const { status } = api.data;
            if (status) {
                const getProfile = await axiosService.post('/users/app/profile');
                const { data: profileData, status: profileStatus } = getProfile.data;
                if (profileStatus) {
                    dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                    dispatch(loginAction());
                    userProfileAddressCheck();
                    toast('Update');
                } else {
                    toast('error');
                }
            } else {
                toast('error');
            }
        } catch (e) {
            console.log(e);
            toast('error');
        } finally {
            setEdit(false);
        }
    };

    const userProfileAddressCheck = () => {
        const addressCheck = user?.addressCheck === 1;
        if (!addressCheck) {
            console.log('go to address');
            navigation('/addressAdd' as never);
            return;
        } else {
            navigation('/main');
        }
    };
    const dateChangeHandler = (value) => {
        setFieldValue('birthDate', dateFormatNotTime(value));
    };
    const { logout } = useAuth();
    const typeBusinessHanlder = (index: number, typeValue: any) => {
        setFieldValue('businessType', typeValue);
    };
    const { sizePix } = useWindowDimensions();
    const ProfileListInput = useMemo(() => {
        if (user && user.userType === 1) {
            return (
                <VStack my={8} space={'8'} mb={18} alignSelf={'center'} w={'100%'}>
                    <LabelInput
                        bg={'white.100'}
                        label={'First name'}
                        onBlur={handleBlur('firstName')}
                        value={values.firstName}
                        error={errors.firstName}
                        touched={touched.firstName}
                        onChangeText={handleChange('firstName')}
                        placeholder="First name"
                    />
                    <LabelInput
                        bg={'white.100'}
                        label={'Last name'}
                        onBlur={handleBlur('Lastname')}
                        value={values.LastName}
                        error={errors.LastName}
                        touched={touched.LastName}
                        onChangeText={handleChange('LastName')}
                        placeholder="Last name"
                    />
                    <LabelInput
                        bg={'white.100'}
                        label={'Email'}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        onChangeText={handleChange('email')}
                        placeholder="Email"
                    />

                    <CustomDatePicker
                        label={'Birth date'}
                        value={new Date(values.birthDate ?? ('2022-03-21' as any))}
                        onChange={dateChangeHandler}
                    />
                    <SelectInput
                        bg={'white.100'}
                        valueName={'gender'}
                        label={'Gender'}
                        value={values.gender}
                        error={errors.gender}
                        touched={touched.gender}
                        setFieldValue={setFieldValue}
                        placeholder="Gender"
                    />
                    {/*@ts-ignore*/}
                    <LabelInput
                        bg={'white.100'}
                        label={'Phone'}
                        onBlur={handleBlur('mobile')}
                        value={values.mobile}
                        error={errors.mobile}
                        touched={touched.mobile}
                        onChangeText={handleChange('mobile')}
                        placeholder="0400 000 000"
                    />
                </VStack>
            );
        } else {
            return (
                <VStack my={8} space={'8'} mb={18} alignSelf={'center'} w={'100%'}>
                    <LabelInput
                        bg={'white.100'}
                        label={'Name of organisation'}
                        onBlur={handleBlur('businessName')}
                        value={values.businessName}
                        error={errors.businessName}
                        touched={touched.businessName}
                        onChangeText={handleChange('businessName')}
                        placeholder="Name of organisation"
                    />
                    <Box
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        borderWidth={1}
                        borderRadius={100}
                        borderColor={'black.100'}
                        px={2}
                    >
                        <Box maxH={sizePix(53)} w={'100%'} pb={0} justifyContent={'center'} alignItems={'center'}>
                            <DefaultDown
                                noBorder={true}
                                value={values.businessType === '0' ? '' : values.businessType}
                                placeholder={'Choose your Business type'}
                                defaultButtonText={TransactionBusinessCase2(user?.businessType as any)}
                                data={['1', '2', '3']}
                                index={0}
                                switchAction={TransactionBusinessCase2}
                                bagValueHandler={typeBusinessHanlder}
                                style={{
                                    borderWidth: 0,
                                    alignSelf: 'center',
                                    mb: 1,
                                }}
                            />
                            <Box px={2} position={'absolute'} left={3} top={sizePix(-12)} bg={'white.100'}>
                                <FontPixel {...Ar18M} color={mainColor}>
                                    {'Business type'}
                                </FontPixel>
                            </Box>
                        </Box>
                    </Box>
                    {/*@ts-ignore*/}
                    <LabelInput
                        keyboardType="numeric"
                        bg={'white.100'}
                        label={'ABN'}
                        onBlur={handleBlur('abn')}
                        value={values.abn}
                        error={errors.abn}
                        touched={touched.abn}
                        onChangeText={handleChange('abn')}
                        placeholder="12 345 678 910"
                    />
                    <LabelInput
                        bg={'white.100'}
                        label={'First name'}
                        onBlur={handleBlur('firstName')}
                        value={values.firstName}
                        error={errors.firstName}
                        touched={touched.firstName}
                        onChangeText={handleChange('firstName')}
                        placeholder="First name"
                    />
                    <LabelInput
                        bg={'white.100'}
                        label={'Last name'}
                        onBlur={handleBlur('Lastname')}
                        value={values.LastName}
                        error={errors.LastName}
                        touched={touched.LastName}
                        onChangeText={handleChange('LastName')}
                        placeholder="Last name"
                    />
                    <LabelInput
                        bg={'white.100'}
                        label={'Email'}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        onChangeText={handleChange('email')}
                        placeholder="Email"
                    />
                    <CustomDatePicker
                        label={'Birth date'}
                        value={new Date(values.birthDate ?? ('2022-03-21' as any))}
                        onChange={dateChangeHandler}
                    />

                    <SelectInput
                        bg={'white.100'}
                        valueName={'gender'}
                        label={'Gender'}
                        value={values.gender}
                        error={errors.gender}
                        touched={touched.gender}
                        setFieldValue={setFieldValue}
                        placeholder="Gender"
                    />
                    {/*@ts-ignore*/}
                    <LabelInput
                        bg={'white.100'}
                        label={'Phone'}
                        onBlur={handleBlur('mobile')}
                        value={values.mobile}
                        error={errors.mobile}
                        touched={touched.mobile}
                        onChangeText={handleChange('mobile')}
                        placeholder="0400 000 000"
                    />
                </VStack>
            );
        }
    }, [user, values]);
    const ProfileList = useMemo(() => {
        if (user && user.userType === 1) {
            return (
                <VStack my={SizePixel(4)} space={'6'} mb={18} ml={'14px'} w={'90%'}>
                    <ProfileCard value={values.firstName} label={'First name'} />
                    <ProfileCard value={values.LastName} label={'Last name'} />
                    <ProfileCard value={values.email} label={'Email'} />
                    <ProfileCard
                        value={`${addressConvert([user?.userAddress, user?.userAddressMore, user?.userZip])}`}
                        label={'Pickup address'}
                        onPress={() => navigation('/addressInfo')}
                    />
                    <Box
                        pb={'30px'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        borderColor={'gray.100'}
                        borderBottomWidth={0.5}
                    >
                        <TouchableOpacity
                            hitSlop={{
                                top: 20,
                                left: 20,
                                right: 20,
                                bottom: 20,
                            }}
                            style={{ width: '100%' }}
                            onPress={() => navigation('/paymentScreen' as never)}
                        >
                            <Box w={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box>
                                    <Text {...Ar18M} color={mainColor}>
                                        Payment Methods
                                    </Text>
                                </Box>
                                <Box>
                                    <BackGray color={'#4C4C4C'} />
                                </Box>
                            </Box>
                        </TouchableOpacity>
                    </Box>
                    {user?.snsType === 1 && (
                        <Box
                            py={'10px'}
                            pb={'20px'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                            h={'100%'}
                            borderColor={'gray.100'}
                            borderBottomWidth={0.5}
                        >
                            <TouchableOpacity
                                hitSlop={{
                                    top: 20,
                                    left: 20,
                                    right: 20,
                                    bottom: 20,
                                }}
                                style={{ width: '100%', justifyContent: 'center' }}
                                onPress={() => navigation('/passwordNew' as never)}
                            >
                                <Box w={'90%'} h={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Text {...Ar18M} color={'blue.200'}>
                                            Reset Password
                                        </Text>
                                    </Box>
                                    <Box>
                                        <BackGray color={'#4C4C4C'} />
                                    </Box>
                                </Box>
                            </TouchableOpacity>
                        </Box>
                    )}

                    <ProfileCard value={values.birthDate} label={'Birthdate'} />
                    <ProfileCard value={values.gender === 1 ? 'Male' : values.gender === 2 ? 'Female' : 'Non-binary'} label={'Gender'} />
                    <ProfileCard value={values.mobile} label={'Mobile'} />
                </VStack>
            );
        } else {
            return (
                <VStack my={SizePixel(4)} space={'6'} mb={18} ml={'14px'} w={'90%'}>
                    <ProfileCard value={values.businessName} label={'Name of organisation'} />
                    <ProfileCard value={TransactionBusinessCase(values.businessType ?? '0')} label={'Business type'} />
                    <ProfileCard value={values.abn} label={'ABN'} />
                    <ProfileCard value={values.firstName} label={'First name'} />
                    <ProfileCard value={values.LastName} label={'Last name'} />
                    <ProfileCard value={values.email} label={'Email'} />
                    <ProfileCard
                        value={`${addressConvert([user?.userAddress, user?.userAddressMore, user?.userZip])}`}
                        label={'Pickup address'}
                        onPress={() => navigation('/addressInfo')}
                    />
                    <Box
                        pb={'30px'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        borderColor={'gray.100'}
                        borderBottomWidth={0.5}
                    >
                        <TouchableOpacity
                            hitSlop={{
                                top: 20,
                                left: 20,
                                right: 20,
                                bottom: 20,
                            }}
                            style={{ width: '100%' }}
                            onPress={() => navigation('/paymentScreen' as never)}
                        >
                            <Box w={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box>
                                    <Text {...Ar18M} color={mainColor}>
                                        Payment Methods
                                    </Text>
                                </Box>
                                <Box>
                                    <BackGray color={'#4C4C4C'} />
                                </Box>
                            </Box>
                        </TouchableOpacity>
                    </Box>
                    {user?.snsType === 1 && (
                        <Box
                            pb={'30px'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                            borderColor={'gray.100'}
                            borderBottomWidth={0.5}
                        >
                            <TouchableOpacity
                                hitSlop={{
                                    top: 20,
                                    left: 20,
                                    right: 20,
                                    bottom: 20,
                                }}
                                style={{ width: '100%' }}
                                onPress={() => navigation('/passwordNew' as never)}
                            >
                                <Box w={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Text {...Ar18M} color={mainColor}>
                                            Reset Password
                                        </Text>
                                    </Box>
                                    <Box>
                                        <BackGray color={'#4C4C4C'} />
                                    </Box>
                                </Box>
                            </TouchableOpacity>
                        </Box>
                    )}
                    <ProfileCard value={values.birthDate} label={'Birthdate'} />
                    <ProfileCard value={values.gender === 1 ? 'Male' : values.gender === 2 ? 'Female' : 'Non-binary'} label={'Gender'} />
                    <ProfileCard value={values.mobile} label={'Mobile'} />
                </VStack>
            );
        }
    }, [user, values]);
    return (
        <Box bg={'white.100'} w={'100%'} alignItems={'center'}>
            {check ? (
                <Box safeAreaTop mt={4} px={'18px'} bg={'white.100'} w={'100%'}>
                    <HStack alignItems={'center'} justifyContent={'space-between'}>
                        <TouchableOpacity onPress={() => navigation(-1)} hitSlop={{ top: 30, left: 30, right: 30, bottom: 30 }}>
                            <BackIcon />
                        </TouchableOpacity>
                        {!edit ? (
                            <TouchableOpacity onPress={() => setEdit((p) => !p)}>
                                <Text {...Ar18M} color={mainColor}>
                                    Edit
                                </Text>
                            </TouchableOpacity>
                        ) : (
                            <TouchableOpacity onPress={() => setEdit((p) => !p)}>
                                <Text {...Ar18M} color={mainColor}>
                                    Back
                                </Text>
                            </TouchableOpacity>
                        )}
                    </HStack>
                </Box>
            ) : (
                <Box safeAreaTop mt={4} px={'18px'} bg={'white.100'} alignItems={'flex-end'} justifyContent={'flex-end'} w={'100%'}>
                    <HStack alignItems={'center'} justifyContent={'space-between'}>
                        {!edit && (
                            <TouchableOpacity onPress={() => setEdit((p) => !p)} style={{ marginRight: 10 }}>
                                <Text {...Ar18M} color={mainColor}>
                                    Edit
                                </Text>
                            </TouchableOpacity>
                        )}
                        <TouchableOpacity onPress={() => logout()}>
                            <Text {...Ar18M} color={mainColor}>
                                Cancel
                            </Text>
                        </TouchableOpacity>
                    </HStack>
                </Box>
            )}

            <Box {...PageSetting} w={'100%'} px={'24px'} flexGrow={1} safeAreaBottom justifyContent={'space-between'}>
                <ScrollView w={'100%'} showsVerticalScrollIndicator={false}>
                    <Box alignItems={'flex-start'} w={'100%'}>
                        {edit && <Heading {...Ar36B}>Profile</Heading>}
                        {edit ? ProfileListInput : ProfileList}
                    </Box>
                    {edit && (
                        <Flex mb={10}>
                            <Box justifyContent={'center'} alignItems={'center'}>
                                <Button onPress={() => handleSubmit()} variant={'dButton'} bg={mainColor}>
                                    <FontPixel fontFamily={'Arch'} fontWeight={100} fontSize={SizePixel(22)} color={'white.100'}>
                                        {'Save'}
                                    </FontPixel>
                                </Button>
                            </Box>
                        </Flex>
                    )}
                    <Box mb={200} />
                </ScrollView>
            </Box>
        </Box>
    );
};

export default UserProfile;
