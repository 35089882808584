import React, { useState } from 'react';
import { Text, Box, VStack, Divider, Button, Image } from 'native-base';
import { ImageBackground, Linking, TouchableOpacity, Image as RNImage } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { logoutAction, profileSetting } from '../../store/authReducer';
import { Ar14Sb, Ar18M, Ar18SbBlack, Ar21B, Ar21N, Ar21R, Ar21Sb, Ar22M, La23N, La31B } from '../../themes/font.style';
import ModalWrapper from '../commons/modal/modal.wrapper';
import useAxiosServices from '../../hooks/axiosHooks';
import WebSheet from '../../components/bottomSheet/web.sheet';
import { useNavigate } from 'react-router-dom';
import Drawer from 'rc-drawer';
import { Img } from 'react-image';

import BackGround from '../../assets/background/Sidebar_background.png';
import BackGround2 from '../../assets/background/business-background.png';
import businessIcon from '../../assets/icons/building.png';
import personalIcon from '../../assets/icons/feedback.png';
import { useColors } from '../../hooks/useColors';
import { UserIdHelpers } from '../../utils/helper/user.helper';
import { FontPixel } from '../../components/typographys/font.pixel';
import useAuth from '../../hooks/useAuth';
const SideBarUser = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
    const { logout } = useAuth();
    const user = useSelector((state: RootState) => state.auth.user);
    const backgroundSource = user?.userType === 1 ? BackGround : BackGround2;
    const { mainColor } = useColors();
    const navigation = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const goToHandler = (name: string) => {
        navigation(name as any);
        setOpen(false);
    };
    const [webOpen, setWepOpen] = useState(false);

    const logOut = async () => {
        dispatch(logoutAction());
        await AsyncStorage.multiRemove(['accessToken', 'refreshToken']);
    };
    const openLink = async () => {
        setWepOpen(true);
        setOpen(false);
    };
    const [openInfo, setOpenInfo] = useState(false);

    const openCollection = () => {
        if (user?.profileCheck === 1 && user?.addressCheck === 1) {
            navigation('/mainCollection' as never);
        } else {
            setOpenInfo(true);
        }
    };

    const [changeOpen, setChangeOpen] = useState(false);
    const onChangeType = async () => {
        const api = await axiosService.post('/users/app/account/change');
        const { data, status } = api.data;
        if (status) {
            dispatch(profileSetting({ user: data, userRole: data.userRole }));
        }
        setChangeOpen(false);
    };
    const accountId = () => {
        if (user?.userType === 1) {
            return `AC-0${user?.idx}`;
        } else {
            // @ts-ignore
            return `AC-${9999 - user?.idx ?? 0}`;
        }
    };
    const renderNameChange = () => {
        if (!user) {
            return '';
        }
        const name = user.userType === 1 ? user.userFirstName : user.businessName;
        return `Hi ${name}!`;
    };
    return (
        <>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                handler={false}
                level={null}
                afterVisibleChange={(c: boolean) => {
                    console.log('transitionEnd: ', c);
                }}
                width="321px"
            >
                <Box style={{ flex: 1 }}>
                    {/*<Image w={320} h={500} source={BackGround} position={'absolute'} zIndex={1} />*/}
                    <Img
                        src={backgroundSource}
                        style={{ objectFit: 'cover', width: '324px', height: 500, position: 'absolute', zIndex: 1 }}
                    />
                    <Box zIndex={2}>
                        <Box py={8} pb={'20px'} pt={'70px'} px={'26px'}>
                            <Box>
                                <FontPixel {...La31B} color={'white.100'}>
                                    {renderNameChange()}
                                </FontPixel>
                                <FontPixel {...La23N} color={'gray.100'} opacity={0.8}>
                                    {UserIdHelpers(user)}
                                </FontPixel>
                                <Button
                                    variant={'basicButton'}
                                    borderRadius={'38px'}
                                    mt={'40px'}
                                    bg={'white.100'}
                                    onPress={() => openCollection()}
                                >
                                    <FontPixel {...Ar22M} color={'gray.300'}>
                                        Book a Collection
                                    </FontPixel>
                                </Button>
                            </Box>
                        </Box>
                        <Box bg={'white.100'}>
                            <Box>
                                <Box px={'30px'} pt={'16px'} pb={'16px'} justifyContent={'center'}>
                                    <TouchableOpacity onPress={() => goToHandler('/collectionList')}>
                                        <FontPixel {...Ar21B} color={'gray.300'}>
                                            Collections
                                        </FontPixel>
                                    </TouchableOpacity>
                                </Box>
                                <Divider />
                                <VStack px={'30px'} space={'16px'} py={'10px'} pb={'20px'}>
                                    <TouchableOpacity onPress={() => goToHandler('/profile')}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Account
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => goToHandler('/redeemMain')}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Redeem
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => goToHandler('/transaction')}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Transactions
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => navigation('/addressInfo' as any)}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Pickup address
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => navigation('FeedPage' as any)}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Feedback
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => navigation('/shareScreen' as any)}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Share RECAN
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={() => openLink()}>
                                        <Box justifyContent={'center'}>
                                            <FontPixel {...Ar21R} color={'gray.300'}>
                                                Contact us
                                            </FontPixel>
                                        </Box>
                                    </TouchableOpacity>
                                </VStack>

                                <Divider />
                                {/*<TouchableOpacity onPress={() => goToHandler('DrivingList')}>*/}
                                {/*    <Box px={5} py={4} pb={2} justifyContent={'center'}>*/}
                                {/*        <Text fontWeight={100} fontSize={'18px'} fontFamily={'Arch'} color={'black.100'}>*/}
                                {/*            Business account*/}
                                {/*        </Text>*/}
                                {/*    </Box>*/}
                                {/*</TouchableOpacity>*/}
                                <TouchableOpacity
                                    onPress={() => {
                                        setOpen(false);
                                        setChangeOpen(true);
                                    }}
                                >
                                    <Box px={'30px'} py={6} pb={2} justifyContent={'center'}>
                                        <FontPixel {...Ar18SbBlack} color={'gray.300'}>
                                            {user?.userType !== 1 ? ' Switch to personal' : 'Switch to business'}
                                        </FontPixel>
                                    </Box>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => logOut()}>
                                    <Box px={'30px'} py={4} pb={2} justifyContent={'center'}>
                                        <FontPixel {...Ar18SbBlack} color={'gray.300'}>
                                            Sign out
                                        </FontPixel>
                                    </Box>
                                </TouchableOpacity>
                                <Box px={'30px'} py={1} pb={4} justifyContent={'center'}>
                                    <Text {...Ar14Sb} color={'gray.200'}>
                                        v1.0
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
            <ModalWrapper
                open={changeOpen}
                image={user?.userType === 2 ? personalIcon : businessIcon}
                onClose={() => setChangeOpen(false)}
                onHandler={[
                    { text: 'No', onPress: () => setChangeOpen(false), color: 'gray.200' },
                    { text: 'Change', onPress: onChangeType, color: mainColor },
                ]}
                title={'Account switch'}
                content={
                    user?.userType === 1 ? 'Do you want to switch to a business account?' : 'Do you want to switch to a personal account?'
                }
            />
            <WebSheet open={webOpen} setOpen={setWepOpen} url={'https://www.recan.co/work-with-us'} />
        </>
    );
};

export default SideBarUser;
