import React, { useState } from 'react';

import ArrowDown from '../../assets/icons/arrow.down';
import ArrowUp from '../../assets/icons/arrow.up';
import { Box, CheckIcon, Pressable, Select, Text, VStack } from 'native-base';
import { Ar18M, Ar18SbBlack } from '../../themes/font.style';
import { TransactionBusinessCase2 } from '../../etc/bookStatusCase';
import useWindowDimensions from '../../hooks/useDimensions';
import { useColors } from '../../hooks/useColors';
import { SizePixel } from '../../utils/pixcel.convert';

interface Props {
    data: any[];
    value?: any;
    index: number;
    bagValueHandler: (index: any, type: any) => void;
    switchAction: (type: any) => string;
    defaultValueByIndex?: number;
    defaultButtonText: string;
    style?: any;
    placeholder: string;
    noBorder?: boolean;
}

const DefaultDown = ({
    data,
    value,
    bagValueHandler,
    index,
    switchAction,
    defaultValueByIndex,
    defaultButtonText,
    style,
    placeholder,
    noBorder,
}: Props) => {
    console.log('defaultButtonText', value);

    const [open, setOpen] = useState(false);
    const [defaultText, setDefaultText] = useState(defaultButtonText ?? '');
    const [selectText, setSelectText] = useState('');
    const { sizePix } = useWindowDimensions();
    const { mainColor } = useColors();
    return (
        <Box flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Box w={'100%'}>
                <VStack alignItems="flex-start" space={4}>
                    <Select
                        {...Ar18SbBlack}
                        fontSize={sizePix(18)}
                        dropdownCloseIcon={
                            <Box mr={'15px'}>
                                <ArrowDown />
                            </Box>
                        }
                        dropdownOpenIcon={
                            <Box mr={'15px'}>
                                <ArrowUp />
                            </Box>
                        }
                        selectedValue={String(value)}
                        minWidth="200"
                        accessibilityLabel={placeholder}
                        placeholder={placeholder}
                        borderWidth={noBorder ? 0 : 1}
                        borderRadius={'24px'}
                        borderColor={'#222'}
                        width={'100%'}
                        height={SizePixel(50)}
                        px={4}
                        _selectedItem={{
                            bg: 'gray.100',
                            endIcon: <CheckIcon size="5" color={'#1C6EBA'} />,
                        }}
                        mt={1}
                        defaultValue={value}
                        onValueChange={(itemValue) => bagValueHandler(index ?? 1, itemValue)}
                    >
                        {data.map((v) => (
                            <Select.Item label={switchAction(v)} value={String(v)} />
                        ))}
                    </Select>
                </VStack>
            </Box>
        </Box>
    );
};

export default DefaultDown;
