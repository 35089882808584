import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Center,
    Input,
    Stack,
    IconButton,
    Button,
    HStack,
    Divider,
    ScrollView,
    Image,
    Heading,
    useToast,
    Pressable,
} from 'native-base';
import PasswordViewIcon from '../../assets/icons/passwordView.icon';

import GoogleIcon from '../../assets/icons/google.icon';
import FaceBookIcon from '../../assets/icons/facebook.icon';
import AppleIcon from '../../assets/icons/apple.icon';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from '../../components/customInput/defaultInput';
import ErrorHelp from '../../components/customInput/error.help';
import DefaultHeader from '../header/header.default';
import SelectAccountSheet from '../../components/bottomSheet/selectAccount.sheet';
import useAxiosServices from '../../hooks/axiosHooks';
import { Ar17B, Ar17M, Ar17R, Ar21B, Ar36B } from '../../themes/font.style';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { loginAction, profileSetting } from '../../store/authReducer';
import jwtDecode from 'jwt-decode';
import { RootState } from '../../store';
import PaypalSheet from '../../components/bottomSheet/paypal.sheet';
import { loadingEndAction } from '../../store/commonReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import JoinSelect from '../../components/bottomSheet/join.select';
import PaypalLogin from '../../components/bottomSheet/paypal.sheet';
import { Img } from 'react-image';

const SingInSchema = Yup.object().shape({
    accountType: Yup.string().required('Please select account Type'),
    email: Yup.string().email('Please enter a valid email').min(4, 'Password Too short').required('Please enter your email'),
    password: Yup.string()
        .min(8, 'This password is too short. It must contain at least 8 characters.')
        .required('Please enter your password'),
    passwordCheck: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match"),
});

const passwordView = require('../../assets/icons/password.png');
import paypalIcon from '../../assets/icons/paypal.png';
import { MAXWIDTH } from '../../themes';
import { FontPixel } from '../../components/typographys/font.pixel';
import useWindowDimensions from '../../hooks/useDimensions';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import { LongButton } from '../../components/buttons/long.button';
import { PageSetting } from '../../utils/pageSetting';
const LoginSingUp = () => {
    const navigate = useNavigate();
    const { appToken } = useSelector((state: RootState) => state.auth);
    const { axiosService } = useAxiosServices();
    const { handleChange, errors, touched, values, setValues, setFieldValue, handleSubmit } = useFormik({
        validationSchema: SingInSchema,
        initialValues: {
            accountType: '',
            email: '',
            password: '',
            passwordCheck: '',
        },
        onSubmit: async (value) => {
            submit(value);
        },
    });
    const navigation = useNavigate();
    const [sheetOpen, setSheetOpen] = useState(false);
    const inputHandler = async (type: 1 | 2) => {
        await setFieldValue('accountType', type === 1 ? 'Personal' : 'Company');
        setSheetOpen(false);
    };
    const goToEmailValidateScreen = (code: number, email: string, password: string) => {
        // @ts-ignore
        navigation('/emailValidate', { state: { email, password, code, accountType: values.accountType } });
    };
    const submit = async (value: any) => {
        try {
            const { email, passwordCheck, password } = value;
            const api = await axiosService.post('users/check/email', { email, passwordCheck, password });
            const { status, data } = api.data;
            if (!status) {
                toast(data);
            } else {
                goToEmailValidateScreen(data, email, password);
            }
        } catch (e) {
            toast('Error.');
        }
    };
    const [view, setView] = useState(false);
    const [view2, setView2] = useState(false);

    useEffect(() => {}, []);

    const dispatch = useDispatch();

    const loginHandler = async (value: { id: string; email: string; password: string }, type) => {
        const { email, password, id } = value;
        try {
            const api = await axiosService.post('/users/login/sns', {
                username: id,
                password,
            });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', { appToken });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigate('/main', { replace: true });
            } else {
                toast('Error');
            }
        } catch (e) {
            setSelectOpen(true);
            setTempEmail(email);
            setTempId(id);
            setTempType(type);
        }
    };

    const snsLoginWithGoogle = async (r: any) => {
        try {
            const { googleId, profileObj } = r;
            const { email, name } = profileObj;
            if (googleId) {
                try {
                    console.log(googleId);
                    await loginHandler({ id: googleId, email, password: googleId } as any, 2);
                } catch (e) {}
            }
            // await GoogleSignin.hasPlayServices();
            // const userInfo = await GoogleSignin.signIn();
            // const {id, email} = userInfo.user;
            // await loginHandler({id, email, password: id}, 2);
        } catch (error) {
            console.log(error);
            // if (error.code === statusCodes.SIGN_IN_CANCELLED) {
            //   // user cancelled the login flow
            // } else if (error.code === statusCodes.IN_PROGRESS) {
            //   // operation (e.g. sign in) is in progress already
            // } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
            //   // play services not available or outdated
            // } else {
            //   // some other error happened
            // }
        }
    };
    const join = async (id: any, email, type, accountType) => {
        try {
            const api = await axiosService.post('/users/join/simple/sns', {
                email: email,
                password: id,
                id: id,
                type,
                accountType,
            });

            const { status, data } = api.data;
            console.log('DATA, ', data);
            if (status) {
                await loginJoinHandler({ email: id, password: id });
            } else {
                toast(data);
            }
        } catch (e) {
        } finally {
            setSelectOpen(false);
        }
    };

    const snsLoginWithApple = async (r) => {
        console.log('CODE', r);
        if (r && r.hasOwnProperty('authorization')) {
            const { authorization } = r;
            const { id_token, code } = authorization;
            const decodedToken: any = jwtDecode(id_token);
            const id = decodedToken.sub;
            const mail = decodedToken.email ?? '';
            console.log(id, mail);
            await loginHandler({ id: id, email: mail, password: id }, 2);
        }
    };
    const [tempId, setTempId] = useState('');
    const [tempEmail, setTempEmail] = useState('');
    const [tempType, setTempType] = useState('1');
    const [selectOpen, setSelectOpen] = useState(false);
    const openAndJoin = async (accountType) => {
        const confrim = selectOpen;
        if (confrim) {
            dispatch(loadingEndAction());
            setSelectOpen(false);
            await join(tempId, tempEmail, tempType, accountType);
        }
    };

    const loginJoinHandler = async (value: { email: string; password: string }) => {
        const { email, password } = value;
        try {
            const api = await axiosService.post('/users/login/sns', {
                username: email,
                password,
            });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', {
                appToken,
            });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigate('/main', { replace: true });
            } else {
                toast('Error');
            }
        } catch (e) {
            console.log(e);
            toast('Not a registered member. Please sign up and proceed.');
        }
    };

    const [paypalOpen, setPaypalOpen] = useState(false);
    const responseGoogle = (res) => {
        console.log(res);
    };
    const { sizePix: SizePixel, height } = useWindowDimensions();

    return (
        <>
            <Box bg={'white.100'} alignItems={'center'} width={'100vw'} h={height}>
                <DefaultHeader navigation={navigation} bg={'white.100'} />
                <Box {...PageSetting} flex={1} px={SizePixel(29)} width={'100%'} flexGrow={1} justifyContent={'space-between'}>
                    <Box>
                        <Box mb={SizePixel(81)} mt={SizePixel(28)} alignItems={'center'}>
                            <FontPixel {...Ar36B} color={'black.100'}>
                                Sign up
                            </FontPixel>
                        </Box>
                        <Box>
                            <Box width={'100%'}>
                                <ErrorHelp errors={errors} />
                                <Pressable onPress={() => setSheetOpen(true)}>
                                    <CustomInput
                                        isDisabled={true}
                                        value={values.accountType}
                                        error={errors.accountType}
                                        touched={touched.accountType}
                                        onChangeText={handleChange('accountType')}
                                        placeholder="Account Type"
                                    />
                                </Pressable>
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChangeText={handleChange('email')}
                                    placeholder="Email"
                                />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                    onChangeText={handleChange('password')}
                                    type={view ? 'text' : 'password'}
                                    InputRightElement={
                                        <IconButton
                                            onPress={() => setView((p) => !p)}
                                            _pressed={{ bg: 'transfer', opacity: 0.5 }}
                                            icon={<PasswordViewIcon color={'#ACACAC'} boolean={view} />}
                                        />
                                    }
                                    placeholder="Password"
                                />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.passwordCheck}
                                    error={errors.passwordCheck}
                                    touched={touched.passwordCheck}
                                    onChangeText={handleChange('passwordCheck')}
                                    type={view2 ? 'text' : 'password'}
                                    InputRightElement={
                                        <IconButton
                                            onPress={() => setView2((p) => !p)}
                                            _pressed={{ bg: 'transfer', opacity: 0.5 }}
                                            icon={<PasswordViewIcon color={'#ACACAC'} boolean={view2} />}
                                        />
                                    }
                                    placeholder="Confirm password"
                                />
                            </Box>
                        </Box>
                        <Box mt={SizePixel(74)}>
                            <Box width={'100%'}>
                                <LongButton my={2} colorScheme={'blue.200'} onPress={() => handleSubmit()} variant={'basicButton'}>
                                    <HStack alignItems={'center'}>
                                        <Box alignItems={'center'} width={'100%'}>
                                            <FontPixel color={'white.100'} fontFamily={'Arch'} fontWeight={'700'} fontSize={21}>
                                                Sign up
                                            </FontPixel>
                                        </Box>
                                    </HStack>
                                </LongButton>
                                <Box
                                    mt={SizePixel(20)}
                                    mb={SizePixel(22)}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    width={'100%'}
                                >
                                    <Divider bg={'black.100'} mx="1" orientation={'horizontal'} width={'30%'} borderWidth={0.5} />
                                    <Box>
                                        <FontPixel fontFamily={'Arch'} fontWeight={400} fontSize={15}>
                                            Or Sign up with
                                        </FontPixel>
                                    </Box>
                                    <Divider bg={'black.100'} mx="1" orientation={'horizontal'} width={'30%'} borderWidth={0.5} />
                                </Box>
                                <Stack
                                    space={4}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                >
                                    <GoogleLogin
                                        clientId="1053774717511-r8ojjb66ujb9nfaomi825suv37vkgi99.apps.googleusercontent.com"
                                        onSuccess={snsLoginWithGoogle}
                                        onFailure={responseGoogle}
                                        onScriptLoadFailure={(error) => console.log('ERROR', error)}
                                        uxMode={'popup'}
                                        render={(r) => (
                                            <Button
                                                w={'30%'}
                                                my={2}
                                                colorScheme={'blue.200'}
                                                onPress={() => r.onClick()}
                                                variant={'basicButton'}
                                            >
                                                <HStack alignItems={'center'}>
                                                    <GoogleIcon />
                                                </HStack>
                                            </Button>
                                        )}
                                    />
                                    <PaypalLogin
                                        render={(r) => (
                                            <Button
                                                w={'30%'}
                                                my={2}
                                                colorScheme={'blue.300'}
                                                onPress={() => r.open()}
                                                variant={'basicButton'}
                                            >
                                                <HStack alignItems={'center'}>
                                                    <Img
                                                        style={{ objectFit: 'contain', marginTop: 1 }}
                                                        height={'30px'}
                                                        width={'30px'}
                                                        src={paypalIcon}
                                                        alt={'paypalIcon'}
                                                    />
                                                </HStack>
                                            </Button>
                                        )}
                                    />

                                    <AppleLogin
                                        clientId="com.recan.web"
                                        redirectURI="https://recan-app.mirable.cc/signUp"
                                        scope="email"
                                        usePopup={true}
                                        callback={(r) => {
                                            snsLoginWithApple(r);
                                        }}
                                        render={(r) => (
                                            <Button
                                                w={'30%'}
                                                my={2}
                                                colorScheme={'black.100'}
                                                onPress={() => r.onClick()}
                                                variant={'basicButton'}
                                            >
                                                <HStack mb={1} alignItems={'center'}>
                                                    <AppleIcon />
                                                </HStack>
                                            </Button>
                                        )}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box justifyContent={'flex-end'}>
                        <Box justifyContent={'center'} alignContent={'center'} flexDirection={'row'}>
                            <FontPixel {...Ar17R}>Have an account?</FontPixel>
                            <Pressable onPress={() => navigation('/signIn')}>
                                <FontPixel {...Ar17B} ml={1}>
                                    Sign in
                                </FontPixel>
                            </Pressable>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <JoinSelect open={selectOpen} onHandler={setSelectOpen} inputHandler={openAndJoin} value={tempType} />
            <SelectAccountSheet value={values.accountType} open={sheetOpen} onHandler={setSheetOpen} inputHandler={inputHandler} />
        </>
    );
};

export default LoginSingUp;
