import React, { forwardRef } from 'react';
import { Box, Input, IInputProps, Text, FormControl, VStack, useTheme } from 'native-base';
import ErrorHelp from './error.help';
import ErrorIcon from '../../assets/icons/error.icon';
import { Ar18M, Ar18SbBlack } from '../../themes/font.style';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useWindowDimensions from '../../hooks/useDimensions';
import { FontPixel } from '../typographys/font.pixel';
import { SizePixel } from '../../utils/pixcel.convert';
import { useColors } from '../../hooks/useColors';

interface Props extends IInputProps {
    bg?: string;
    touched?: boolean | any;
    error?: string | any;
    value: any;
    label: string;
    color?: string;
    inter?: any;
    onChange: (value) => void;
}

const CustomDatePicker = forwardRef<typeof Input, Props>(({ bg, inter, color, label, value, onChange, touched, error, ...props }, ref) => {
    const theme = useTheme();
    const { sizePix } = useWindowDimensions();
    const { mainColor } = useColors();
    return (
        <>
            <Box
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                borderWidth={1}
                borderRadius={100}
                borderColor={error && touched ? 'red.100' : 'black.100'}
                px={2}
            >
                <Box w={'100%'} p={1} maxH={sizePix(53)}>
                    <Box px={2} position={'absolute'} left={3} top={sizePix(-12)} bg={bg ?? 'white.100'}>
                        <FontPixel {...Ar18M} color={color ?? mainColor}>
                            {label}
                        </FontPixel>
                    </Box>
                    <Box>
                        <DatePicker
                            dateFormat="dd MMM yyyy"
                            portalId="root-portal"
                            selected={value ?? new Date()}
                            onChange={(v) => onChange(v)}
                            customInput={
                                <Input
                                    {...Ar18SbBlack}
                                    fontSize={sizePix(18)}
                                    ml={'10px'}
                                    backgroundColor={'#00ff0000'}
                                    value={value}
                                    borderWidth={0}
                                    isFullWidth
                                    width={'100%'}
                                    height={sizePix(50)}
                                    size="lg"
                                    {...{ ref }}
                                    placeholder={''}
                                />
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
});

export default CustomDatePicker;
