import { userTypes } from '../../@types/userTypes';

export const UserIdHelpers = (user: userTypes | undefined) => {
    const userIdx = user?.idx;
    const userType = user?.userType === 1 ? 'P' : 'B';
    const convert = userIdx ?? 0 > 99 ? String(userIdx) : `0${String(userIdx)}`;
    return `${userType}-${convert}`;
};
export const UserCreditRender = (user: undefined | userTypes) => {
    if (!user) {
        return 0;
    }
    return user.userType === 1 ? user?.credit ?? 0 : user?.credit2 ?? 0;
};

export const UserTypePersonal = (user: undefined | userTypes) => {
    if (!user) {
        return true;
    }
    return user.userType === 1;
};
