import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING_V2 } from './index';
import { Box, Text } from 'native-base';
import { Ar36B } from '../../themes/font.style';
import CalenderInput from '../../components/customInput/calender.input';
import TimeInput from '../../components/customInput/time.input';
import { useUser } from '../../hooks/use.user';
interface Props {
    date?: any;
    weekNo: string[];
    dateNo: string[];
    pickDate: string | null;
    calenderHandler: (v: string) => void;
    setPickTime: Dispatch<SetStateAction<string | null>>;
    pickTime: string | null;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
}
// const [pickDate, setPickDate] = useState<string | null>(null);

export const DateCollectionScreen = ({ setPageType, date, calenderHandler, weekNo, dateNo, pickDate, pickTime, setPickTime }: Props) => {
    useEffect(() => {
        setPageType('Date');
    }, []);
    const { userType } = useUser();
    return (
        <Box px={SizePixel(MAIN_COLLECTION_PADDING_V2)} mt={SizePixel(64.37)} flex={1}>
            <Box mb={SizePixel(41)}>
                <Text {...Ar36B} color={'black.100'}>
                    Pick a Date
                </Text>
            </Box>
            <Box>
                <CalenderInput dateNo={dateNo} weekNo={weekNo} date={pickDate} calnderHandler={calenderHandler} />
            </Box>
            {userType === 2 && (
                <Box mt={SizePixel(18)}>
                    <TimeInput time={pickTime} timeHandler={setPickTime} />
                </Box>
            )}
        </Box>
    );
};
