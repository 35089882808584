import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addressListType } from '../@types/userTypes';
export interface IAdjustRouteMapProps {
    lot?: number;
    lat?: number;
    address?: string;
    addressMore?: string;
    postcode?: string;
    route?: string;
    type?: 'user' | 'noUser';
    idx?: number;
}
export interface CommonProps {
    isLoading: boolean;
    addressTemp: IAdjustRouteMapProps;
    collectionStep: number;
}

export const initialState: CommonProps = {
    isLoading: false,
    collectionStep: 1,
    addressTemp: {
        lot: 0,
        lat: 0,
        address: '',
        addressMore: '',
        postcode: '',
        route: '',
        type: 'user',
        idx: 0,
    },
};
export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState,
    reducers: {
        loadingAction: (state) => {
            state.isLoading = !state.isLoading;
        },
        loadingEndAction: (state) => {
            state.isLoading = false;
        },
        addressAdd: (state, action) => {
            const { addressTemp } = action.payload;
            state.addressTemp = { ...addressTemp } as any;
        },
        collectionStepAction: (state, action) => {
            const { step } = action.payload;
            state.collectionStep = step;
        },
        collectionStepInitAction: (state) => {
            state.collectionStep = 1;
        },
    },
});

export const { loadingAction, loadingEndAction, addressAdd, collectionStepAction, collectionStepInitAction } = commonSlice.actions;

export default commonSlice.reducer;
