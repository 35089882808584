import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Box, Button, Text, ScrollView, Input } from 'native-base';
import { KeyboardAvoidingView, TextInput, Platform, StyleSheet, InputAccessoryView } from 'react-native';
import { ScreenHeight, SizePixel } from '../../utils/pixcel.convert';
import { useColors } from '../../hooks/useColors';
import { Ar22M } from '../../themes/font.style';
import { toast } from 'react-toastify';
import useAxiosServices from '../../hooks/axiosHooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
interface IProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
}
export const StripeForm = ({ setLoading }: IProps) => {
    const stripe = useStripe();
    const { mainColor } = useColors();
    const { axiosService } = useAxiosServices();
    const navigation = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth);
    const elements = useElements();
    const pay = async () => {
        if (elements === null) {
            return;
        }
        const cardElement = elements.getElement('card');
        if (cardElement === null) {
            return;
        }
        const payload = await stripe?.createToken(cardElement);
        console.log(payload);
        if (payload?.error) {
            // Handle error
            toast(payload.error?.message);
        } else if (payload?.token) {
            console.log('paymentMethodId', payload?.token);
            await saveAPI(payload?.token);
            // Send the ID of the PaymentMethod to your server for the next step
            // ...
        }
    };
    const saveAPI = async (params) => {
        const { id, card } = params;
        const data = { cardToken: id, card, email: user?.userId };
        const api = await axiosService.post('/payment/cardSave', data);
        console.log('RESULT', api.data);
        const { result } = api.data;
        if (result) {
            console.log('RESULT', result);
            navigation(-1);
        }
    };

    const temp = async () => {
        setLoading(true);
    };
    // const [cardNumber, setCardNumber] = useState<undefined | string>(undefined);
    // const saveCardNumber = (detail: CardFieldInput.Details) => {
    //     console.log(detail);
    //     if (detail.complete) {
    //         setCardNumber(detail.number);
    //     }
    // };
    return (
        <Box w={'100%'} h={'100%'} flex={1} mt={'100px'}>
            <ScrollView style={{ paddingHorizontal: SizePixel(32) }}>
                {/*<CardField*/}
                {/*    postalCodeEnabled={false}*/}
                {/*    placeholders={{*/}
                {/*        number: '0000 0000 0000 0000',*/}
                {/*    }}*/}
                {/*    cardStyle={{*/}
                {/*        backgroundColor: '#FFFFFF',*/}
                {/*        textColor: '#000000',*/}
                {/*    }}*/}
                {/*    style={{*/}
                {/*        width: '100%',*/}
                {/*        height: 50,*/}
                {/*        marginVertical: 30,*/}
                {/*    }}*/}
                {/*    onCardChange={(cardDetails) => {*/}
                {/*        console.log('cardDetails', cardDetails);*/}
                {/*    }}*/}
                {/*    onFocus={(focusedField) => {*/}
                {/*        console.log('focusField', focusedField);*/}
                {/*    }}*/}
                {/*/>*/}
                <CardElement />
            </ScrollView>
            <ScrollView>
                <Button
                    borderRadius={0}
                    bg={mainColor}
                    h={SizePixel(60)}
                    alignItems={'center'}
                    justifyContent={'center'}
                    onPress={() => pay()}
                >
                    <Text {...Ar22M} color={'white.100'} lineHeight={SizePixel(24.44)}>
                        Save
                    </Text>
                </Button>
            </ScrollView>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
    },
    input: {
        marginBottom: 20,
        borderBottomWidth: 2,
        borderColor: '#dbdbdb',
        padding: 10,
    },
});
