import React, { useState } from 'react';
import { IProductsTypes } from './temp.const';
import { Box, Pressable, Text } from 'native-base';
import { Image } from 'react-native';
import { SizePixel } from '../../utils/pixcel.convert';
import { Ar15B, Ar18SbBlack, La17B, La17N, La20B } from '../../themes/font.style';
import { ProductMinusAndPlus } from './plain.icon';
import { BottomView } from './shop.sheet';
import useWindowDimensions from '../../hooks/useDimensions';
interface IProps {
    products: IProductsTypes[number];
    index: number;
    updateProduct: (type: 'up' | 'down' | 'step', index, value?: number) => void;
    removeProduct: (index) => void;
    handleOpenSheet: (index: number) => void;
    modify: boolean;
}
export const ProductsBox = ({ modify, products, updateProduct, removeProduct, index, handleOpenSheet }: IProps) => {
    const { sizePix } = useWindowDimensions();
    return (
        <>
            <Box
                flexDirection={'row'}
                pl={SizePixel(13)}
                py={SizePixel(20)}
                borderRadius={SizePixel(15)}
                borderColor={'gray.100'}
                mb={SizePixel(16)}
                borderWidth={SizePixel(2)}
            >
                <Pressable
                    onPress={() => handleOpenSheet(index)}
                    width={SizePixel(104)}
                    height={SizePixel(104)}
                    borderRadius={10}
                    overflow={'hidden'}
                >
                    <Image style={{ width: '100%', height: '100%' }} resizeMode={'cover'} source={{ uri: products.image }} />
                </Pressable>
                <Box pl={SizePixel(24)} w={SizePixel(228)} h={SizePixel(104)} pr={SizePixel(4)}>
                    <Box w={SizePixel(200)}>
                        <Box h={SizePixel(72)} mb={SizePixel(4)}>
                            <Text {...Ar18SbBlack} color={'black.100'}>
                                {products.title}
                            </Text>
                            <Text {...La20B} color={'gray.200'}>
                                ${products.amount}
                            </Text>
                        </Box>

                        <Box flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Pressable display={modify ? 'none' : 'flex'} disabled={modify} onPress={() => removeProduct(index)}>
                                <Text {...Ar15B} color={'gray.200'} mr={SizePixel(10)}>
                                    Remove
                                </Text>
                            </Pressable>

                            <Pressable
                                display={modify ? 'none' : 'flex'}
                                disabled={modify}
                                onLongPress={() => removeProduct(index)}
                                onPress={() => updateProduct('down', index)}
                                alignItems={'center'}
                                justifyContent={'center'}
                                w={SizePixel(28)}
                                h={SizePixel(28)}
                                _pressed={{ bg: 'gray.200' }}
                                bg={'gray.100'}
                            >
                                {({ isHovered, isFocused, isPressed }) => {
                                    return <ProductMinusAndPlus type={'m'} color={isPressed ? '#FFF' : '#000000'} />;
                                }}
                            </Pressable>
                            <Pressable
                                disabled={modify}
                                alignItems={'center'}
                                justifyContent={'center'}
                                w={SizePixel(28)}
                                h={SizePixel(28)}
                                mx={SizePixel(8)}
                            >
                                <Text {...La17B} lineHeight={SizePixel(26.35)}>
                                    {products.qty}
                                </Text>
                            </Pressable>
                            <Pressable
                                display={modify ? 'none' : 'flex'}
                                disabled={modify}
                                onLongPress={() => updateProduct('up', index)}
                                onPress={() => updateProduct('up', index)}
                                alignItems={'center'}
                                justifyContent={'center'}
                                w={SizePixel(28)}
                                h={SizePixel(28)}
                                _pressed={{ bg: 'gray.200' }}
                                bg={'gray.100'}
                            >
                                {({ isHovered, isFocused, isPressed }) => {
                                    return <ProductMinusAndPlus type={'p'} color={isPressed ? '#FFF' : '#000000'} />;
                                }}
                            </Pressable>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
