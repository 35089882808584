import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Center,
    Input,
    Stack,
    IconButton,
    Button,
    HStack,
    Divider,
    ScrollView,
    Image,
    Heading,
    useToast,
    Pressable,
} from 'native-base';
import PasswordViewIcon from '../../assets/icons/passwordView.icon';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from '../../components/customInput/defaultInput';
import ErrorHelp from '../../components/customInput/error.help';
import DefaultHeader from '../header/header.default';
import SelectAccountSheet from '../../components/bottomSheet/selectAccount.sheet';
import useAxiosServices from '../../hooks/axiosHooks';
import { Ar17B, Ar17M, Ar17R, Ar21B, Ar36B } from '../../themes/font.style';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { loginAction, profileSetting } from '../../store/authReducer';
import { RootState } from '../../store';
import { loadingEndAction } from '../../store/commonReducer';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import JoinSelect from '../../components/bottomSheet/join.select';

const SingInSchema = Yup.object().shape({
    accountType: Yup.string(),
    email: Yup.string().email('Please enter a valid email').min(4, 'Password Too short').required('Please enter your email'),
    password: Yup.string()
        .min(8, 'This password is too short. It must contain at least 8 characters.')
        .required('Please enter your password'),
    passwordCheck: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match"),
});

const passwordView = require('../../assets/icons/password.png');
import paypalIcon from '../../assets/icons/paypal.png';
import { MAXWIDTH } from '../../themes';
import { FontPixel } from '../../components/typographys/font.pixel';
import useWindowDimensions from '../../hooks/useDimensions';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import { LongButton } from '../../components/buttons/long.button';
import axios from 'axios';
const AccountLoginCheck = () => {
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [searchParams] = useSearchParams();
    const queryId = location.state;
    const { appToken } = useSelector((state: RootState) => state.auth);
    const { axiosService } = useAxiosServices();
    const { handleChange, errors, touched, values, setValues, setFieldValue, handleSubmit } = useFormik({
        validationSchema: SingInSchema,
        initialValues: {
            accountType: '',
            email: '',
            password: '',
            passwordCheck: '',
        },
        onSubmit: async (value) => {
            await getLoginCheck();
        },
    });
    const navigation = useNavigate();
    const goToEmailValidateScreen = (code: number, email: string, password: string) => {
        // @ts-ignore
        navigation('/emailValidate', { state: { email, password, code, accountType: values.accountType } });
    };
    const submit = async (value: any) => {
        try {
            const { email, passwordCheck, password } = value;
            const api = await axiosService.post('users/check/email', { email, passwordCheck, password });
            const { status, data } = api.data;
            if (!status) {
                toast(data);
            } else {
                goToEmailValidateScreen(data, email, password);
            }
        } catch (e) {
            toast('Error.');
        }
    };
    const [view, setView] = useState(false);
    const [view2, setView2] = useState(false);

    useEffect(() => {
        getData();
    }, [queryId]);
    const getData = async () => {
        try {
            const api = await axiosService.post('/before-store/info', { id: queryId });
            const { status, info } = api.data;
            if (status) {
                console.log('INFO', info);
                const { userEmail } = info;
                await setFieldValue('email', userEmail);
            } else {
                toast('Not found user information');
                navigate('/login', { replace: true });
            }
        } catch (e) {
            console.log('error');
            console.log('error');

            toast('Not found user information');
            navigate('/login', { replace: true });
        }
    };
    const getLoginCheck = async () => {
        try {
            const { email, password } = values;

            const api = await axiosService.post('before-store/auth', { identifier: email, password });
            const { jwt, user } = api.data;
            if (jwt) {
                navigate('/accountChange', { state: { id: queryId, email, password } });
                console.log('INFO', user);
            } else {
                toast.error('Check your information');
            }
        } catch (e) {
            const { data } = e.response;
            const messages = data.message;
            console.log(messages);
            if (messages && messages?.length > 0) {
                const m = messages[0];
                if (m && m?.hasOwnProperty('messages')) {
                    const deMM = m.messages;
                    const deM = deMM[0];
                    console.log(deM);
                    const finalMessage = deM?.message ?? 'Check your information';
                    toast.error(finalMessage);
                } else {
                    toast.error('Check your information');
                }
            } else {
                toast.error('Check your information');
            }
        }
    };

    const dispatch = useDispatch();

    const { sizePix: SizePixel, height } = useWindowDimensions();

    return (
        <>
            <Box bg={'white.100'} alignItems={'center'} width={'100vw'} h={height}>
                <DefaultHeader navigation={navigation} bg={'white.100'} />
                <Box flex={1} px={SizePixel(29)} maxW={InitWidthSizeScreen} width={'100%'} flexGrow={1} justifyContent={'space-between'}>
                    <Box>
                        <Box mt={SizePixel(28)} alignItems={'center'}>
                            <FontPixel {...Ar36B} color={'black.100'}>
                                Check Login
                            </FontPixel>
                        </Box>
                        <Box mb={SizePixel(41)} mt={SizePixel(28)} alignItems={'flex-start'}>
                            <FontPixel {...Ar17R} color={'black.100'}>
                                Please log in with your existing login information
                                <br /> to verify your account ownership.
                            </FontPixel>
                        </Box>
                        <Box>
                            <Box width={'100%'}>
                                <ErrorHelp errors={errors} />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    isDisabled={true}
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChangeText={handleChange('email')}
                                    placeholder="Email"
                                />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                    onChangeText={handleChange('password')}
                                    type={view ? 'text' : 'password'}
                                    InputRightElement={
                                        <IconButton
                                            onPress={() => setView((p) => !p)}
                                            _pressed={{ bg: 'transfer', opacity: 0.5 }}
                                            icon={<PasswordViewIcon color={'#ACACAC'} boolean={view} />}
                                        />
                                    }
                                    placeholder="Password"
                                />
                                <Box mb={SizePixel(16)} />
                                <Box alignItems={'flex-end'}>
                                    <Pressable
                                        onPress={() => navigation('/accountChange/find/send', { state: { queryId, email: values.email } })}
                                    >
                                        <Text {...Ar17B}>Forgot your password?</Text>
                                    </Pressable>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={SizePixel(74)}>
                            <Box width={'100%'}>
                                <LongButton my={2} colorScheme={'blue.200'} onPress={() => handleSubmit()} variant={'basicButton'}>
                                    <HStack alignItems={'center'}>
                                        <Box alignItems={'center'} width={'100%'}>
                                            <FontPixel color={'white.100'} fontFamily={'Arch'} fontWeight={'700'} fontSize={21}>
                                                Login
                                            </FontPixel>
                                        </Box>
                                    </HStack>
                                </LongButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AccountLoginCheck;
