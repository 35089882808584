import { lazy } from 'react';
import NavMotion from './nav.Motion';
import GuestGuard from './guest.guard';
import OutletLayout from './outlet.layout';
import Loadable from './loadable';
import LoginSelectScreen from '../containers/auth/login.select';
import AuthGuard from './auth.guard';
import DashboardUser from '../containers/user/dashboard.user';
import ProfileWrapper from '../containers/auth/profile.wrapper';
import AddressInfo from '../containers/user/address/address.info';
import GoogleSearch from '../components/map/google.search';
import FirstAgree from '../containers/user/collections/first.agree';
import DoneView from '../containers/user/collections/done.view';
import MainCollection from '../containers/collection/index';
import DetailCollection from '../containers/user/collections/detail.collection';
import TransActionList from '../containers/user/transaction/transaction.list';
import RedeemMain from '../containers/user/redeem/redeem.main';
import RedeemSelect from '../containers/user/redeem/redeem.select';
import RedeemCharitied from '../containers/user/redeem/redeem.charitied';
import CharityDetail from '../containers/user/redeem/charity.detail';
import PayoutDetail from '../containers/user/redeem/payout.detail';
import CollectionList from '../containers/user/collections/collection.list';
import ShareScreen from '../containers/user/transaction/share.screen';
import PasswordNew from '../containers/auth/password.new';
import ReportCollection from '../containers/user/collections/report.collection';
import MapAdjust from '../components/map/adjust.screen';
import { PaymentAddScreen } from '../containers/payment/payment.add.screen';
import getStripe from '../containers/payment/get.stripe';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentScreen } from '../containers/payment/payment.screen';
// project imports

// login routing

const LoginSignUp = Loadable(lazy(() => import('../containers/auth/login.signUp')));

// ==============================|| AUTH ROUTING ||============================== //

const UserNavigation = {
    path: '/',
    element: (
        <AuthGuard>
            <OutletLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/main',
            element: <DashboardUser />,
        },
        {
            path: '/profile',
            element: <ProfileWrapper />,
        },
        {
            path: '/addressAdd',
            element: <GoogleSearch />,
        },
        {
            path: '/addressInfo',
            element: <AddressInfo />,
        },
        {
            path: '/adjust',
            element: <MapAdjust />,
        },
        {
            path: '/agreeView',
            element: <FirstAgree />,
        },
        {
            path: '/doneView',
            element: <DoneView />,
        },
        {
            path: '/mainCollection',
            element: <MainCollection />,
        },
        {
            path: '/detailCollection/:idx',
            element: <MainCollection />,
        },
        {
            path: '/transaction',
            element: <TransActionList />,
        },
        {
            path: '/redeemMain',
            element: <RedeemMain />,
        },
        {
            path: '/redeemSelect',
            element: <RedeemSelect />,
        },
        {
            path: '/redeemCharitied',
            element: <RedeemCharitied />,
        },
        {
            path: '/charityDetail/:idx',
            element: <CharityDetail />,
        },
        {
            path: '/payoutDetail',
            element: <PayoutDetail />,
        },
        {
            path: '/collectionList',
            element: <CollectionList />,
        },
        {
            path: '/shareScreen',
            element: <ShareScreen />,
        },
        {
            path: '/passwordNew',
            element: <PasswordNew />,
        },
        {
            path: '/reportCollection/:idx',
            element: <ReportCollection />,
        },
        {
            path: '/CollectionPayment',
            element: <PaymentAddScreen />,
        },
        {
            path: '/paymentScreen',
            element: <PaymentScreen />,
        },
    ],
};

export default UserNavigation;
