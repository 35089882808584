import React from 'react';
import ArrowDown from '../../assets/icons/arrow.down';
import ArrowUp from '../../assets/icons/arrow.up';
import { Box, CheckIcon, Select } from 'native-base';
import { Ar18SbBlack } from '../../themes/font.style';

interface Props {
    data: any[];
    sortHandler: (index: any) => void;
    switchAction: (type: any) => string;
    defaultValueByIndex?: number;
    defaultButtonText: string;
    style?: any;
    value: any;
}

const SortDropDown = ({ value, data, sortHandler, switchAction, defaultButtonText, style }: Props) => {
    return (
        <Box
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            borderWidth={1}
            borderRadius={100}
            borderColor={'blue.200'}
            bg={'white.100'}
            {...style}
        >
            <Select
                bottom={1}
                {...Ar18SbBlack}
                dropdownCloseIcon={
                    <Box mr={'10px'}>
                        <ArrowDown />
                    </Box>
                }
                dropdownOpenIcon={
                    <Box mr={'10px'}>
                        <ArrowUp />
                    </Box>
                }
                selectedValue={value}
                minWidth="200"
                borderWidth={0}
                width={'100%'}
                height={50}
                px={4}
                _selectedItem={{
                    bg: 'gray.100',
                    endIcon: <CheckIcon size="5" color={'#1C6EBA'} />,
                }}
                mt={1}
                onValueChange={(itemValue) => sortHandler(itemValue)}
            >
                {data.map((v) => (
                    <Select.Item label={switchAction(v)} value={String(v)} />
                ))}
            </Select>
        </Box>
    );
};

export default SortDropDown;
