import { Box, Button, Center, HStack, Image, NativeBaseProvider, View, VStack } from 'native-base';
import React, { ReactElement } from 'react';

import theme from '../themes';
import { Provider } from 'react-redux';
import { store } from '../store';
import RootNavigation from './RootNavigation';
import { JWTProvider } from '../contexts/JWTContent';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, Theme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationScroll from './navigation.scroll';
import { BrowserRouter } from 'react-router-dom';

export type StackParams = {
    Home: undefined;
    Details: { data: string } | undefined;
};

export function Navigation(): ReactElement {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                <NativeBaseProvider theme={theme}>
                    <JWTProvider>
                        <RootNavigation />
                    </JWTProvider>
                    <ToastContainer position="top-center" draggable />
                </NativeBaseProvider>
            </StyledEngineProvider>
        </Provider>
    );
}
