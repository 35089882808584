import { useTheme } from 'native-base';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const useColors = () => {
    const theme = useTheme();
    const user = useSelector((state: RootState) => state.auth.user);
    const mainColor = user?.userType === 2 ? theme.colors.green[200] : theme.colors.blue[200];
    const colorScheme = user?.userType === 2 ? theme.colors.green : theme.colors.blue;
    return { mainColor, colorScheme };
};
