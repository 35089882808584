export const FormattingAddress = (address_components) => {
    if (!address_components) {
        return {
            route: '',
            address: '',
            addressMore: '',
            postcode: '',
        };
    }
    let addressString = '';
    let moreString = '';
    let postcode = '';
    const addressStreet = [];
    const componentForm = {
        street_number: ['long_name', 'main', 'street'],
        route: ['short_name', 'main', 'street'],
        locality: ['short_name', 'main', ''],
        sublocality_level_1: ['short_name', 'main', ''],
        administrative_area_level_1: ['short_name', 'more', ''],
        // administrative_area_level_2: ['short_name', 'more', ''],
        postal_code: ['short_name', 'no', ''],
    };
    for (let i = 0; i < address_components.length; i++) {
        // warning this only gets the first type of the component. E.g. 'sublocality_level_1'
        const addressType = address_components[i].types[0];
        if (componentForm[addressType]) {
            const [addressText, type, street] = componentForm[addressType];
            const val = address_components[i][addressText];
            console.log(addressType, val);
            if (street === 'street') {
                // @ts-ignore
                addressStreet.push(val);
            }
            if (type === 'main') {
                addressString += '' + val + ' ';
            }
            if (type === 'more') {
                moreString += '' + val + ' ';
            }
            if (type === 'no') {
                postcode += val;
            }
        }
    }
    return {
        route: addressStreet.slice(0, 2).join(' '),
        address: addressString.replace(/,\s*$/, ''),
        addressMore: moreString.replace(/,\s*$/, ''),
        postcode,
    };
};

export const addressConvert = (addressFormat: (string | undefined | null)[]) => {
    const temp = addressFormat.filter((v) => typeof v === 'string');
    const temp2 = temp.map((v) => v?.trim()).filter((v) => v !== '');
    return temp2.join(', ');
};

export const RouteNameInit = (route, address: string) => {
    if (!route) {
        if (address) {
            const temp = address.split(',');
            const v = temp.map((v) => v.replace(/ /g, ''));
            const [one, two, ...three] = v;
            return `${one?.replace(/ /g, '') ?? ''} ${two?.replace(' ', '') ?? ''}`;
        }
        return ' ';
    }
    return route;
};
