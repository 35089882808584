import React, { useEffect, useState } from 'react';

import { Box, Button, Center, Flex, Image, Modal, Pressable, ScrollView, Text, useToast, VStack } from 'native-base';
import DefaultHeader from '../header/header.default';
import OTPInputView from '../../components/otpInput';
import useAxiosServices from '../../hooks/axiosHooks';
import { Ar17RBlack, Ar18SbBlack, Ar22M, Ar25SbBlack } from '../../themes/font.style';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { LocationParams } from '../../@types/userTypes';
import { MAXWIDTH } from '../../themes';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';
import { FontPixel } from '../../components/typographys/font.pixel';

const mailIcon = require('../../assets/icons/sendMail.png');
const EmailValidate = () => {
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const route: any = useLocation();
    const state: { code: any; email: any; accountType: any; password: any } = route.state;
    console.log('ee', state);
    const [code, setCode] = useState('');
    const [fulfilled, setFulfilled] = useState(false);
    const [verifyCode, setVerifyCode] = useState(state?.code);

    const verifyHandler = () => {
        setFulfilled(code.length === 6);
    };

    const goToVerify = async () => {
        if (String(verifyCode) !== String(code)) {
            toast('Please check the code');
        } else {
            await join();
        }
    };
    const [modalOpen, setModalOpen] = useState(false);
    const join = async () => {
        try {
            const api = await axiosService.post('/users/join/simple', {
                email: state?.email,
                password: state?.password,
                code: state?.code,
                accountType: state?.accountType === 'Personal' ? 1 : state?.accountType === 'Company' ? 2 : 0,
            });
            const { status, data } = api;
            if (!status) {
                toast(data);
            } else {
                navigate('/emailComplete' as never);
            }
        } catch (e) {
            toast('error');
        }
    };

    useEffect(() => {
        verifyHandler();
    }, [code]);

    const reSendCode = async () => {
        try {
            const api = await axiosService.post('users/re/email', { email: state.email });
            const { status, data } = api.data;
            if (!status) {
                toast(data);
            } else {
                setModalOpen(true);
                setVerifyCode(data);
            }
        } catch (e) {
            toast('Error');
        }
    };
    const { sizePix } = useWindowDimensions();
    return (
        <>
            <Box bg={'white.100'} alignItems={'center'} width={'100vw'}>
                <DefaultHeader navigation={navigate} />
                <Box maxW={InitWidthSizeScreen} px={sizePix(28)} safeAreaBottom flexGrow={1} justifyContent={'space-between'}>
                    <Box w={'100%'}>
                        <Box mt={sizePix(70)} pt={0} w={'100%'}>
                            <FontPixel mb={3} textAlign={'left'} fontFamily={'Arch'} fontWeight={'500'} fontSize={28}>
                                Verify your Email
                            </FontPixel>
                            <FontPixel {...Ar18SbBlack}>We sent the verification code to</FontPixel>
                            <FontPixel {...Ar18SbBlack}>
                                <FontPixel {...Ar18SbBlack} color={'blue.100'}>
                                    {state?.email ?? 'test@naver.com'}
                                </FontPixel>
                            </FontPixel>
                            <FontPixel {...Ar18SbBlack}>Which expires in 15 minutes. Please enter it below.</FontPixel>
                        </Box>
                        <OTPInputView
                            style={{ width: '100%', height: 200 }}
                            pinCount={6}
                            placeholderTextColor={'#222'}
                            code={code}
                            onCodeChanged={(codeInput) => setCode(codeInput)}
                            onCodeFilled={() => console.log(code)}
                            // code={this.state.code} //You can supply this prop or not. The component will be used as a controlled / uncontrolled component respectively.
                            // onCodeChanged = {code => { this.setState({code})}}
                            autoFocusOnLoad
                        />
                    </Box>
                    <Flex mb={2}>
                        <Box justifyContent={'flex-end'}>
                            <Box justifyContent={'center'} alignItems={'center'}>
                                <Pressable onPress={() => reSendCode()}>
                                    <FontPixel {...Ar22M} mb={3} color={'gray.300'}>
                                        Send Code again
                                    </FontPixel>
                                </Pressable>
                                <Button
                                    h={sizePix(53)}
                                    onPress={() => goToVerify()}
                                    disabled={!fulfilled}
                                    bg={fulfilled ? 'blue.200' : 'gray.200'}
                                    variant={'shadowBasic'}
                                >
                                    <Text {...Ar22M} color={'white.100'}>
                                        Continue
                                    </Text>
                                </Button>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Modal p={0} isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Content maxWidth="400px" borderRadius={10}>
                    <Modal.Body>
                        <Center mt={10}>
                            <Image w={'100px'} h={'100px'} resizeMode={'contain'} source={mailIcon} alt={'houseimage'} />
                            <VStack space={2} mt={4} alignItems={'center'}>
                                <Text {...Ar25SbBlack}>Check your email</Text>
                                <Text {...Ar17RBlack} textAlign={'center'}>
                                    {'check your email\n' + 'Please enter it below.\n'}
                                </Text>
                            </VStack>
                        </Center>
                    </Modal.Body>
                    <Modal.Footer bg={'blue.200'}>
                        <Button.Group>
                            <Button
                                borderRadius={0}
                                bg={'blue.200'}
                                w={'100%'}
                                variant="basicButton"
                                onPress={() => {
                                    setModalOpen(false);
                                }}
                            >
                                <Text textAlign={'center'} {...Ar25SbBlack} color={'white.100'}>
                                    Got it!
                                </Text>
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default EmailValidate;
