import React, { useRef } from 'react';
import { addressListType } from '../../@types/userTypes';
import { Box, Button, Image, Popover, Pressable, Text, VStack } from 'native-base';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { addressChange } from '../../store/authReducer';
import DotHorizon from '../../assets/icons/dot.horizon';
import { Ar18M, Ar18SbBlack } from '../../themes/font.style';
import { addressNullCheck } from '../../utils/gup';
import { useNavigate } from 'react-router-dom';
import { Img } from 'react-image';
interface Props {
    address: addressListType;
    makeMain: (idx: number) => void;
    deleteAddress: (idx: number) => void;
    main?: boolean;
}
import marker from '../../assets/icons/address-pin.png';
const dotHorizen = require('../../assets/icons/dotHorizon.png');
import checked from '../../assets/icons/simpleCheck.png';
import { FontPixel } from '../typographys/font.pixel';
import { addressConvert, RouteNameInit } from '../../utils/helper/address.helper';
import { addressAdd } from '../../store/commonReducer';
const AddressCard = ({ address, makeMain, deleteAddress, main }: Props) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const navigation = useNavigate();
    const dispatch = useDispatch();

    const goToAddressEdit = (v: addressListType) => {
        dispatch(
            addressAdd({
                addressTemp: {
                    route: RouteNameInit(v.route, v.address),
                    address: v.address,
                    addressMore: v.addressMore,
                    lat: v.lat,
                    lot: v.lot,
                    postcode: v.postcode,
                    type: 'user',
                    idx: v.idx,
                },
            })
        );
        navigation('/addressAdd');
    };
    const [show, setShow] = React.useState<boolean>(false);
    return (
        <Box w={'100%'} flexDirection={'row'} pb={3} justifyContent={'space-between'} alignItems={'center'}>
            <Box w={'80%'} flexDirection={'row'} alignItems={'center'} overflow={'hidden'} justifyContent={'flex-start'}>
                <Img
                    src={address.main === 0 ? marker : checked}
                    alt={'title'}
                    width={'27px'}
                    height={'27px'}
                    style={{ objectFit: 'contain' }}
                />

                <Box ml={'27px'} alignItems={'flex-start'} overflow={'hidden'}>
                    <FontPixel {...Ar18SbBlack} color={'black.100'}>
                        {RouteNameInit(address.route, address.address)}
                    </FontPixel>
                    <FontPixel {...Ar18M} color={'gray.300'}>
                        {addressConvert([address.addressMore, address.postcode])}
                    </FontPixel>
                </Box>
            </Box>
            <Box>
                {main ? (
                    <Pressable onPress={() => goToAddressEdit(address)}>
                        <FontPixel {...Ar18SbBlack} color={'blue.200'}>
                            Edit
                        </FontPixel>
                    </Pressable>
                ) : (
                    <Popover
                        isOpen={show}
                        onClose={() => setShow(false)}
                        placement={'bottom right'}
                        trigger={(triggerProps) => {
                            return (
                                <Button
                                    {...triggerProps}
                                    onPress={() => setShow(true)}
                                    bg={'white.100'}
                                    _pressed={{ opacity: 0.5, bg: 'gray.100' }}
                                >
                                    <DotHorizon />
                                </Button>
                            );
                        }}
                    >
                        <Popover.Content bg={'white.100'} shadow={10} accessibilityLabel="Delete Customerd" w="100%">
                            <Popover.Body>
                                <VStack p={2} space={4}>
                                    <Box>
                                        <Pressable
                                            onPress={() => {
                                                makeMain(address.idx);
                                                setShow(false);
                                                if (address.main === 0) {
                                                    dispatch(addressChange({ addressCheck: 1 }));
                                                }
                                                if (address.main === 1) {
                                                    dispatch(addressChange({ addressCheck: 0 }));
                                                }
                                            }}
                                        >
                                            <Text fontSize={'16px'} fontWeight={600} fontFamily={'Arch'} color={'blue.200'}>
                                                {address.main === 0 ? 'Make this my pickup address' : 'UnMake list'}
                                            </Text>
                                        </Pressable>
                                    </Box>
                                    <Box>
                                        <Pressable
                                            onPress={() => {
                                                setShow(false);
                                                goToAddressEdit(address);
                                            }}
                                        >
                                            <Text fontSize={'16px'} fontWeight={600} fontFamily={'Arch'} color={'blue.200'}>
                                                Edit
                                            </Text>
                                        </Pressable>
                                    </Box>
                                </VStack>
                            </Popover.Body>
                        </Popover.Content>
                    </Popover>
                )}
            </Box>
        </Box>
    );
};

export default AddressCard;
