import React from 'react';
import { Svg, G, Path, Defs, ClipPath, Rect } from 'react-native-svg';
import useWindowDimensions from '../../hooks/useDimensions';

const SearchIcon = () => {
    const { sizePix } = useWindowDimensions();
    return (
        <Svg width={sizePix(31)} height={sizePix(31)} viewBox="0 0 31 31" fill="none">
            <Path
                d="M27.8324 24.5797L22.0587 18.8056C23.0397 17.2438 23.6091 15.3979 23.6091 13.4169C23.6091 7.81233 19.0656 3.26953 13.4613 3.26953C7.85697 3.26953 3.31396 7.81233 3.31396 13.4169C3.31396 19.0216 7.85676 23.564 13.4613 23.564C15.6172 23.564 17.614 22.89 19.2572 21.7447L24.9622 27.4501C25.3586 27.8461 25.8783 28.0434 26.3973 28.0434C26.917 28.0434 27.436 27.8461 27.833 27.4501C28.6252 26.6571 28.6252 25.3725 27.8324 24.5797ZM13.4613 20.2764C9.67329 20.2764 6.60219 17.2055 6.60219 13.4173C6.60219 9.62906 9.67329 6.55797 13.4613 6.55797C17.2495 6.55797 20.3204 9.62906 20.3204 13.4173C20.3204 17.2055 17.2495 20.2764 13.4613 20.2764Z"
                fill="#1C6EBA"
            />
        </Svg>
    );
};

export default SearchIcon;
