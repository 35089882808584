import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Text, View } from 'native-base';
import { InitWidthSizeScreen, ScreenWidth, SizePixel } from '../../utils/pixcel.convert';
import { useColors } from '../../hooks/useColors';
import { Ar22M, La17N, La31B } from '../../themes/font.style';
import { LongButton } from '../../components/buttons/long.button';
import { CollectionPageType } from './index';
import { IProductsTypes } from './temp.const';
import { ProductAllAmountRender, ProductQtyRender } from '../../utils/product.helper';
import { NumberFormatting } from '../../utils/helper/number.helper';
import { BottomButton } from './bottom.button';

interface IProps {
    MAXSTEP: number;
    step: number;
    handleStep: (type: 'down' | 'up') => void;
    backOption: () => void;
    pageType: CollectionPageType;
    product: IProductsTypes;
    modify: boolean;
    renderDisabled: boolean;
    saveAPI: any;
    initializePaymentSheet: any;
    propsRef: any;
}
export const CollectionBottom = ({
    propsRef,
    initializePaymentSheet,
    saveAPI,
    renderDisabled,
    MAXSTEP,
    step,
    handleStep,
    backOption,
    pageType,
    product,
    modify,
}: IProps) => {
    const { mainColor, colorScheme } = useColors();
    const stepValue = useState(0);
    const animationStyle = useCallback(() => {
        const StepAcc = ScreenWidth / MAXSTEP;
        const stepInput = new Array(MAXSTEP).fill('1').map((_, i) => i);
        const stepOutput = new Array(MAXSTEP).fill('1').map((_, i) => i * StepAcc);
        const width = stepInput;
        return {
            width: StepAcc,
            height: '100%',
            backgroundColor: mainColor,
        };
    }, [MAXSTEP]);

    const renderSwitch = () => {
        switch (pageType) {
            case 'Plain':
            case 'Map':
                return (
                    <Box w={'100%'} px={SizePixel(12)} justifyContent={'space-between'} flexDirection={'row'}>
                        <Button
                            _pressed={{ opacity: 0.5, bg: 'none' }}
                            onPress={() => backOption()}
                            borderRadius={SizePixel(27)}
                            width={SizePixel(160)}
                            h={SizePixel(58)}
                            bg={'white.100'}
                            borderWidth={'1px'}
                            borderColor={'gray.200'}
                        >
                            <Text {...Ar22M} color={'gray.300'}>
                                No
                            </Text>
                        </Button>
                        <Button
                            disabled={renderDisabled}
                            _disabled={{ bg: 'gray.200' }}
                            _pressed={{ opacity: 0.5, bg: mainColor }}
                            onPress={() => handleStep('up')}
                            borderRadius={SizePixel(27)}
                            width={SizePixel(160)}
                            h={SizePixel(58)}
                            bg={mainColor}
                        >
                            <Text {...Ar22M} color={'white.100'}>
                                Yes
                            </Text>
                        </Button>
                    </Box>
                );
            case 'Collection':
            case 'Date':
            case 'Description':
            case 'Photo':
                return (
                    <Box px={SizePixel(32)} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                        <LongButton
                            disabled={renderDisabled}
                            condition={renderDisabled}
                            _pressed={{ opacity: 0.5, bg: 'none' }}
                            onPress={() => handleStep('up')}
                            borderRadius={SizePixel(27)}
                            width={SizePixel(358)}
                            h={SizePixel(58)}
                            bg={'white.100'}
                            shadow={6}
                        >
                            <Text {...Ar22M} color={'white.100'}>
                                Next
                            </Text>
                        </LongButton>
                    </Box>
                );
            case 'Shop':
            case 'Credit':
                const amount = ProductAllAmountRender(product);
                return (
                    <Box px={SizePixel(32)} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                        {amount > 0 ? (
                            <Box w={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={SizePixel(24)}>
                                <Box>
                                    <Text {...La17N} lineHeight={SizePixel(26.35)}>
                                        {`${ProductQtyRender(product)} items(s)`}
                                    </Text>
                                    <Text {...La31B} lineHeight={SizePixel(37)}>
                                        {`$${NumberFormatting(amount)}`}
                                    </Text>
                                </Box>
                                <Button
                                    disabled={renderDisabled}
                                    _pressed={{ opacity: 0.5, bg: mainColor }}
                                    onPress={() => {
                                        initializePaymentSheet();
                                        handleStep('up');
                                    }}
                                    borderRadius={SizePixel(27)}
                                    width={SizePixel(182)}
                                    h={SizePixel(58)}
                                    bg={renderDisabled ? 'gray.200' : mainColor}
                                >
                                    <Text {...Ar22M} color={'white.100'}>
                                        Next
                                    </Text>
                                </Button>
                            </Box>
                        ) : (
                            <LongButton
                                disabled={renderDisabled}
                                _disabled={{ bg: 'gray.200' }}
                                _pressed={{ opacity: 0.5, bg: 'none' }}
                                onPress={() => handleStep('up')}
                                borderRadius={SizePixel(27)}
                                width={SizePixel(358)}
                                h={SizePixel(58)}
                                bg={'white.100'}
                                shadow={6}
                            >
                                <Text {...Ar22M} color={'white.100'}>
                                    Skip
                                </Text>
                            </LongButton>
                        )}
                    </Box>
                );
            case 'Regular':
                return (
                    <Box px={SizePixel(32)} justifyContent={'space-between'} flexDirection={'row'}>
                        <Button
                            _pressed={{ opacity: 0.5, bg: 'none' }}
                            onPress={() => backOption()}
                            borderRadius={SizePixel(27)}
                            width={SizePixel(160)}
                            h={SizePixel(58)}
                            bg={'white.100'}
                        >
                            <Text {...Ar22M} color={'gray.300'}>
                                No
                            </Text>
                        </Button>
                        <Button
                            disabled={renderDisabled}
                            _disabled={{ bg: 'gray.200' }}
                            _pressed={{ opacity: 0.5, bg: mainColor }}
                            onPress={() => handleStep('up')}
                            borderRadius={SizePixel(27)}
                            width={SizePixel(160)}
                            h={SizePixel(58)}
                            bg={renderDisabled ? 'gray.200' : mainColor}
                        >
                            <Text {...Ar22M} color={'white.100'}>
                                Set
                            </Text>
                        </Button>
                    </Box>
                );
            case 'Detail':
                const amountDetail = ProductAllAmountRender(product);
                const textModify = modify ? 'Update' : 'Submit';
                const textButton = `${textModify}${amountDetail > 0 ? ' & Purchase' : ''}`;
                return (
                    <Text {...Ar22M} color={'white.100'}>
                        {textButton}
                    </Text>
                );
            default:
                return <Box />;
        }
    };

    const pageRefry = () => {
        if (pageType === 'Shop') {
            return false;
        }
        if (pageType === 'Credit') {
            return false;
        }
        if (pageType === 'Detail') {
            return false;
        }
        return true;
    };
    console.log('ageRefry()', propsRef);

    return (
        <div>
            {pageRefry() ? (
                <Box position={'absolute'} w={'100%'} pb={SizePixel(60)} bottom={0} bg={'white.100'} h={SizePixel(164)}>
                    <Box height={SizePixel(4)} bg={'gray.50'} w={'100%'} mb={SizePixel(42)}>
                        <Box style={{ ...animationStyle() }} />
                    </Box>
                    {renderSwitch()}
                </Box>
            ) : pageType === 'Detail' ? (
                <BottomButton
                    onPress={() => saveAPI()}
                    cHeight={154}
                    cGHeight={200}
                    cBottom={-20}
                    shadow={8}
                    disabled={renderDisabled}
                    _disabled={{ bg: 'gray.200' }}
                >
                    {renderSwitch()}
                </BottomButton>
            ) : (
                <Box position={'absolute'} w={'100%'} pb={SizePixel(30)} bottom={0} bg={'white.100'} h={SizePixel(120)}>
                    <Box height={SizePixel(4)} w={'100%'}>
                        <View />
                    </Box>
                    {renderSwitch()}
                </Box>
            )}
        </div>
    );
};
