import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import styles from './style.module.css';
import { Box, Pressable, Text } from 'native-base';
import { SizePixel } from '../../utils/pixcel.convert';
import { TouchableOpacity } from 'react-native';
import { Ar21Sb } from '../../themes/font.style';

interface IProps {
    src: string[];
    currentIndex?: number;
    backgroundStyle?: CSSProperties;
    disableScroll?: boolean;
    closeOnClickOutside?: boolean;
    onClose?: () => void;
    closeComponent?: JSX.Element;
    leftArrowComponent?: JSX.Element;
    rightArrowComponent?: JSX.Element;
    removeIndex: () => void;
}

const ReactSimpleImageViewer = (props: IProps) => {
    const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);

    const changeImage = useCallback(
        (delta: number) => {
            let nextIndex = (currentIndex + delta) % props.src.length;
            if (nextIndex < 0) {
                nextIndex = props.src.length - 1;
            }
            setCurrentIndex(nextIndex);
        },
        [currentIndex]
    );

    const handleClick = useCallback(
        (event: any) => {
            if (!event.target || !props.closeOnClickOutside) {
                return;
            }

            const checkId = event.target.id === 'ReactSimpleImageViewer';
            const checkClass = event.target.classList.contains('react-simple-image-viewer__slide');

            if (checkId || checkClass) {
                event.stopPropagation();
                props.onClose?.();
            }
        },
        [props.onClose]
    );

    const handleKeyDown = useCallback(
        (event: any) => {
            if (event.key === 'Escape') {
                props.onClose?.();
            }

            if (['ArrowLeft', 'h'].includes(event.key)) {
                changeImage(-1);
            }

            if (['ArrowRight', 'l'].includes(event.key)) {
                changeImage(1);
            }
        },
        [props.onClose, changeImage]
    );

    const handleWheel = useCallback(
        (event: any) => {
            if (event.wheelDeltaY > 0) {
                changeImage(-1);
            } else {
                changeImage(1);
            }
        },
        [changeImage]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        if (!props.disableScroll) {
            document.addEventListener('wheel', handleWheel);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);

            if (!props.disableScroll) {
                document.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleKeyDown, handleWheel]);

    return (
        <div
            id="ReactSimpleImageViewer"
            className={`${styles.wrapper} react-simple-image-viewer__modal`}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            style={props.backgroundStyle}
        >
            <Box
                top={0}
                w={'100%'}
                bg={'black.100'}
                position={'absolute'}
                pt={SizePixel(20)}
                px={SizePixel(32)}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                zIndex={10}
                h={SizePixel(74)}
            >
                <div style={{ display: 'flex' }}>
                    <TouchableOpacity onPress={() => props.removeIndex()}>
                        <Text {...Ar21Sb} color={'white.100'}>
                            Remove
                        </Text>
                    </TouchableOpacity>
                </div>
                <span style={{ color: '#fff', fontSize: '32px', cursor: 'pointer' }} onClick={() => props.onClose?.()}>
                    {props.closeComponent || '×'}
                </span>
            </Box>

            {props.src.length > 1 && (
                <span className={`${styles.navigation} ${styles.prev} react-simple-image-viewer__previous`} onClick={() => changeImage(-1)}>
                    {props.leftArrowComponent || '❮'}
                </span>
            )}

            {props.src.length > 1 && (
                <span className={`${styles.navigation} ${styles.next} react-simple-image-viewer__next`} onClick={() => changeImage(1)}>
                    {props.rightArrowComponent || '❯'}
                </span>
            )}

            <div className={`${styles.content} react-simple-image-viewer__modal-content`} onClick={handleClick}>
                <div className={`${styles.slide} react-simple-image-viewer__slide`}>
                    <img className={styles.image} src={props.src[currentIndex]} alt="" />
                </div>
            </div>
        </div>
    );
};

export default ReactSimpleImageViewer;
