import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING } from './index';
import { Box, Button, HStack, Image, Pressable, ScrollView, Text } from 'native-base';
import { Ar17R, Ar18M, Ar18SbBlack, Ar36B } from '../../themes/font.style';
import { Image as RNImage, Modal } from 'react-native';
import { v4 as uuid } from 'uuid';
import { CollectionCheckCircleIcon, CollectionUnCheckCircleIcon } from './plain.icon';
import { IPhotoListTypes } from '../../@types/collection.types';
import ImageUploading from 'react-images-uploading';
import { PhotoModal } from './photo.modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Img } from 'react-image';
import LibImage from '../../assets/icons/photo.png';
import CameraImage from '../../assets/icons/camera.png';

interface IProps {
    dogCheck: boolean;
    setDogCheck: Dispatch<SetStateAction<boolean>>;
    saveDefault: boolean;
    setSaveDefault: Dispatch<SetStateAction<boolean>>;
    photoList: IPhotoListTypes[];
    setPhotoList: Dispatch<SetStateAction<IPhotoListTypes[]>>;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
}
export const PhotoCollectionScreen = ({
    setPageType,
    photoList,
    setPhotoList,
    saveDefault,
    setSaveDefault,
    setDogCheck,
    dogCheck,
}: IProps) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const [photoArray, setPhotoArray] = useState<IPhotoListTypes[][]>([]);
    useEffect(() => {
        setPageType('Photo');
    }, []);
    const photoHandler = async (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        if (!imageList?.[addUpdateIndex[0]]) {
            return null;
        }
        const update = imageList[addUpdateIndex[0]];
        console.log('update', update);
        setPhotoList((p) => [...p, { ...update, key: uuid() as string, modify: true }]);
        // if (type === 'pick') {
        //     await ImagePicker.openPicker({
        //         width: 600,
        //         height: 600,
        //         compressImageQuality: 0.5,
        //     }).then((image) => {
        //         setPhotoList((p) => [...p, { key: uuid.v4() as string, file: image, modify: true }]);
        //         console.log(image);
        //     });
        // } else {
        //     await ImagePicker.openCamera({
        //         width: 600,
        //         height: 600,
        //         compressImageQuality: 0.5,
        //     }).then((image) => {
        //         setPhotoList((p) => [...p, { key: uuid.v4() as string, file: image, modify: true }]);
        //     });
        // }
    };

    const handleArraySplit = () => {
        const perChunk = 3;
        const result = photoList.reduce((resultArray: IPhotoListTypes[][], item, index) => {
            const chunkIndex = Math.floor(index / perChunk);
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
        }, []);
        setPhotoArray([...result]);
    };
    const handleArraySplitList = (photoList) => {
        const perChunk = 3;
        const result = photoList.reduce((resultArray: IPhotoListTypes[][], item, index) => {
            const chunkIndex = Math.floor(index / perChunk);
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
        }, []);
        return [...result];
    };
    console.log('photoList', handleArraySplitList(photoList));
    useEffect(() => {}, [photoList]);

    const [view, setView] = useState(false);
    const [viewIndex, setViewIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setViewIndex(index);
        setView(true);
    };

    const handleRemovePhotoIndex = (index: number) => {
        setView(false);
        const photo = [...photoList];
        const filter = photo.filter((v, i) => i !== index);
        setPhotoList([...filter]);
    };
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setPhotoArray(imageList);
    };

    return (
        <Box px={SizePixel(MAIN_COLLECTION_PADDING)} mt={SizePixel(64.37)}>
            <Box mb={SizePixel(6)}>
                <Text {...Ar36B} color={'black.100'}>
                    Upload Photo
                </Text>
                <Text {...Ar18M} color={'gray.300'} mt={SizePixel(12)} lineHeight={SizePixel(22.86)}>
                    Let us know your secure bags location (Please be specific)
                </Text>
            </Box>
            <ImageUploading multiple value={photoList} onChange={photoHandler} maxNumber={9} dataURLKey="url">
                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    <ScrollView scrollToOverflowEnabled={true} showsVerticalScrollIndicator={false} maxH={'64vh'} overflowY={'auto'}>
                        <Box flexDirection={'row'} mt={SizePixel(33)} mb={SizePixel(20)}>
                            <Button
                                onPress={onImageUpload}
                                variant={'basicButton'}
                                w={SizePixel(110)}
                                h={SizePixel(60)}
                                borderColor={'black.100'}
                                borderWidth={1}
                                disabled={photoList.length === 9}
                                style={{ opacity: photoList.length === 9 ? 0.3 : 1 }}
                            >
                                <Img style={{ width: SizePixel(31), height: SizePixel(24), objectFit: 'contain' }} src={LibImage} />
                            </Button>
                        </Box>
                        <Box>
                            {handleArraySplitList(photoList).map((v, i) => (
                                <>
                                    <HStack space={SizePixel(20)} mb={SizePixel(20)}>
                                        {v.map((vv, ii) => (
                                            <Pressable
                                                onPress={() => handleOpenModal(ii + i)}
                                                key={vv.key}
                                                w={SizePixel(109)}
                                                h={SizePixel(79.84)}
                                                backgroundColor={'gray.10'}
                                                borderRadius={SizePixel(8.99)}
                                                overflow={'hidden'}
                                            >
                                                <Img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={vv.url} />
                                            </Pressable>
                                        ))}
                                    </HStack>
                                </>
                            ))}
                        </Box>
                        <Box>
                            {user?.userType === 1 && (
                                <Pressable
                                    onPress={() => setDogCheck((p) => !p)}
                                    borderBottomWidth={1}
                                    borderColor={'gray.100'}
                                    pt={SizePixel(28)}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'flex-start'}
                                >
                                    <Box>
                                        <Text {...Ar18SbBlack} color={'black.100'} mb={SizePixel(12)} lineHeight={SizePixel(19.58)}>
                                            Be aware of Dog?
                                        </Text>
                                        <Text {...Ar17R} color={'black.100'} mb={SizePixel(38.5)} lineHeight={SizePixel(22.07)}>
                                            Pet on Premise
                                        </Text>
                                    </Box>
                                    <Box>{dogCheck ? <CollectionCheckCircleIcon /> : <CollectionUnCheckCircleIcon />}</Box>
                                </Pressable>
                            )}

                            <Pressable
                                onPress={() => setSaveDefault((p) => !p)}
                                pt={SizePixel(39)}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                alignItems={'flex-start'}
                            >
                                <Box>
                                    <Text {...Ar18SbBlack} color={'black.100'} mb={SizePixel(12)} lineHeight={SizePixel(19.58)}>
                                        Save this as a default?
                                    </Text>
                                    <Text {...Ar17R} color={'black.100'} mb={SizePixel(42.5)} lineHeight={SizePixel(22.07)}>
                                        Save it as a default to work on later
                                    </Text>
                                </Box>
                                <Box>
                                    <Box>{saveDefault ? <CollectionCheckCircleIcon /> : <CollectionUnCheckCircleIcon />}</Box>
                                </Box>
                            </Pressable>
                        </Box>
                        <Box h={SizePixel(500)} />
                    </ScrollView>
                )}
            </ImageUploading>
            <PhotoModal view={view} setView={setView} photoList={photoList} removeIndex={handleRemovePhotoIndex} index={viewIndex} />
        </Box>
    );
};
