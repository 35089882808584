import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { bagType } from '../../@types/collection.types';
import { Box, Button, Text } from 'native-base';
import DefaultDown from '../../components/dropdown/default.down';
import { TransactionBagCase } from '../../etc/bookStatusCase';
import NumberInput from '../../components/customInput/number.input';
import { Ar18SbBlack, Ar21Sb, Ar36B } from '../../themes/font.style';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useColors } from '../../hooks/useColors';
import { ScreenWidth, SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING_V2 } from './index';
import WebSheet from '../../components/bottomSheet/web.sheet';
import { TouchableOpacity } from 'react-native';
import { FontPixel } from '../../components/typographys/font.pixel';
interface IProps {
    bagData: bagType[];
    setBagData: Dispatch<SetStateAction<bagType[]>>;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
    headerRef: any;
    bottomRef: any;
}
export const BoxDetailScreen = ({ bagData, setBagData, setPageType, headerRef, bottomRef }: IProps) => {
    const { mainColor } = useColors();
    useEffect(() => {
        setPageType('Collection');
    }, []);
    const typeValueHandler = (index: number, typeValue) => {
        const initial = [...bagData];
        const target = bagData[index];
        if (!target) {
        }
        const { type, value } = target;
        const changeValue = { type: typeValue, value };
        initial[index] = changeValue;
        setBagData([...initial]);
    };

    const bagValueHandler2 = (index: number, amount) => {
        const amountBags = Number(amount);
        const initial = [...bagData];
        const target = bagData[index];
        if (!target) {
        }
        const { type, value } = target;
        const changeValue = { type, value: amountBags };
        initial[index] = changeValue;
        setBagData([...initial]);
    };
    const bagValueHandler = (index: number, amount: 'up' | 'minus') => {
        const initial = [...bagData];
        const target = bagData[index];
        if (!target) {
        }
        const { type, value } = target;
        if (amount === 'up') {
            const changeValue = { type, value: value + 1 };
            initial[index] = changeValue;
            setBagData([...initial]);
        } else {
            if (value > 0) {
                const changeValue = { type, value: value - 1 };
                initial[index] = changeValue;
                setBagData([...initial]);
            } else {
                if (bagData.length > 1) {
                    const filter = initial.filter((v, i) => i !== index);
                    setBagData([...filter]);
                }
            }
        }
    };
    const bagRemove = (index) => {
        const initial = [...bagData];
        const filter = initial.filter((v, i) => i !== index);
        setBagData([...filter]);
    };
    const { userRole, isLogin, user } = useSelector((state: RootState) => state.auth);

    const addBag = () => {
        const temp = [...bagData, { type: 1, value: 0 }];
        if (temp.length < 5) {
            setBagData([...temp]);
        }
    };
    const [webOpen, setWebOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const handleScreenHeight = useCallback(() => {
        const headerHeight = headerRef?.current?.clientHeight;
        const bottomHeight = bottomRef?.current?.offsetTop;
        const height = `100vh - ${headerHeight + bottomHeight}px`;
        console.log('100vh', headerHeight);
        console.log('100vh', bottomHeight);
        return height;
    }, [headerRef, bottomRef]);
    console.log(headerRef);
    console.log(bottomRef);
    console.log('handleScreenHeight', handleScreenHeight());
    return (
        <>
            <Box px={SizePixel(MAIN_COLLECTION_PADDING_V2)} mt={SizePixel(64.37)}>
                <Box mb={SizePixel(41)}>
                    <Text {...Ar36B} color={'black.100'}>
                        Collection Details
                    </Text>
                </Box>
                <Box overflowY={'auto'} maxH={'63vh'}>
                    {bagData.map((v, i) => (
                        <>
                            <DefaultDown
                                placeholder={''}
                                value={v.type}
                                defaultButtonText={TransactionBagCase(v.type)}
                                data={[1, 2, 3, 4]}
                                index={i}
                                switchAction={TransactionBagCase}
                                bagValueHandler={typeValueHandler}
                            />
                            {v.type !== 5 && (
                                <NumberInput
                                    bagValueHandler2={bagValueHandler2}
                                    value={v.value}
                                    index={i}
                                    bagValueHandler={bagValueHandler}
                                />
                            )}
                            {i > 0 && (
                                <Box
                                    w={'100%'}
                                    justifyContent={'flex-end'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    right={3}
                                    bottom={3}
                                >
                                    <TouchableOpacity onPress={() => bagRemove(i)}>
                                        <FontPixel {...Ar18SbBlack} color={'gray.200'}>
                                            Remove
                                        </FontPixel>
                                    </TouchableOpacity>
                                </Box>
                            )}
                        </>
                    ))}
                    {user?.userType !== 1 && bagData.length < 4 && (
                        <Box w={'100%'} justifyContent={'center'} flexDirection={'row'} alignItems={'center'} mt={SizePixel(29)}>
                            <TouchableOpacity onPress={() => addBag()}>
                                <Text {...Ar21Sb} color={mainColor}>
                                    Add
                                </Text>
                            </TouchableOpacity>
                        </Box>
                    )}
                    {
                        <Box justifyContent={'center'} alignItems={'center'} mt={SizePixel(16.28)}>
                            <Button onPress={() => setWebOpen(true)} bg={'#00ff0000'} _pressed={{ opacity: 0.5, bg: '#00ff0000' }}>
                                <Text
                                    underline
                                    color={'black.100'}
                                    fontFamily={'Arch'}
                                    fontWeight={700}
                                    fontSize={SizePixel(14)}
                                    lineHeight={SizePixel(15.23)}
                                >
                                    What is RECAN bag?
                                </Text>
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>

            <WebSheet open={webOpen} setOpen={setWebOpen} url={'https://recan.co'} />
        </>
    );
};
