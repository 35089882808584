import React from 'react';
import useWindowDimensions from '../../hooks/useDimensions';

export const MapBackIcon = () => {
    const { sizePix } = useWindowDimensions();

    return (
        <svg width={sizePix(28)} height={sizePix(28)} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.75 1.75C15.75 0.783502 14.9665 0 14 0C13.0335 0 12.25 0.783502 12.25 1.75V12.25H1.75C0.783503 12.25 0 13.0335 0 14C0 14.9665 0.783502 15.75 1.75 15.75H12.25V26.25C12.25 27.2165 13.0335 28 14 28C14.9665 28 15.75 27.2165 15.75 26.25V15.75H26.25C27.2165 15.75 28 14.9665 28 14C28 13.0335 27.2165 12.25 26.25 12.25H15.75V1.75Z"
                fill="black"
            />
            <path
                d="M24.1291 13.9995C24.1291 19.5937 19.594 24.1288 13.9998 24.1288C8.40548 24.1288 3.87042 19.5937 3.87042 13.9995C3.87042 8.40518 8.40548 3.87012 13.9998 3.87012C19.594 3.87012 24.1291 8.40518 24.1291 13.9995Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9998 20.6288C17.661 20.6288 20.6291 17.6607 20.6291 13.9995C20.6291 10.3382 17.661 7.37012 13.9998 7.37012C10.3385 7.37012 7.37042 10.3382 7.37042 13.9995C7.37042 17.6607 10.3385 20.6288 13.9998 20.6288ZM13.9998 24.1288C19.594 24.1288 24.1291 19.5937 24.1291 13.9995C24.1291 8.40518 19.594 3.87012 13.9998 3.87012C8.40548 3.87012 3.87042 8.40518 3.87042 13.9995C3.87042 19.5937 8.40548 24.1288 13.9998 24.1288Z"
                fill="black"
            />
            <path
                d="M17.3516 14.0002C17.3516 15.8514 15.851 17.352 13.9998 17.352C12.1487 17.352 10.648 15.8514 10.648 14.0002C10.648 12.1491 12.1487 10.6484 13.9998 10.6484C15.851 10.6484 17.3516 12.1491 17.3516 14.0002Z"
                fill="black"
            />
        </svg>
    );
};
