import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Pressable, Text } from 'native-base';
import { SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING, MAIN_COLLECTION_PADDING_V2 } from './index';
import { Ar18M, Ar36B } from '../../themes/font.style';
import CalenderInput from '../../components/customInput/calender.input';
import TimeInput from '../../components/customInput/time.input';
import TextAreaInput from '../../components/customInput/textarea.input';
import { Keyboard } from 'react-native';
interface IProps {
    description: string | null;
    setDescription: Dispatch<SetStateAction<string | null>>;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
}
export const DescriptionScreen = ({ description, setDescription, setPageType }: IProps) => {
    useEffect(() => {
        setPageType('Description');
    }, []);
    return (
        <Pressable h={'100%'} onPress={() => Keyboard.dismiss()} px={SizePixel(MAIN_COLLECTION_PADDING)} mt={SizePixel(64.37)}>
            <Box mb={SizePixel(6)}>
                <Text {...Ar36B} color={'black.100'}>
                    Description
                </Text>
                <Text {...Ar18M} color={'gray.300'} mt={SizePixel(12)} lineHeight={SizePixel(22.86)}>
                    Let us know your secure bags location (Please be specific)
                </Text>
            </Box>
            <Box mt={SizePixel(25)}>
                <TextAreaInput
                    onChangeText={setDescription}
                    placeholder={'e.g. Outside the front door / garage door\n'}
                    value={description}
                />
            </Box>
        </Pressable>
    );
};
