import React from 'react';
import { Box, Button, Center, Image, Modal, Text, VStack } from 'native-base';
import { Ar17RBlack, Ar18M, Ar25SbBlack } from '../../../themes/font.style';
import { Img } from 'react-image';
import { useColors } from '../../../hooks/useColors';
import useWindowDimensions from '../../../hooks/useDimensions';
import { FontPixel } from '../../../components/typographys/font.pixel';
interface Props {
    open: boolean;
    image?: any;
    onClose: any;
    onHandler: { text: string; onPress: any; color: any }[];
    title?: any;
    content?: any;
}

const ModalWrapper = ({ open, image, onClose, onHandler, title, content }: Props) => {
    const modalButtonWidth = () => {
        const value = onHandler.length;
        switch (value) {
            case 1:
                return '100%';
            case 2:
                return '50%';
            default:
                return '100%';
        }
    };
    const { mainColor } = useColors();
    const { sizePix } = useWindowDimensions();
    return (
        <Modal
            isOpen={open}
            onClose={() => {
                onClose();
            }}
        >
            <Modal.Content maxWidth={sizePix(400)}>
                <Modal.Body>
                    <Center my={sizePix(30)}>
                        {image && (
                            <Img
                                width={sizePix(143)}
                                height={sizePix(143)}
                                style={{ objectFit: 'contain' }}
                                src={image}
                                alt={'modalImage'}
                            />
                        )}
                        <VStack space={2} mt={4} alignItems={'center'}>
                            {title && (
                                <FontPixel textAlign={'center'} {...Ar25SbBlack} mb={'10px'}>
                                    {title}
                                </FontPixel>
                            )}
                            {content && (
                                <FontPixel textAlign={'center'} {...Ar17RBlack}>
                                    {content}
                                </FontPixel>
                            )}
                        </VStack>
                    </Center>
                </Modal.Body>
                <Modal.Footer p={0} bottom={-5}>
                    <Button.Group space={0} width={'100%'}>
                        {onHandler.map((v, i) => (
                            <Button
                                h={sizePix(74)}
                                bg={v.color}
                                borderRadius={0}
                                w={modalButtonWidth()}
                                variant="basicButton"
                                colorScheme="blueGray"
                                onPress={() => {
                                    v.onPress();
                                }}
                            >
                                <FontPixel {...Ar25SbBlack} color={'white.100'}>
                                    {v.text}
                                </FontPixel>
                            </Button>
                        ))}
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    );
};

export default ModalWrapper;
