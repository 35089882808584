import React, { useEffect, useState } from 'react';
import { Dimensions, ImageBackground, Linking, StatusBarIOS } from 'react-native';
import { VStack, Text, Box, Center, Image, ScrollView, Fab, Icon, Button, Pressable, useTheme, StatusBar } from 'native-base';
import DefaultHeader from '../header/header.default';
import MainHeader from '../header/header.main';
// import CustomTabView from '../../components/tab.view';
// import InfoModal from './confirm/info.modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import useAxiosServices from '../../hooks/axiosHooks';
import { collectionTypes } from '../../@types/collection.types';
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { Ar17M, Ar18M, Ar22M, La56Sb } from '../../themes/font.style';
import WebSheet from '../../components/bottomSheet/web.sheet';
import { useNavigate } from 'react-router-dom';
import TabView from '../../components/tab.view';
import styled from 'styled-components';
import { Img } from 'react-image';
import BackGroundImageSource from '../../assets/background/background1.png';
import MoneyFlow from '../../assets/icons/moneyFlow.png';
import BackgrounCode from '../../assets/background/backgroun.code';
import Sidebar from 'react-sidebar';
import SideBarUser from './sideBar.user';
import { MAXWIDTH } from '../../themes';
import { useColors } from '../../hooks/useColors';
import { InitHeightSizeScreen, InitWidthSizeScreen, SizePixel } from '../../utils/pixcel.convert';
import MoneyFlow2 from '../../assets/icons/moneyFlow2.png';
import BusinessBackGround from '../../assets/background/background2.png';
import useWindowDimensions from '../../hooks/useDimensions';
import { LongButton } from '../../components/buttons/long.button';
import { FontPixel } from '../../components/typographys/font.pixel';

const { width, height } = Dimensions.get('window');

const BackGroundGrdiant = styled.div`
    width: 100%;
    height: 600px;
    background: radial-gradient(72.78% 51.76% at 50% 31.1%, #2795b7 0%, rgba(28, 110, 186, 0) 100%),
        linear-gradient(
            180deg,
            rgba(66, 188, 152, 1) 12.26%,
            rgba(46, 178, 183, 1) 23.2%,
            #1799d3 41.95%,
            rgba(22, 166, 218, 1) 34.66%,
            rgba(255, 255, 255, 0) 72.16%
        );
`;

const DashboardUser = () => {
    const theme = useTheme();
    const { mainColor, colorScheme } = useColors();
    const user = useSelector((state: RootState) => state.auth.user);
    const [list, setList] = useState<collectionTypes[]>([]);
    const backgroundSource = user?.userType === 1 ? BackGroundImageSource : BusinessBackGround;
    const moneyImage = user?.userType === 1 ? MoneyFlow : MoneyFlow2;
    const navigation = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();
    const [openInfo, setOpenInfo] = useState(false);
    const [credit, setCredit] = useState(10);
    const [loading, setLoading] = useState(true);
    const [init, setInit] = useState(false);
    const [rData, setRdata] = useState({ one: 0, two: 0, three: 0, four: 0 });

    const openCollection = () => {
        const check = user?.userType === 1 ? user?.profileCheck === 1 : user?.businessCheck === 1;
        if (check && user?.addressCheck === 1) {
            navigation('/mainCollection' as never);
        } else {
            setOpenInfo(true);
        }
    };

    useEffect(() => {
        getList();
        getListStat();
    }, [navigation, user]);

    const getList = async () => {
        try {
            const api = await axiosService.post('/pick/app/list');
            const { status, list, reportData, credit } = api.data;
            console.log(list);
            if (status) {
                console.log('REPORT', reportData);
                setCredit(credit);
                setList([...list]);
                setRdata({ ...reportData });
            }
        } catch (e) {
            console.log('error', e);
        } finally {
            setRefreshing(false);

            setLoading(false);
        }
    };

    const getListStat = async () => {
        try {
            await axiosService.post('/pick/app/list');
        } catch (e) {
        } finally {
        }
    };
    console.log('init', init);
    const [refreshing, setRefreshing] = useState(false);
    const onRefresh = async () => {
        setRefreshing(true);
        await getList();
    };
    const [openLink, setOpenLink] = useState(false);
    const [graView, setGraView] = useState(0);

    // const userProfileAddressCheck = () => {
    //     const check = user?.userType === 1 ? user?.profileCheck === 1 : user?.businessCheck === 1;
    //     const addressCheck = user?.addressCheck === 1;
    //     setTimeout(() => {
    //         if (!check) {
    //             navigation('ProfileUser' as never, { type: 'user' } as never);
    //             return;
    //         }
    //         if (!addressCheck) {
    //             navigation('Address' as never);
    //             return;
    //         }
    //     }, 10);
    // };
    const [sideOpen, setSideOpen] = useState(false);
    const { height, sizePix } = useWindowDimensions();
    return (
        <>
            <Box w={'100vw'} h={height}>
                <Img src={backgroundSource} width={'100%'} style={{ zIndex: 1, position: 'absolute', height: '50vh' }} />
                <Box flex={1} alignItems={'center'} w={'100%'} h={'100vh'} bgSize={'cover'} zIndex={2}>
                    <Box safeArea flex={1} zIndex={2} h={'100vh'} bg={'rgba(255,255,255,0)'} width={'100%'} maxW={InitWidthSizeScreen}>
                        <MainHeader navigation={navigation} setOpenLink={setOpenLink} setSideOpen={setSideOpen} />
                        <Box alignItems={'center'} mt={sizePix(39.32)}>
                            <FontPixel {...La56Sb} color={'white.100'} lineHeight={sizePix(67.2)}>
                                ${`${user?.credit}`}
                            </FontPixel>
                            <FontPixel {...Ar18M} color={'gray.300'} height={sizePix(31)}>
                                Credit Balance
                            </FontPixel>
                        </Box>
                        <Box alignItems={'center'} mt={sizePix(36)} mb={sizePix(41)} height={sizePix(38)}>
                            <Pressable _pressed={{ opacity: 0.5 }} onPress={() => navigation('/redeemMain' as never)}>
                                <Box alignItems={'center'} flexDirection={'row'}>
                                    <Img width={SizePixel(35)} height={SizePixel(35)} src={moneyImage} alt={'moneyFloew'} />
                                    <FontPixel {...Ar17M} color={mainColor} ml={3}>
                                        Redeem
                                    </FontPixel>
                                </Box>
                            </Pressable>
                        </Box>
                        <Box flex={1} mx={sizePix(35)} w={sizePix(358)} alignSelf={'center'}>
                            <TabView list={list} refreshing={refreshing} onRefresh={onRefresh} setView={setGraView} rData={rData} />
                        </Box>
                        <Box
                            zIndex={11}
                            alignSelf={'center'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            w={sizePix(358)}
                            position={'absolute'}
                            bottom={sizePix(30)}
                            height={sizePix(58.44)}
                            bg={'#00ff0000'}
                        >
                            <LongButton zIndex={30} onPress={() => openCollection()} shadow={8}>
                                <FontPixel {...Ar22M} color={'white.100'}>
                                    Book a Collection
                                </FontPixel>
                            </LongButton>
                        </Box>

                        {/*{graView === 1 && (*/}
                        {/*    <LinearGradient*/}
                        {/*        style={{ zIndex: 2, width: 500, height: 200, position: 'absolute', bottom: 0 }}*/}
                        {/*        colors={['rgba(255,255,255,0)', 'rgba(248,248,248,0.67)', 'rgba(248,248,248,0.88)', 'rgb(255,255,255)']}*/}
                        {/*    ></LinearGradient>*/}
                        {/*)}*/}
                    </Box>
                </Box>
            </Box>

            {/*<InfoModal open={openInfo} setOpen={setOpenInfo} />*/}
            <WebSheet open={openLink} setOpen={setOpenLink} url={'https://recan.co'} />
            <SideBarUser open={sideOpen} setOpen={setSideOpen} />
        </>
    );
};

export default DashboardUser;
