import React, { useState } from 'react';
import { Box, Button, Center, Image, Modal, Pressable, Text, VStack } from 'native-base';
import { Ar17B, Ar17RBlack, Ar18M, Ar22M, Ar25SbBlack } from '../../../themes/font.style';
import WebSheet from '../../../components/bottomSheet/web.sheet';
import { SizePixel } from '../../../utils/pixcel.convert';

interface Props {
    open: boolean;
    image?: any;
    onClose: any;
    onHandler: { text: string; onPress: any; color: any }[];
    title?: any;
    content?: any;
}

const ModalWrapper3 = ({ open, image, onClose, onHandler, title, content }: Props) => {
    const modalButtonWidth = () => {
        const value = onHandler.length;
        switch (value) {
            case 1:
                return '100%';
            default:
                return '100%';
        }
    };
    const [webOpen, setWepOpen] = useState(false);
    const openWeb = () => {
        onClose();
        setWepOpen(true);
    };

    return (
        <>
            <Modal
                isOpen={open}
                onClose={() => {
                    onClose();
                }}
            >
                <Modal.Content maxWidth="400px" w={SizePixel(325)}>
                    <Modal.Body>
                        <Box mt={SizePixel(47.62)} alignItems={'center'}>
                            {image && (
                                <Box mb={SizePixel(27.17)} w={SizePixel(131)} h={SizePixel(131)}>
                                    {image}
                                </Box>
                            )}
                            <VStack alignItems={'center'}>
                                {title && (
                                    <Text textAlign={'center'} {...Ar25SbBlack} mb={SizePixel(27.17)} lineHeight={SizePixel(27.2)}>
                                        {title}
                                    </Text>
                                )}
                                {content && (
                                    <Text textAlign={'center'} {...Ar17RBlack} mb={SizePixel(27.17)}>
                                        {content}
                                    </Text>
                                )}
                            </VStack>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer p={0}>
                        <Box w={'100%'}>
                            {onHandler.map((v, i) => (
                                <Button
                                    h={SizePixel(74)}
                                    bg={v.color}
                                    borderRadius={0}
                                    w={modalButtonWidth()}
                                    variant="basicButton"
                                    colorScheme="blueGray"
                                    onPress={() => {
                                        v.onPress();
                                    }}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    <Text {...Ar22M} color={'white.100'} lineHeight={SizePixel(23.94)}>
                                        {v.text}
                                    </Text>
                                </Button>
                            ))}
                        </Box>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default ModalWrapper3;
