import React, { Dispatch, SetStateAction } from 'react';
import { IPhotoListTypes } from '../../@types/collection.types';
import { Modal, TouchableOpacity } from 'react-native';

import { Box, Pressable, Text } from 'native-base';
import { SizePixel } from '../../utils/pixcel.convert';
import { Ar21Sb } from '../../themes/font.style';
import CloseIcon from '../../assets/icons/close.icon';
import ReactSimpleImageViewer from '../../components/modal/image.modal';

interface IProps {
    view: boolean;
    setView: Dispatch<SetStateAction<boolean>>;
    photoList: IPhotoListTypes[];
    removeIndex: (index) => void;
    index: number;
}

export const PhotoModal = ({ view, setView, photoList, removeIndex, index }: IProps) => {
    const images = photoList.map((v) => v.url);
    return (
        <Modal visible={view} transparent={true}>
            <div />
            <ReactSimpleImageViewer
                removeIndex={() => removeIndex(index)}
                src={images}
                currentIndex={index}
                disableScroll={true}
                onClose={() => setView(false)}
            />
            {/*<ImageViewer*/}
            {/*    index={index}*/}
            {/*    imageUrls={images}*/}
            {/*    renderHeader={(currentIndex) => (*/}
            {/*        <Box*/}
            {/*            w={'100%'}*/}
            {/*            bg={'black.100'}*/}
            {/*            position={'absolute'}*/}
            {/*            pt={SizePixel(20)}*/}
            {/*            px={SizePixel(32)}*/}
            {/*            flexDirection={'row'}*/}
            {/*            justifyContent={'space-between'}*/}
            {/*            alignItems={'center'}*/}
            {/*            zIndex={10}*/}
            {/*            h={SizePixel(74)}*/}
            {/*        >*/}
            {/*            <TouchableOpacity*/}
            {/*                onPress={() => {*/}
            {/*                    removeIndex(currentIndex);*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <Text {...Ar21Sb} color={'white.100'}>*/}
            {/*                    Remove*/}
            {/*                </Text>*/}
            {/*            </TouchableOpacity>*/}
            {/*            <Pressable*/}
            {/*                onPress={() => {*/}
            {/*                    setView(false);*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <CloseIcon color={'#FFF'} />*/}
            {/*            </Pressable>*/}
            {/*        </Box>*/}
            {/*    )}*/}
            {/*    renderIndicator={(currentIndex) => (*/}
            {/*        <Box position={'absolute'} bottom={SizePixel(100)} w={'100%'} flexDirection={'row'} justifyContent={'center'}>*/}
            {/*            {photoList.map((v, i) => (*/}
            {/*                <Box*/}
            {/*                    style={{*/}
            {/*                        backgroundColor: i + 1 === (currentIndex as number) ? '#FFFFFF' : '#4C4C4C',*/}
            {/*                        width: 8,*/}
            {/*                        height: 8,*/}
            {/*                        borderRadius: 4,*/}
            {/*                        marginLeft: 3,*/}
            {/*                        marginRight: 3,*/}
            {/*                        marginTop: 3,*/}
            {/*                        marginBottom: 3,*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            ))}*/}
            {/*        </Box>*/}
            {/*    )}*/}
            {/*/>*/}
        </Modal>
    );
};
