import React from 'react';
import { View, Text, Box, Center, Image, Button, VStack } from 'native-base';
import CollectionHeader from '../../header/header.collection';
import { Ar18M, Ar22M } from '../../../themes/font.style';

import colorLogo from '../../../assets/logo/recan-colour-logo.png';
import CheckImage from '../../../assets/icons/checked.png';
import { useNavigate } from 'react-router-dom';
import { Img } from 'react-image';
const DoneView = () => {
    const navigation = useNavigate();
    return (
        <>
            <CollectionHeader navigation={navigation} />
            <Box bg={'white.100'} flex={1} safeAreaBottom p={4}>
                <Box alignItems={'center'}>
                    <Img width={'123px'} height={'43px'} style={{ objectFit: 'contain' }} src={colorLogo} alt={'colorlogo'} />
                </Box>
                <Box flex={1} justifyContent={'center'}>
                    <Box mt={'115px'} p={4} flex={1}>
                        <VStack justifyContent={'center'} alignItems={'center'}>
                            <Img
                                width={'195px'}
                                height={'195px'}
                                style={{ objectFit: 'contain', marginBottom: 10 }}
                                src={CheckImage}
                                alt={'houseImage'}
                            />
                            <Box mt={'30px'} justifyContent={'center'} alignItems={'center'}>
                                <Text fontFamily={'Arch'} fontWeight={700} fontSize={'26px'}>
                                    Great!
                                </Text>
                            </Box>
                            <Box mt={'13px'} justifyContent={'center'} alignItems={'center'}>
                                <Text textAlign={'center'} {...Ar18M} color={'gray.200'}>
                                    You are ready to book your collection
                                </Text>
                            </Box>
                        </VStack>
                    </Box>
                    <Box flexDirection={'row'} w={'100%'} justifyContent={'center'}>
                        <Button
                            shadow={8}
                            onPress={() => navigation('/mainCollection' as never)}
                            width={'100%'}
                            variant={'basicButton'}
                            bg={'blue.200'}
                        >
                            <Text {...Ar22M} color={'white.100'}>
                                Next
                            </Text>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DoneView;
