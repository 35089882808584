import styled from '@emotion/styled';
import safeGet from './safeGet';

const tabSizes = {
    large: {
        height: '60px',
        fontSize: '2em',
    },
    medium: { height: '40px', fontSize: '1em' },
    small: { height: '30px', fontSize: '1em' },
};

export const defaultTabBarStyle = {
    fontFamily: 'sans-serif',
    barColor: '#8c7ae6',
    headerTextColor: '#fff',
    selectedHeaderTextColor: '#e6d5ec',
    alignHeader: 'center',
    showInkBar: true,
    inkBarColor: 'white',
    activeInkBarColor: '#82589F',
    size: 'medium',
    tabPosition: 'top',
    justifyTabs: 'space-evenly',
};

export type TabBarStyle = {
    fontFamily?: string;
    barColor?: string;
    headerTextColor?: string;
    selectedHeaderTextColor?: string;
    alignHeader?: 'left' | 'center' | 'right';
    showInkBar?: boolean;
    inkBarColor?: string;
    activeInkBarColor?: string;
    size?: 'large' | 'medium' | 'small';
    tabPosition?: 'top' | 'bottom';
    justifyTabs?: 'start' | 'space-evenly';
    height?: number;
};

export interface ITabsList {
    styleProps?: TabBarStyle;
    // tabBarCSS: string;
}
interface ITab {
    isSelected: boolean;
    width: number;
    styleProps: TabBarStyle;
    tabItemCSS: string;
}
interface IStyleProps {
    styleProps?: TabBarStyle;
    tabBarCSS?: string;
}
interface ITabGroup {
    styleProps: TabBarStyle;
}
interface ITabHighlight {
    height: number;
    selectedTab: number;
    tabCount: number;
    styleProps: TabBarStyle;
    top: number;
}
export const TabPane = styled.div<IStyleProps>`
    background-color: ${({ styleProps }) => safeGet(styleProps, 'barColor', defaultTabBarStyle.barColor)};
    height: ${({ styleProps }) => `${styleProps?.height}px`};
    font-size: ${({ styleProps }) => tabSizes[safeGet(styleProps, 'size', defaultTabBarStyle.size)].fontSize};
    border-radius: 100px;
    border-bottom: ${({ styleProps }) =>
        safeGet(styleProps, 'showInkBar', true)
            ? `${safeGet(styleProps, 'inkBarColor', defaultTabBarStyle.inkBarColor)} solid 1px`
            : 'none'};
    ${({ tabBarCSS }) => tabBarCSS};
`;
export const ViewPane = styled.div`
    height: 100%;
`;

export const TabsList = styled.section<ITabsList>`
    width: ${(tabGroupProps) => safeGet(tabGroupProps, 'styleProps.width', 'inherit')};
    display: flex;
    height: inherit;
    text-align: center;
`;

export const TabGroupContainer = styled.div<ITabGroup>`
    overflow-x: hidden;
    overflow-y: hidden;
    user-select: none;
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    flex-flow: ${({ styleProps }) => (styleProps.tabPosition === 'bottom' ? 'column-reverse' : 'column')};
`;

export const TabItem = styled.section<ITab>`
    z-index: 1;
    color: ${({ isSelected, styleProps }) =>
        isSelected
            ? safeGet(styleProps, 'selectedHeaderTextColor', defaultTabBarStyle.selectedHeaderTextColor)
            : safeGet(styleProps, 'headerTextColor', defaultTabBarStyle.headerTextColor)};
    font-family: ${({ styleProps }) => safeGet(styleProps, 'fontFamily', defaultTabBarStyle.fontFamily)};
    width: ${({ width, styleProps }) =>
        safeGet(styleProps, 'justifyTabs', defaultTabBarStyle.justifyTabs) === 'space-evenly' ? `${width}%` : 'inherit'};
    height: inherit;
    padding: ${({ styleProps }) =>
        safeGet(styleProps, 'justifyTabs', defaultTabBarStyle.justifyTabs) === 'space-evenly' ? '0' : ' 0 20px'};

    cursor: pointer;
    line-height: ${({ styleProps }) => `${styleProps.height}px`};
    text-align: ${({ styleProps }) => styleProps.alignHeader || defaultTabBarStyle.alignHeader};
    ${({ tabItemCSS }) => tabItemCSS}
`;

export const TabSelector = styled.div<ITabHighlight>`
    z-index: 0;
    margin: 0px 2px 0px 2px;
    border-radius: 100px;
    top: ${(props) => `${props.top}px`};
    width: ${(props) => `${100 / props.tabCount}%`};
    height: ${(props) => `${props.height - 2}px`};
    position: absolute;
    background-color: ${({ styleProps }) =>
        safeGet(styleProps, 'showInkBar', true)
            ? `${safeGet(styleProps, 'activeInkBarColor', defaultTabBarStyle.activeInkBarColor)}`
            : 'transfer'};
    border: ${({ styleProps }) =>
        safeGet(styleProps, 'showInkBar', true)
            ? `${safeGet(styleProps, 'activeInkBarColor', defaultTabBarStyle.activeInkBarColor)} solid 1px`
            : 'none'};
    margin-left: ${({ selectedTab, tabCount }) => `${(100 / tabCount) * selectedTab + (selectedTab % 2 === 0 ? 1 : -1)}%`};
    transition: 0.1s ease-in-out;
    /* display:none; */
`;

/* margin-left: ${props =>
    `${(props.selectedTab / props.tabCount) * 100}%`}; */
