import React, { ChangeEvent, useRef, useState } from 'react';

interface Props {
    type: 'camera' | 'pick';
    onHandler: (type: 'camera' | 'pick', file: any) => void;
}
import LibImage from '../../assets/icons/photo.png';
import CameraImage from '../../assets/icons/camera.png';
import { Button } from 'native-base';
import { Img } from 'react-image';
const PhotoInput = ({ type, onHandler }: Props) => {
    const photoInputProgress = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e);
        e.stopPropagation();
        e.preventDefault();
        // @ts-ignore
        const file = e.target?.files[0];
        const objectURL = URL.createObjectURL(file);

        console.log(file);
        console.log('file', objectURL);
        onHandler(type, file);
        // @ts-ignore
        e.currentTarget.value = null;
    };
    const inputRef = useRef<HTMLInputElement>(null);

    const inputOpen = () => {
        // @ts-ignore
        inputRef.current?.click();
        // @ts-ignore
        inputRef.current.value = null;
    };
    const [temp, setTemp] = useState(null);

    return (
        <>
            <Button
                mr={type === 'camera' ? 5 : 0}
                onPress={() => inputOpen()}
                variant={'basicButton'}
                w={'100px'}
                h={'50px'}
                borderColor={'black.100'}
                borderWidth={'1'}
            >
                <Img style={{ width: 33, height: 33, objectFit: 'contain' }} src={type === 'camera' ? CameraImage : LibImage} />
            </Button>
            <input
                id={`input_my_${type}`}
                accept="image/*"
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={(e) => photoInputProgress(e)}
            />
        </>
    );
};

export default PhotoInput;
