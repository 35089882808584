import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING } from './index';
import { Box, ScrollView, Text } from 'native-base';
import { Ar18M, Ar36B } from '../../themes/font.style';
import { IProductsTypes } from './temp.const';
import { ProductsBox } from './products.box';
import { BottomView } from './shop.sheet';
import { ProductDetail } from './product.detail';

interface IProps {
    products: IProductsTypes;
    updateProduct: (type: 'up' | 'down' | 'step', index, value?: number) => void;
    removeProduct: (index) => void;
    handleOpenSheet: (index: number) => void;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
    modify: boolean;
}

export const ShopScreen = ({ modify, setPageType, products, updateProduct, removeProduct, handleOpenSheet }: IProps) => {
    useEffect(() => {
        setPageType('Shop');
    }, []);
    return (
        <>
            <Box px={SizePixel(12)} mt={SizePixel(64.37)}>
                <Box mb={SizePixel(12.37)}>
                    <Text {...Ar36B} color={'black.100'}>
                        Do you need
                    </Text>
                    <Text {...Ar36B} color={'black.100'}>
                        RECAN bags?
                    </Text>
                    <Text {...Ar18M} color={'gray.200'} mt={SizePixel(12)} lineHeight={SizePixel(22.86)}>
                        Order extra RECAN bags for your next collection
                    </Text>
                </Box>
                <ScrollView showsVerticalScrollIndicator={false} mt={SizePixel(21.27 - 9)} maxH={'65vh'}>
                    {products.map((v, index) => (
                        <ProductsBox
                            modify={modify}
                            index={index}
                            products={v}
                            key={v.key as string}
                            updateProduct={updateProduct}
                            removeProduct={removeProduct}
                            handleOpenSheet={handleOpenSheet}
                        />
                    ))}
                    <Box h={SizePixel(100)} />
                </ScrollView>
            </Box>
        </>
    );
};
