import { useNavigate, useSearchParams } from 'react-router-dom';

// project imports
import useAuth from '../hooks/useAuth';
import { GuardProps } from './auth.guard';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const { isLogin } = useAuth();
    const navigate = useNavigate();
    const [query] = useSearchParams();
    console.log(query.get('setting'));
    useEffect(() => {
        if (!isLogin) {
            navigate('/login', { replace: true });
        }
        // if (query.get('setting')) {
        //     if (query.get('setting') === 'change') {
        //         navigate('/accountChange', { replace: true });
        //     }
        // } else {
        //     if (!isLogin) {
        //         navigate('/login', { replace: true });
        //     }
        // }
    }, [isLogin]);

    return children;
};

export default GuestGuard;
