import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Image, ScrollView, Text, useToast } from 'native-base';
import { Ar22M, Ar28Bold, Ar28BoldBlack } from '../../themes/font.style';
import { Img } from 'react-image';
import { useLocation, useNavigate } from 'react-router-dom';
import Checked from '../../assets/icons/checked.png';
import { PageSetting } from '../../utils/pageSetting';
import { FontPixel } from '../../components/typographys/font.pixel';
import { LongButton } from '../../components/buttons/long.button';

import Logo from '../../assets/logo/recan-colour-logo.png';

const EmailComplete = () => {
    const toast = useToast();
    const navigation = useNavigate();
    const route: any = useLocation();
    const state: { code: any; email: any; accountType: any; password: any } = route.state;
    const [code, setCode] = useState('');
    const [fulfilled, setFulfilled] = useState(false);

    const verifyHandler = () => {
        setFulfilled(code.length === 6);
    };

    useEffect(() => {
        verifyHandler();
    }, [code]);

    return (
        <Box alignItems={'center'} bg={'white.100'} safeArea flexGrow={1} justifyContent={'space-between'}>
            <Box {...PageSetting} pt={'30px'}>
                <Img src={Logo} alt="Alternate Text" width={'123px'} height={'34px'} style={{ alignSelf: 'center' }} />
                <ScrollView>
                    <Box my={10} pt={10} alignItems={'center'} justifyContent={'center'}>
                        <Img src={Checked} alt="Alternate Text" width={170} height={170} />
                    </Box>
                    <Box my={10} alignItems={'center'} justifyContent={'center'}>
                        <Text mb={3} {...Ar28Bold} color={'black.100'}>
                            Successfully verified!
                        </Text>
                    </Box>
                </ScrollView>
                <Flex mb={'70px'} w={'100%'}>
                    <Box justifyContent={'flex-end'}>
                        <Box justifyContent={'center'} alignItems={'center'} w={'100%'}>
                            <LongButton variant={'shadowBasic'} onPress={() => navigation('/login' as never)}>
                                <FontPixel {...Ar22M} color={'white.100'}>
                                    OK
                                </FontPixel>
                            </LongButton>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default EmailComplete;
