import React, { useRef } from 'react';
import { useColors } from '../../hooks/useColors';
import { Box, HStack, Pressable, Text } from 'native-base';
import { TouchableOpacity } from 'react-native';
import BackIcon from '../../assets/icons/back.icon';
import { Ar18SbBlack, Ar21Sb } from '../../themes/font.style';
import CloseIcon from '../../assets/icons/close.icon';
import { SizePixel } from '../../utils/pixcel.convert';
import { SaveAndExitButton } from './saveAndExit.button';
import { useDispatch } from 'react-redux';
import { collectionStepInitAction } from '../../store/commonReducer';
import { useNavigate } from 'react-router-dom';
interface Props {
    optional?: any;
    backOption?: () => void;
    cancelOption?: () => void;
    modalHandler?: (type: 'ok' | 'close' | 'complete') => void;
    step?: number;
    maxStep: number;
    modify: boolean;
    propsRef: any;
}

export const CollectionHeader = ({ propsRef, step, maxStep, modify, optional, backOption, cancelOption, modalHandler }: Props) => {
    const { mainColor } = useColors();
    const finishRefry = step === maxStep;
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const rr = useRef();
    console.log('RR', rr);
    return (
        <Box bg={'white.100'} mt={SizePixel(22)} px={SizePixel(12)} ref={propsRef}>
            <Box bg={'white.100'}>
                <HStack alignItems={'center'} justifyContent={'space-between'}>
                    <Pressable
                        hitSlop={{ top: 20, left: 20, right: 20, bottom: 20 }}
                        onPress={() => (backOption ? backOption() : navigation(-1))}
                    >
                        <BackIcon />
                    </Pressable>
                    {finishRefry ? (
                        <Box flexDirection={'row'} position={'absolute'} right={0}>
                            {modify && (
                                <Pressable
                                    onPress={() => {
                                        if (modify) {
                                            dispatch(collectionStepInitAction());
                                            if (cancelOption) {
                                                cancelOption();
                                            }
                                        } else {
                                            if (cancelOption) {
                                                cancelOption();
                                            }
                                        }
                                    }}
                                >
                                    <Text {...Ar21Sb} color={mainColor}>
                                        Cancel
                                    </Text>
                                </Pressable>
                            )}

                            <Pressable
                                onPress={() => {
                                    if (modify) {
                                        dispatch(collectionStepInitAction());
                                    }
                                    navigation(-1);
                                }}
                                ml={SizePixel(27.98)}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <CloseIcon />
                            </Pressable>
                        </Box>
                    ) : (
                        <Pressable
                            onPress={() => {
                                if (modify) {
                                    dispatch(collectionStepInitAction());
                                }
                                navigation(-1);
                            }}
                        >
                            <SaveAndExitButton color={'black'} />
                        </Pressable>
                    )}
                </HStack>
            </Box>
        </Box>
    );
};
