import React from 'react';
import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { SizePixel } from '../../utils/pixcel.convert';
import { useColors } from '../../hooks/useColors';

export const PlainOneIcon = () => {
    return (
        <Svg width={SizePixel(80)} height={SizePixel(80)} viewBox="0 0 90 90" fill="none">
            <G clipPath="url(#clip0_980_9471)">
                <Path
                    d="M75.7213 56.0473C75.4489 55.0303 74.4033 54.4267 73.3862 54.6992L72.2529 55.0028L79.1424 43.07C83.0457 36.3091 80.721 27.6333 73.9602 23.73L73.9585 23.729C71.718 22.552 69.9137 25.6815 72.0536 27.0324C76.9934 29.8844 78.692 36.2236 75.84 41.1633L68.9506 53.0962L68.6469 51.9631C68.3745 50.946 67.3289 50.3425 66.3118 50.6149C65.2947 50.8875 64.6911 51.9329 64.9637 52.95L66.4586 58.5294C66.7311 59.5466 67.7776 60.1499 68.7937 59.8775L74.3732 58.3826C75.3903 58.1101 75.994 57.0645 75.7213 56.0473V56.0473Z"
                    fill="black"
                />
                <Path
                    d="M18.5636 62.4907C13.6238 59.6387 11.9253 53.2996 14.7773 48.3598L21.6667 36.4269L21.9703 37.56C22.2428 38.5771 23.2883 39.1806 24.3054 38.9082C25.3226 38.6357 25.9261 37.5902 25.6536 36.5731L24.1586 30.9939C23.8861 29.9766 22.8396 29.3733 21.8235 29.6457L16.2441 31.1407C15.227 31.4131 14.6234 32.4586 14.8959 33.4758C15.1684 34.4929 16.214 35.0964 17.231 34.8239L18.3643 34.5203L11.4749 46.4532C7.57153 53.214 9.8963 61.8898 16.657 65.7931L16.6588 65.7941C18.9001 66.9713 20.703 63.8406 18.5636 62.4907V62.4907Z"
                    fill="black"
                />
                <Path
                    d="M61.2724 33.1777L64.5188 27.5549C65.0452 26.643 64.7328 25.4769 63.8209 24.9504L53.2093 18.8238C52.2974 18.2973 51.1313 18.6098 50.6048 19.5217L47.3585 25.1445C42.5665 23.5532 36.2711 27.7786 37.5034 33.1814C34.5597 34.0132 32.6678 37.2917 33.419 40.2558C31.963 40.6992 30.7477 41.6619 29.9825 42.9874L23.8559 53.599C21.6411 57.4351 22.9601 62.3578 26.7962 64.5725L37.4078 70.6992C41.244 72.9139 46.1666 71.5949 48.3814 67.7588L54.508 57.1472C55.2734 55.8215 55.4994 54.2873 55.1554 52.8053C58.0987 51.9731 59.991 48.6957 59.2398 45.7309C64.536 44.095 65.0461 36.533 61.2724 33.1777V33.1777ZM52.9539 23.0795L60.2631 27.2995L58.0853 31.0715L50.776 26.8515L52.9539 23.0795ZM45.0791 65.8522C43.9156 67.8673 41.3296 68.5602 39.3145 67.3968L28.7029 61.2702C26.6878 60.1068 25.9948 57.5207 27.1582 55.5056L33.2848 44.894C33.892 43.8423 35.228 43.5012 36.2597 44.0969L50.4085 52.2657C51.4384 52.8603 51.8137 54.1874 51.2057 55.2406L45.0791 65.8522ZM52.3152 48.9634L38.1663 40.7945C35.7174 39.2463 37.7915 35.6781 40.3441 37.0224L54.4929 45.1912C56.932 46.7283 54.8828 50.31 52.3152 48.9634V48.9634ZM56.3996 41.8889L42.2507 33.7201C38.9854 31.4428 43.6983 27.0647 47.2181 29.2005L57.8297 35.3271C61.4382 37.3057 60.0061 43.5782 56.3996 41.8889Z"
                    fill="black"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_980_9471">
                    <Rect width="65.0793" height="65.0793" fill="white" transform="translate(33.3984 0.311523) rotate(30)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export const PlainTwoIcon = () => {
    return (
        <Svg width={SizePixel(80)} height={SizePixel(80)} viewBox="0 0 81 81" fill="none">
            <G clip-path="url(#clip0_980_9477)">
                <Path
                    d="M61.1694 66.6185L50.9348 42.7401C50.6657 42.113 50.0489 41.7064 49.367 41.7064H42.867C45.4977 37.1105 47.6611 32.0239 47.6611 27.7201C47.6611 5.55681 16.9566 5.28644 16.9566 27.7201C16.9566 32.0239 19.12 37.1105 21.7507 41.7064H15.2507C14.5684 41.7064 13.9515 42.113 13.6829 42.7401L3.44822 66.6185C2.96678 67.7416 3.7916 68.9961 5.01602 68.9961H59.6017C60.8234 68.9961 61.6518 67.7442 61.1694 66.6185V66.6185ZM48.2417 45.1176L53.292 56.8998C53.134 56.9821 36.6967 65.5176 36.579 65.5848H33.0084C32.9182 65.4839 33.6855 66.2552 22.568 55.139L28.7431 51.9787C30.0021 53.5854 30.884 54.6063 31.0216 54.7647C31.7013 55.547 32.9168 55.5457 33.5956 54.7647C33.8811 54.4363 37.3583 50.4073 40.7837 45.1176H48.2417ZM32.3088 14.4162C38.0596 14.4162 44.2494 18.5797 44.2494 27.7201C44.2494 35.4055 35.6343 46.843 32.3088 50.9706C28.9834 46.8425 20.3683 35.4055 20.3683 27.7201C20.3683 18.5302 26.3651 14.4162 32.3088 14.4162ZM16.3756 45.1176C24.6389 45.1176 23.6999 45.119 23.8322 45.115C24.7929 46.5987 25.7576 47.9833 26.655 49.2152L11.2372 57.106L16.3756 45.1176ZM9.13308 62.0151L19.3771 56.7723L28.1909 65.5848H7.6029L9.13308 62.0151ZM43.9724 65.5848L54.6398 60.0438L57.0148 65.5848H43.9724Z"
                    fill="black"
                />
                <Path
                    d="M37.4259 26.3554C37.4259 23.534 35.1301 21.2383 32.3087 21.2383C29.4869 21.2383 27.1912 23.534 27.1912 26.3554C27.1912 29.1768 29.4869 31.4721 32.3087 31.4721C35.1301 31.4721 37.4259 29.1768 37.4259 26.3554ZM30.6029 26.3554C30.6029 25.4146 31.368 24.6496 32.3087 24.6496C33.2491 24.6496 34.0146 25.4146 34.0146 26.3554C34.0146 27.2957 33.2491 28.0608 32.3087 28.0608C31.368 28.0608 30.6029 27.2957 30.6029 26.3554V26.3554Z"
                    fill="black"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_980_9477">
                    <Rect width="58" height="58" fill="white" transform="translate(3.30884 10.9961)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export const CalenderIcon = () => {
    const { mainColor } = useColors();
    return (
        <Svg width={SizePixel(31)} height={SizePixel(31)} viewBox="0 0 31 31" fill="none">
            <G clipPath="url(#clip0_991_3326)">
                <Path
                    d="M11.8541 14.3457H9.35413V16.8457H11.8541V14.3457ZM16.8541 14.3457H14.3541V16.8457H16.8541V14.3457ZM21.8541 14.3457H19.3541V16.8457H21.8541V14.3457ZM24.3541 5.5957H23.1041V3.0957H20.6041V5.5957H10.6041V3.0957H8.10413V5.5957H6.85413C5.46663 5.5957 4.36663 6.7207 4.36663 8.0957L4.35413 25.5957C4.35413 26.9707 5.46663 28.0957 6.85413 28.0957H24.3541C25.7291 28.0957 26.8541 26.9707 26.8541 25.5957V8.0957C26.8541 6.7207 25.7291 5.5957 24.3541 5.5957ZM24.3541 25.5957H6.85413V11.8457H24.3541V25.5957Z"
                    fill={mainColor}
                />
            </G>
            <Defs>
                <ClipPath id="clip0_991_3326">
                    <Rect width="30" height="30" fill="white" transform="translate(0.60376 0.595703)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export const ClockIcon = () => {
    const { mainColor } = useColors();
    return (
        <Svg width={SizePixel(31)} height={SizePixel(31)} viewBox="0 0 31 31" fill="none">
            <G clipPath="url(#clip0_991_7184)">
                <Path
                    d="M15.5917 3.0957C8.69175 3.0957 3.10425 8.6957 3.10425 15.5957C3.10425 22.4957 8.69175 28.0957 15.5917 28.0957C22.5042 28.0957 28.1042 22.4957 28.1042 15.5957C28.1042 8.6957 22.5042 3.0957 15.5917 3.0957ZM15.6042 25.5957C10.0792 25.5957 5.60425 21.1207 5.60425 15.5957C5.60425 10.0707 10.0792 5.5957 15.6042 5.5957C21.1292 5.5957 25.6042 10.0707 25.6042 15.5957C25.6042 21.1207 21.1292 25.5957 15.6042 25.5957Z"
                    fill={mainColor}
                />
                <Path d="M16.2292 9.3457H14.3542V16.8457L20.9167 20.7832L21.8542 19.2457L16.2292 15.9082V9.3457Z" fill={mainColor} />
            </G>
            <Defs>
                <ClipPath id="clip0_991_7184">
                    <Rect width="30" height="30" fill="white" transform="translate(0.60376 0.595703)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export const CollectionCheckCircleIcon = () => {
    const { mainColor } = useColors();
    return (
        <Svg width={SizePixel(29)} height={SizePixel(29)} viewBox="0 0 29 29" fill="none">
            <Circle cx="14.0835" cy="14.5918" r="14" fill={mainColor} />
            <Circle cx="14.0835" cy="14.5918" r="6" fill="white" />
        </Svg>
    );
};

export const CollectionUnCheckCircleIcon = () => {
    const { mainColor } = useColors();
    return (
        <Svg width={SizePixel(29)} height={SizePixel(29)} viewBox="0 0 29 29" fill="none">
            <Circle cx="14.0835" cy="14.0918" r="13.5" fill="white" stroke="black" />
        </Svg>
    );
};
interface IProductMinusAndPlus {
    type: 'p' | 'm';
    color: string;
}
export const ProductMinusAndPlus = ({ type, color }: IProductMinusAndPlus) => {
    if (type === 'p') {
        return (
            <Svg width={SizePixel(15)} height={SizePixel(15)} viewBox="0 0 15 15" fill="none">
                <Path d="M1.93347 7.32715H13.9335" stroke={color} strokeWidth="2" strokeLinecap="round" />
                <Path d="M7.93347 1.32715L7.93347 13.3271" stroke={color} strokeWidth="2" strokeLinecap="round" />
            </Svg>
        );
    } else {
        return (
            <Svg width={SizePixel(15)} height={SizePixel(3)} viewBox="0 0 15 3" fill="none">
                <Path d="M1.93347 1.32715H13.9335" stroke={color} strokeWidth="2" strokeLinecap="round" />
            </Svg>
        );
    }
};

export const ProductMinusAndPlus2 = ({ type, color }: IProductMinusAndPlus) => {
    if (type === 'p') {
        return (
            <Svg width={SizePixel(13)} height={SizePixel(13)} viewBox="0 0 15 15" fill="none">
                <Path d="M1.93347 7.32715H13.9335" stroke={color} strokeWidth="2" strokeLinecap="round" />
                <Path d="M7.93347 1.32715L7.93347 13.3271" stroke={color} strokeWidth="2" strokeLinecap="round" />
            </Svg>
        );
    } else {
        return (
            <Svg width={SizePixel(15)} height={SizePixel(3)} viewBox="0 0 15 3" fill="none">
                <Path d="M1.93347 1.32715H13.9335" stroke={color} strokeWidth="2" strokeLinecap="round" />
            </Svg>
        );
    }
};

export const InfoIcon = () => {
    return (
        <Svg width={SizePixel(36)} height={SizePixel(36)} viewBox="0 0 36 36" fill="none">
            <Path
                d="M18 25.5C18.35 25.5 18.6438 25.3813 18.8813 25.1438C19.1188 24.9063 19.2375 24.6125 19.2375 24.2625C19.2375 23.9125 19.1188 23.6188 18.8813 23.3813C18.6438 23.1438 18.35 23.025 18 23.025C17.65 23.025 17.3563 23.1438 17.1188 23.3813C16.8813 23.6188 16.7625 23.9125 16.7625 24.2625C16.7625 24.6125 16.8813 24.9063 17.1188 25.1438C17.3563 25.3813 17.65 25.5 18 25.5ZM16.9875 19.7625H19.2375V10.275H16.9875V19.7625ZM18 33C15.95 33 14.0125 32.6063 12.1875 31.8188C10.3625 31.0313 8.76875 29.9563 7.40625 28.5938C6.04375 27.2313 4.96875 25.6375 4.18125 23.8125C3.39375 21.9875 3 20.0375 3 17.9625C3 15.9125 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.04375 8.7375 7.40625 7.3875C8.76875 6.0375 10.3625 4.96875 12.1875 4.18125C14.0125 3.39375 15.9625 3 18.0375 3C20.0875 3 22.025 3.39375 23.85 4.18125C25.675 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0313 10.325 31.8188 12.15C32.6063 13.975 33 15.925 33 18C33 20.05 32.6063 21.9875 31.8188 23.8125C31.0313 25.6375 29.9625 27.2313 28.6125 28.5938C27.2625 29.9563 25.675 31.0313 23.85 31.8188C22.025 32.6063 20.075 33 18 33ZM18.0375 30.75C21.5625 30.75 24.5625 29.5063 27.0375 27.0188C29.5125 24.5313 30.75 21.5125 30.75 17.9625C30.75 14.4375 29.5125 11.4375 27.0375 8.9625C24.5625 6.4875 21.55 5.25 18 5.25C14.475 5.25 11.4688 6.4875 8.98125 8.9625C6.49375 11.4375 5.25 14.45 5.25 18C5.25 21.525 6.49375 24.5313 8.98125 27.0188C11.4688 29.5063 14.4875 30.75 18.0375 30.75Z"
                fill="#A6A6A6"
            />
        </Svg>
    );
};
