import React, { Dispatch, SetStateAction } from 'react';
import { AmericaMIcon, MasterMIcon, PayPalMIcon, VisaMIcon } from './credit.icons';
import { Box, CheckIcon, Pressable, Text } from 'native-base';
import { SizePixel } from '../../../utils/pixcel.convert';
import { LeftIcon } from '../../../assets/icons/left.icon';
import { Ar17R, Ar18SbBlack } from '../../../themes/font.style';
import { NumberFixed, NumberFormatting, numberWithCommas } from '../../../utils/helper/number.helper';
import { CollectionCheckCircleIcon, CollectionUnCheckCircleIcon } from '../plain.icon';
import { TCreditBrandTypes, TPayTypes } from '../temp.const';

interface IProps {
    creditType: TCreditBrandTypes;
    selectPayType: TPayTypes;
    setCreditType: Dispatch<SetStateAction<TCreditBrandTypes>>;
    payType: TPayTypes;
    credit: number;
    amount: number;
    amountType: string;
    onPress: (type: TPayTypes, credit: TCreditBrandTypes) => void;
    modify: boolean;
    paypal?: boolean;
}

export const CreditSelect = ({
    selectPayType,
    paypal,
    setCreditType,
    creditType,
    payType,
    amountType,
    onPress,
    amount,
    credit,
    modify,
}: IProps) => {
    const creditRefry = amount - credit > 0; // 카드결제필요
    const renderTypeText = () => {
        switch (selectPayType) {
            case 'With':
                return [
                    creditRefry ? 'Recan Credit + Card' : 'Recan Credit',
                    creditRefry
                        ? `Recan credit applied ($${NumberFormatting(credit)}) and Pay ($${NumberFormatting(amount - credit)})`
                        : `Recan credit applied ($${NumberFormatting(amount)}) `,
                ];
            case 'All':
                return ['Credit or Debit Card', `Pay the total ($${NumberFormatting(amount)}) by Card`];
            case 'Pay':
                return ['Paypal', `Paypal credit applied ($${NumberFormatting(amount)})`];
            default:
                return ['', ''];
        }
    };

    return (
        <Pressable
            flexDirection={'row'}
            disabled={modify}
            onPress={() => {
                onPress(selectPayType, paypal ? 'P' : creditType);
            }}
            w={'100%'}
            h={SizePixel(136)}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            borderBottomWidth={'1px'}
            borderColor={'gray.100'}
        >
            <Box
                w={'100%'}
                flexDirection={'row'}
                mt={SizePixel(28)}
                mb={SizePixel(32)}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
            >
                <Box>
                    {/*{`PAY TYPE = ${payType}`}*/}
                    {/*{`CREDIT TYPE = ${creditType}`}*/}
                    {/*{`AMOUNT TYPE = ${amountType}`}*/}
                    {/*{`SELECT TYPE = ${selectPayType}`}*/}

                    <Text {...Ar18SbBlack} lineHeight={SizePixel(19.58)}>
                        {renderTypeText()[0]}
                    </Text>
                    <Text mt={SizePixel(12)} {...Ar17R} lineHeight={SizePixel(22.07)} w={SizePixel(281.65)}>
                        {renderTypeText()[1]}
                    </Text>
                </Box>
                <Pressable
                    onPress={() => {
                        onPress(selectPayType, paypal ? 'P' : creditType);
                    }}
                    disabled={modify}
                >
                    {payType === selectPayType ? <CollectionCheckCircleIcon /> : <CollectionUnCheckCircleIcon />}
                </Pressable>
            </Box>
        </Pressable>
    );
};
