import React from 'react';
import { Box, IInputProps, Input, Pressable, Text } from 'native-base';
import { Ar18M, Ar18SbBlack } from '../../themes/font.style';
import { useColors } from '../../hooks/useColors';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { SizePixel } from '../../utils/pixcel.convert';

interface Props extends IInputProps {
    value: any;
    label: string;
    onPress?: () => void;
}

const ProfileCard = ({ value, label, onPress }: Props) => {
    const { mainColor } = useColors();
    return (
        <Box
            pt={SizePixel(19)}
            pb={SizePixel(12)}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            borderColor={'gray.100'}
            borderBottomWidth={1}
        >
            <Box justifyContent={'center'} w={'90%'}>
                <Box position={'absolute'} top={-14} bg={'white.100'}>
                    <Text {...Ar18M} color={mainColor} whiteSpace={'nowrap'}>
                        {label}
                    </Text>
                </Box>
                <Box mt={'10px'}>
                    <Text {...Ar18SbBlack} color={'black.100'} numberOfLines={1}>
                        {value !== '' || value ? value : label}
                    </Text>
                </Box>
            </Box>
            {onPress && (
                <Pressable onPress={() => onPress()}>
                    <MoveIcon />
                </Pressable>
            )}
        </Box>
    );
};

const MoveIcon = () => {
    return (
        <Svg width={SizePixel(24)} height={SizePixel(25)} viewBox="0 0 25 25" fill="none">
            <G clip-path="url(#clip0_1022_14046)">
                <Path
                    d="M7.7069 18.9814C7.2688 19.4195 7.2688 20.1298 7.7069 20.5679C8.145 21.006 8.8553 21.006 9.2934 20.5679L16.3769 13.4844C16.977 12.8843 16.977 11.9114 16.3769 11.3114L9.2934 4.22784C8.8553 3.78974 8.145 3.78974 7.7069 4.22784C7.2688 4.66594 7.2688 5.37624 7.7069 5.81434L14.2904 12.3979L7.7069 18.9814Z"
                    fill="#4C4C4C"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1022_14046">
                    <Rect width="24" height="24" fill="white" transform="translate(24.0835 24.3984) rotate(180)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export default ProfileCard;
