/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe('pk_test_oYnmQBe0yrgG62CsSCT9a94K00knvZVvJP');
    }
    return stripePromise;
};

export default getStripe;
