import { useState, useEffect } from 'react';
import { InitHeightSizeScreen, InitWidthSizeScreen } from '../utils/pixcel.convert';

export default function useWindowDimensions() {
    const hasWindow = typeof window !== 'undefined';
    const getWindowDimensions = () => {
        const width = hasWindow ? window.innerWidth : 0;
        const height = hasWindow ? window.innerHeight : 0;
        return {
            width,
            height,
        };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
    };
    useEffect(() => {
        if (hasWindow) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    const designResolution = {
        width: InitWidthSizeScreen,
        height: InitHeightSizeScreen,
    };
    const create = (designSize = { width: 414, height: 736 }) => {
        if (
            !designSize ||
            !designSize.width ||
            !designSize.height ||
            typeof designSize.width !== 'number' ||
            typeof designSize.height !== 'number'
        ) {
            throw new Error(
                'react-native-pixel-perfect | create function | Invalid design size object! must have width and height fields of type Number.'
            );
        }
        const { width, height } = windowDimensions;
        const CURRENT_RESOLUTION = Math.sqrt(
            height * height +
                (width >= InitWidthSizeScreen ? InitWidthSizeScreen : width) * (width >= InitWidthSizeScreen ? InitWidthSizeScreen : width)
        );
        const DESIGN_RESOLUTION = Math.sqrt(designSize.height * designSize.height + designSize.width * designSize.width);
        const RESOLUTIONS_PROPORTION = CURRENT_RESOLUTION / DESIGN_RESOLUTION;
        return (size) => RESOLUTIONS_PROPORTION * size;
    };
    const perfectSize = create(designResolution);
    const sizePix = (size: number) => {
        return perfectSize(size);
    };

    return { ...windowDimensions, sizePix };
}
