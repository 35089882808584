import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, Image, Button, HStack, Center, ScrollView, useToast, StatusBar, Image as RNImage } from 'native-base';
import GoogleIcon from '../../assets/icons/google.icon';
import FaceBookIcon from '../../assets/icons/facebook.icon';
import AppleIcon from '../../assets/icons/apple.icon';
import EmailIcon from '../../assets/icons/Email.icon';

import { TouchableOpacity } from 'react-native';
import { Ar10r, Ar15R, Ar21B, Ar21N } from '../../themes/font.style';
import useAxiosServices from '../../hooks/axiosHooks';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { loginAction, profileSetting } from '../../store/authReducer';

import jwtDecode from 'jwt-decode';
import { RootState } from '../../store';
import { loadingEndAction } from '../../store/commonReducer';
import PaypalLoginIcon from '../../assets/icons/paypal.loginIcon';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import WebSheet from '../../components/bottomSheet/web.sheet';
import PaypalLogin from '../../components/bottomSheet/paypal.sheet';
import { useNavigate } from 'react-router-dom';
import JoinSelect from '../../components/bottomSheet/join.select';
import { toast } from 'react-toastify';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';
import { LongButton } from '../../components/buttons/long.button';
import { FontPixel } from '../../components/typographys/font.pixel';
import { PageSetting } from '../../utils/pageSetting';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Logo = 'https://recandev.s3.ap-southeast-2.amazonaws.com/e99fedc8-19b6-4b65-9432-aee4f671743a';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const payPal = require('../../assets/icons/paypal.png');
const LoginSelectScreen = () => {
    const navigate = useNavigate();
    const { appToken } = useSelector((state: RootState) => state.auth);
    // const { navigation } = useNavigation<AuthStackParamList<'LoginSelect'>>();

    useEffect(() => {
        // GoogleSignin.configure({
        //   webClientId:
        //     '834335995654-8t7m74uchbk5c4ogd2nr0k7bg7p53bbv.apps.googleusercontent.com', // client ID of type WEB for your server (needed to verify user ID and offline access)
        // });
    }, []);

    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();

    const loginHandler = async (value: { id: string; email: string; password: string }, type) => {
        const { email, password, id } = value;
        try {
            const api = await axiosService.post('/users/login/sns', {
                username: id,
                password,
            });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', {
                appToken,
            });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigate('/main', { replace: true });
            } else {
                toast(' Error');
            }
        } catch (e) {
            setSelectOpen(true);
            setTempEmail(email);
            setTempId(id);
            setTempType(type);
        }
    };

    const [tempEmail, setTempEmail] = useState('');
    const [tempId, setTempId] = useState('');

    const [tempType, setTempType] = useState('1');
    const [selectOpen, setSelectOpen] = useState(false);

    const openAndJoin = async (accountType) => {
        const confrim = selectOpen;
        if (confrim) {
            dispatch(loadingEndAction());
            setSelectOpen(false);
            await join(tempId, tempEmail, tempType, accountType);
        }
    };

    const snsLoginWithGoogle = async (r: any) => {
        try {
            console.log(r);
            // console.log(r);
            const { googleId, profileObj } = r;
            const { email, name } = profileObj;
            if (googleId) {
                try {
                    console.log(googleId);
                    await loginHandler({ id: googleId, email, password: googleId } as any, 2);
                } catch (e) {}
            }
            // await GoogleSignin.hasPlayServices();
            // const userInfo = await GoogleSignin.signIn();
            // const {id, email} = userInfo.user;
            // await loginHandler({id, email, password: id}, 2);
        } catch (error) {
            console.log(error);
            // if (error.code === statusCodes.SIGN_IN_CANCELLED) {
            //   // user cancelled the login flow
            // } else if (error.code === statusCodes.IN_PROGRESS) {
            //   // operation (e.g. sign in) is in progress already
            // } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
            //   // play services not available or outdated
            // } else {
            //   // some other error happened
            // }
        }
    };
    const loginJoinHandler = async (value: { email: string; password: string }) => {
        const { email, password } = value;
        try {
            const api = await axiosService.post('/users/login/sns', {
                username: email,
                password,
            });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', {
                appToken,
            });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigate('/main', { replace: true });
            } else {
                toast('Error');
            }
        } catch (e) {
            console.log(e);
            toast('Not a registered member. Please sign up and proceed.');
        }
    };

    const join = async (id: any, email, type, accountType) => {
        try {
            const api = await axiosService.post('/users/join/simple/sns', {
                email: email,
                password: id,
                id: id,
                type,
                accountType,
            });
            const { status, data } = api;
            if (!status) {
                toast(data);
            } else {
                await loginJoinHandler({ email: id, password: id });
            }
        } catch (e) {
            toast('Error');
        } finally {
            setSelectOpen(false);
        }
    };
    const snsLoginWithApple = async (r) => {
        console.log('CODE', r);
        if (r && r.hasOwnProperty('authorization')) {
            const { authorization } = r;
            const { id_token, code } = authorization;
            const decodedToken: any = jwtDecode(id_token);
            const id = decodedToken.sub;
            const mail = decodedToken.email ?? '';
            console.log(id, mail);
            await loginHandler({ id: id, email: mail, password: id }, 2);
        }
        // @ts-ignore
        // const { sub, email } = jwtDecode(code as any);
        // console.log(sub, email);
        // await loginHandler({id: sub, email: email, password: sub}, 2);
    };
    const [webOpen, setWebOpen] = useState(false);
    const [paypalOpen, setPaypalOpen] = useState(false);
    const responseGoogle = (res) => {
        console.log(res);
    };
    const { sizePix: SizePixel, height } = useWindowDimensions();
    return (
        <>
            <Box width={'100%'} h={height}>
                <Box {...PageSetting} h={'full'} display={'flex'} alignItems={'center'} alignSelf={'center'}>
                    <Box flex="1" flexGrow={1} justifyContent={'space-between'} safeArea bg={'white.100'}>
                        <Box w={'100%'}>
                            <Box mt={SizePixel(200)} mb={SizePixel(110)} alignItems={'center'}>
                                <RNImage style={{ width: SizePixel(188), height: SizePixel(52) }} source={{ uri: Logo }} />
                            </Box>
                            <VStack w={'100%'} px={SizePixel(28)}>
                                <GoogleLogin
                                    clientId="1053774717511-r8ojjb66ujb9nfaomi825suv37vkgi99.apps.googleusercontent.com"
                                    onSuccess={snsLoginWithGoogle}
                                    onFailure={responseGoogle}
                                    onScriptLoadFailure={(error) => console.log('ERROR', error)}
                                    uxMode={'popup'}
                                    render={(r) => (
                                        <LongButton bg={'blue.200'} mb={SizePixel(14)} onPress={() => r.onClick()} variant={'basicButton'}>
                                            <HStack alignItems={'center'}>
                                                <Box position={'absolute'} width={'100%'} right={5}>
                                                    <GoogleIcon />
                                                </Box>
                                                <Box alignItems={'center'} width={'100%'} left={2}>
                                                    <FontPixel {...Ar21B} color={'white.100'}>
                                                        Continue with Google
                                                    </FontPixel>
                                                </Box>
                                            </HStack>
                                        </LongButton>
                                    )}
                                />
                                <PaypalLogin />

                                <AppleLogin
                                    clientId="com.recan.web"
                                    redirectURI="https://recan-app.mirable.cc/login"
                                    scope="email"
                                    usePopup={true}
                                    callback={(r) => {
                                        snsLoginWithApple(r);
                                    }}
                                    render={(r) => (
                                        <Button
                                            h={SizePixel(53)}
                                            bg={'black.100'}
                                            rounded={'full'}
                                            mb={SizePixel(14)}
                                            onPress={() => r.onClick()}
                                        >
                                            <HStack alignItems={'center'}>
                                                <Box position={'absolute'} width={'100%'} right={7}>
                                                    <AppleIcon />
                                                </Box>
                                                <Box alignItems={'center'} width={'100%'} left={2}>
                                                    <FontPixel {...Ar21B} color={'white.100'}>
                                                        Continue with Apple
                                                    </FontPixel>
                                                </Box>
                                            </HStack>
                                        </Button>
                                    )}
                                />
                                <Button
                                    h={SizePixel(53)}
                                    mb={SizePixel(14)}
                                    colorScheme={'blue.100'}
                                    // onPress={() => navigate('SingUpScreen' as any)}
                                    onPress={() => navigate('/signUp')}
                                    variant={'basicButton'}
                                >
                                    <HStack alignItems={'center'}>
                                        <Box position={'absolute'} width={'100%'} right={10}>
                                            <EmailIcon />
                                        </Box>
                                        <Box alignItems={'center'} width={'100%'} left={2}>
                                            <FontPixel {...Ar21B} color={'white.100'}>
                                                Sign up with Email
                                            </FontPixel>
                                        </Box>
                                    </HStack>
                                </Button>

                                <Box flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                    <FontPixel {...Ar10r} color={'gray.300'}>
                                        By signing up, you agree to our
                                    </FontPixel>
                                    <TouchableOpacity
                                        onPress={() => setWebOpen(true)}
                                        style={{
                                            marginHorizontal: 4,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FontPixel {...Ar10r} underline color={'gray.300'}>
                                            Terms
                                        </FontPixel>
                                    </TouchableOpacity>
                                    <FontPixel {...Ar10r} color={'gray.300'}>
                                        of Service and
                                    </FontPixel>
                                    <TouchableOpacity
                                        onPress={() => setWebOpen(true)}
                                        style={{
                                            marginHorizontal: 4,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FontPixel {...Ar10r} underline color={'gray.300'}>
                                            Privacy Policy
                                        </FontPixel>
                                    </TouchableOpacity>
                                </Box>
                            </VStack>
                        </Box>

                        <Box px={SizePixel(28)} alignItems={'center'} w={'100%'} bottom={SizePixel(71)} safeArea>
                            <FontPixel color={'gray.300'} {...Ar15R}>
                                Have an account?
                            </FontPixel>
                            {/*@ts-ignore*/}
                            <Button
                                mt={SizePixel(7)}
                                h={SizePixel(53)}
                                colorScheme={'gray.100'}
                                onPress={() => navigate('/signIn' as never)}
                                variant={'basicButton'}
                            >
                                <HStack alignItems={'center'}>
                                    <Box alignItems={'center'} width={'100%'}>
                                        <FontPixel color={'black.100'} fontFamily={'Arch'} fontWeight={'700'} fontSize={20}>
                                            Sign in
                                        </FontPixel>
                                    </Box>
                                </HStack>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <JoinSelect open={selectOpen} onHandler={setSelectOpen} inputHandler={openAndJoin} value={tempType} />
            <WebSheet open={webOpen} setOpen={setWebOpen} url={'https://recan.co'} />
            {/*<PaypalSheet open={paypalOpen} setOpen={setPaypalOpen} />*/}
        </>
    );
};

export default LoginSelectScreen;
