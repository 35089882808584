import React, { ReactElement } from 'react';
import { Box, Button, Text } from 'native-base';
import { SizePixel } from '../../utils/pixcel.convert';
import { MAIN_COLLECTION_PADDING } from './index';
import { LongButton } from '../../components/buttons/long.button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IButtonProps } from 'native-base/src/components/primitives/Button/types';
import { ColorType } from 'native-base/lib/typescript/components/types';
interface IIIBoxProps extends IButtonProps {}
interface Props extends IIIBoxProps {
    children: ReactElement;
    condition?: boolean;
    cHeight?: number;
    cGHeight?: number;

    cBottom?: number;
}
export const BottomButton = ({ children, cHeight, cGHeight, cBottom, condition, ...props }: Props) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const userColor = user?.userType === 1 ? 'blue.200' : 'green.200';
    return (
        <Box
            position={'absolute'}
            w={'100%'}
            h={SizePixel(cHeight ?? 134)}
            bottom={SizePixel(cBottom ?? 50)}
            zIndex={500}
            alignItems={'center'}
            safeAreaBottom
        >
            <Box px={SizePixel(MAIN_COLLECTION_PADDING)} zIndex={200} position={'absolute'} top={SizePixel(14)}>
                <Button {...props} bg={condition ? 'gray.200' : userColor} h={SizePixel(60)} w={SizePixel(364)} borderRadius={100}>
                    {children}
                </Button>
            </Box>
        </Box>
    );
};
