import React, { useState } from 'react';
import { Box, Button, Center, Image, Modal, Pressable, Text, VStack } from 'native-base';
import { Ar17B, Ar17RBlack, Ar18M, Ar25SbBlack } from '../../../themes/font.style';
import WebSheet from '../../../components/bottomSheet/web.sheet';
import { Img } from 'react-image';
interface Props {
    open: boolean;
    image?: any;
    onClose: any;
    onHandler: { text: string; onPress: any; color: any }[];
    title?: any;
    content?: any;
}

const ModalWrapper2 = ({ open, image, onClose, onHandler, title, content }: Props) => {
    const modalButtonWidth = () => {
        const value = onHandler.length;
        switch (value) {
            case 1:
                return '100%';
            case 2:
                return '50%';
            default:
                return '100%';
        }
    };
    const [webOpen, setWepOpen] = useState(false);
    const openWeb = () => {
        onClose();
        setWepOpen(true);
    };

    return (
        <>
            <Modal
                isOpen={open}
                onClose={() => {
                    onClose();
                }}
            >
                <Modal.Content maxWidth="400px">
                    <Modal.Body>
                        <Center my={'30px'}>
                            {image && (
                                <Img width={'131px'} height={'131px'} style={{ objectFit: 'contain' }} src={image} alt={'modalImage'} />
                            )}
                            <VStack space={2} mt={4} alignItems={'center'}>
                                {title && (
                                    <Text textAlign={'center'} {...Ar25SbBlack} mb={'10px'}>
                                        {title}
                                    </Text>
                                )}
                                {content && (
                                    <Text textAlign={'center'} {...Ar17RBlack}>
                                        {content}
                                    </Text>
                                )}
                                <Pressable mt={'30px'} onPress={() => setWepOpen(true)}>
                                    <Text {...Ar17B} color={'black.100'}>
                                        Learn more
                                    </Text>
                                </Pressable>
                            </VStack>
                        </Center>
                    </Modal.Body>
                    <Modal.Footer p={0} bottom={-5}>
                        <Button.Group space={0} width={'100%'}>
                            {onHandler.map((v, i) => (
                                <Button
                                    h={'74px'}
                                    bg={v.color}
                                    borderRadius={0}
                                    w={modalButtonWidth()}
                                    variant="basicButton"
                                    colorScheme="blueGray"
                                    onPress={() => {
                                        v.onPress();
                                    }}
                                >
                                    <Text {...Ar25SbBlack} color={'white.100'}>
                                        {v.text}
                                    </Text>
                                </Button>
                            ))}
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <WebSheet open={webOpen} setOpen={setWepOpen} url={'https://recan.co'} />
        </>
    );
};

export default ModalWrapper2;
