import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING } from './index';
import { Box, Pressable, ScrollView, Text } from 'native-base';
import { Ar18M, Ar18SbBlack, Ar21B, Ar36B, La56Sb } from '../../themes/font.style';
import { useColors } from '../../hooks/useColors';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserCreditRender } from '../../utils/helper/user.helper';
import { NumberFixed, NumberFormatting } from '../../utils/helper/number.helper';
import { CreditRecanImage } from './credit/credit.icons';
import { CreditCard } from './credit/credit.card';
import Svg, { Path } from 'react-native-svg';
import { LeftIcon } from '../../assets/icons/left.icon';
import { CreditSelect } from './credit/credit.select';
import { ProductAllAmountRender } from '../../utils/product.helper';
import { IProductsTypes, TCreditBrandTypes, TPayTypes } from './temp.const';
import { IPaymentListType } from '../../@types/payment.types';
import { useNavigate } from 'react-router-dom';

export const PlusIconWithBold = () => {
    return (
        <Svg width={SizePixel(25)} height={SizePixel(25)} viewBox="0 0 25 25" fill="none">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.439 4.39844H11.439V10.8984H4.93896V13.8984H11.439V20.3984H14.439V13.8984H20.939V10.8984H14.439V4.39844Z"
                fill="black"
            />
        </Svg>
    );
};

interface IProps {
    products: IProductsTypes;
    payType: TPayTypes;
    setPayType: Dispatch<SetStateAction<TPayTypes>>;
    creditType: TCreditBrandTypes;
    setCreditType: Dispatch<SetStateAction<TCreditBrandTypes>>;
    handlePayType: (type: TPayTypes, credit: TCreditBrandTypes) => void;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
    modify: boolean;
    paypal: { check: boolean; info: IPaymentListType | null };
    card: { check: boolean; info: IPaymentListType | null };
}

export const CreditScreen = ({
    paypal,
    card,
    modify,
    setPageType,
    products,
    payType,
    setPayType,
    setCreditType,
    creditType,
    handlePayType,
}: IProps) => {
    const { mainColor } = useColors();
    const { user } = useSelector((state: RootState) => state.auth);
    const [credit, setCredit] = useState(0);
    const navigation = useNavigate();
    useEffect(() => {
        const userCredit = UserCreditRender(user);
        setCredit(userCredit);
    }, [user]);
    console.log('CARD INFO', card);
    useEffect(() => {
        setPageType('Credit');
    }, []);

    return (
        <Box px={SizePixel(12)} mt={SizePixel(64.37)}>
            <Box>
                <Text {...Ar36B} color={'black.100'}>
                    Choose how to pay
                </Text>
            </Box>
            <ScrollView showsVerticalScrollIndicator={false}>
                <Box
                    alignSelf={'center'}
                    borderRadius={SizePixel(15)}
                    mt={SizePixel(31.29)}
                    w={SizePixel(400)}
                    h={SizePixel(200)}
                    bg={mainColor}
                    pt={SizePixel(20)}
                    pb={SizePixel(12)}
                    justifyContent={'space-between'}
                >
                    <Text {...Ar21B} alignSelf={'flex-end'} color={'white.100'} lineHeight={SizePixel(22.85)} px={SizePixel(20)}>
                        RECAN Credit
                    </Text>
                    <Text {...La56Sb} color={'white.100'} lineHeight={SizePixel(67.2)} px={SizePixel(20)}>
                        {`$${NumberFormatting(credit)}`}
                    </Text>
                    <Box position={'absolute'} bottom={-2} opacity={0.1}>
                        <CreditRecanImage />
                    </Box>
                </Box>
                <Box mt={SizePixel(20)}>
                    {credit > 0 && UserCreditRender(user) - ProductAllAmountRender(products) > 0 && (
                        <CreditSelect
                            creditType={card.info?.brand as any}
                            setCreditType={setCreditType}
                            modify={modify}
                            payType={payType}
                            selectPayType={'With'}
                            credit={UserCreditRender(user)}
                            amount={ProductAllAmountRender(products)}
                            amountType={payType}
                            onPress={handlePayType}
                        />
                    )}
                    {/*<CreditCard modify={modify} content={'PayPal'} creditType={'P'} onPress={() => null} />*/}
                    <CreditSelect
                        creditType={creditType}
                        setCreditType={setCreditType}
                        modify={modify}
                        payType={payType}
                        selectPayType={'Pay'}
                        credit={UserCreditRender(user)}
                        amount={ProductAllAmountRender(products)}
                        amountType={payType}
                        onPress={handlePayType}
                        paypal={true}
                    />

                    {card.check ? (
                        <>
                            <CreditCard
                                modify={false}
                                content={`**** **** ${card.info?.last4}`}
                                creditType={card.info?.brand as any}
                                onPress={() => navigation('/CollectionPayment')}
                            />

                            <CreditSelect
                                creditType={card.info?.brand as any}
                                setCreditType={setCreditType}
                                modify={modify}
                                payType={payType}
                                selectPayType={'All'}
                                credit={UserCreditRender(user)}
                                amount={ProductAllAmountRender(products)}
                                amountType={payType}
                                onPress={handlePayType}
                            />
                        </>
                    ) : (
                        <Pressable
                            flexDirection={'row'}
                            onPress={() => navigation('/CollectionPayment')}
                            w={'100%'}
                            h={SizePixel(88)}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Box flexDirection={'row'} alignItems={'center'}>
                                <PlusIconWithBold />
                                <Text ml={SizePixel(16)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)}>
                                    Add Credit or Debit Card
                                </Text>
                            </Box>
                        </Pressable>
                    )}
                </Box>
                <Box height={SizePixel(400)} />
            </ScrollView>
        </Box>
    );
};
