import React from 'react';
import { SizePixel } from '../../../utils/pixcel.convert';

export const RecyleIcon = () => {
    return (
        <svg width={SizePixel(37)} height={SizePixel(37)} viewBox="0 0 37 37" fill="none">
            <path
                d="M2.14854 12.91L4.16444 14.4851L2.2671 18.1198C1.31845 19.7958 2.94817 21.4285 3.98653 21.9968C5.00874 22.5563 6.59531 22.6228 8.07758 22.6027L10.7457 18.241L12.7616 19.3314L9.26339 12.8495L2.14854 12.91ZM2.44496 21.8075L6.77317 29.8038C7.64277 30.9144 9.28317 31.1769 10.9828 31.1365H15.5482V23.5642L6.89177 23.5037C5.54789 23.5844 3.78889 23.3017 2.44496 21.8075ZM29.676 7.07113L27.3211 8.07066L25.0619 4.64916C24.056 3.00692 21.8378 3.64074 20.8374 4.2734C19.8526 4.89622 19.0252 6.25162 18.3242 7.55782L20.8455 12.006L18.9144 13.2404L26.2796 13.3234L29.676 7.07113ZM21.7504 3.01692L12.6583 2.92578C11.2653 3.14789 10.2404 4.45531 9.45177 5.96152L7.23844 9.95449L13.8613 13.6256L18.111 6.08386C18.6919 4.86922 19.7919 3.46788 21.7504 3.01692ZM22.1459 33.8716L22.4133 31.3273L26.5011 31.0103C28.4251 30.9266 28.9462 28.6793 28.8779 27.4976C28.8107 26.3343 28.0262 24.9535 27.2236 23.7072L22.1109 23.7558L21.9673 21.4684L18.3241 27.8699L22.1459 33.8716ZM29.5342 28.9053L34.0218 20.9974C34.5029 19.6714 33.8563 18.1412 32.9213 16.7211L30.502 12.8495L24.0803 16.8622L28.6162 24.2354C29.3969 25.3323 30.0892 26.9737 29.5342 28.9053Z"
                fill="#A6A6A6"
            />
        </svg>
    );
};

export const DateIcon = () => {
    return (
        <svg width={SizePixel(37)} height={SizePixel(37)} viewBox="0 0 37 37" fill="none">
            <g clip-path="url(#clip0_1022_13545)">
                <path
                    d="M13.584 16.8984H10.584V19.8984H13.584V16.8984ZM19.584 16.8984H16.584V19.8984H19.584V16.8984ZM25.584 16.8984H22.584V19.8984H25.584V16.8984ZM28.584 6.39844H27.084V3.39844H24.084V6.39844H12.084V3.39844H9.08398V6.39844H7.58398C5.91898 6.39844 4.59898 7.74844 4.59898 9.39844L4.58398 30.3984C4.58398 32.0484 5.91898 33.3984 7.58398 33.3984H28.584C30.234 33.3984 31.584 32.0484 31.584 30.3984V9.39844C31.584 7.74844 30.234 6.39844 28.584 6.39844ZM28.584 30.3984H7.58398V13.8984H28.584V30.3984Z"
                    fill="#A6A6A6"
                />
            </g>
            <defs>
                <clipPath id="clip0_1022_13545">
                    <rect width="36" height="36" fill="white" transform="translate(0.0834961 0.398438)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const PencilIcon = () => {
    return (
        <svg width={SizePixel(37)} height={SizePixel(37)} viewBox="0 0 37 37" fill="none">
            <path
                d="M4.25257 31.2203C4.17121 31.5025 4.25257 31.8116 4.45851 32.0178C4.61043 32.1695 4.82209 32.2563 5.03354 32.2563C5.10939 32.2563 5.18545 32.2455 5.25601 32.2237L12.8386 30.0596L6.41666 23.6377L4.25257 31.2203Z"
                fill="#A6A6A6"
            />
            <path
                d="M5.03342 32.2836C4.81096 32.2836 4.59951 32.1967 4.44229 32.0395C4.22533 31.8225 4.14398 31.5079 4.23063 31.2151L6.41103 23.5889L12.8982 30.076L5.26119 32.2509C5.18534 32.2727 5.10927 32.2836 5.03342 32.2836ZM6.43285 23.6865L4.27957 31.2259C4.20351 31.5026 4.27957 31.7954 4.48022 31.996C4.62663 32.1424 4.82727 32.2238 5.03342 32.2238C5.10398 32.2238 5.17983 32.213 5.25038 32.1914L12.7897 30.0381L6.43285 23.6865Z"
                fill="#A6A6A6"
            />
            <path
                d="M31.2259 8.70019L27.7817 5.25596C27.3154 4.79492 26.7079 4.54004 26.0515 4.54004C25.4007 4.54004 24.7877 4.79492 24.3267 5.25067L22.6018 6.97532L29.4958 13.8693L31.2206 12.1444C31.6817 11.6834 31.9311 11.0704 31.9311 10.4196C31.9366 9.76867 31.6817 9.16123 31.2259 8.70019Z"
                fill="#A6A6A6"
            />
            <path
                d="M29.5012 13.907L22.5693 6.9751L24.3103 5.23413C24.7769 4.76758 25.3951 4.5127 26.0515 4.5127C26.7132 4.5127 27.3316 4.76758 27.7927 5.23413L31.2369 8.67836C31.7032 9.14469 31.9581 9.76315 31.9581 10.4193C31.9581 11.0812 31.7032 11.6995 31.2369 12.1605L29.5012 13.907ZM22.6452 6.9751L29.5012 13.8311L31.2043 12.1279C31.6598 11.6724 31.9094 11.0702 31.9094 10.4248C31.9094 9.77947 31.6598 9.17732 31.2043 8.72179L27.76 5.27205C27.3045 4.81652 26.6969 4.56694 26.0515 4.56694C25.4061 4.56694 24.804 4.81652 24.3484 5.27205L22.6452 6.9751Z"
                fill="#A6A6A6"
            />
            <path d="M7.33154 22.2495L21.4568 8.12402L28.3527 15.0199L14.2274 29.1454L7.33154 22.2495Z" fill="#A6A6A6" />
            <path
                d="M14.227 29.1862L7.28979 22.249L21.4572 8.08691L28.3891 15.0188L14.227 29.1862ZM7.37115 22.249L14.227 29.1049L28.313 15.0188L21.4572 8.16298L7.37115 22.249Z"
                fill="#A6A6A6"
            />
        </svg>
    );
};

export const PetIcon = () => {
    return (
        <svg width={SizePixel(37)} height={SizePixel(37)} viewBox="0 0 37 37" fill="none">
            <path
                d="M23.4716 19.3539C22.0345 17.1121 19.587 15.7734 16.9244 15.7734C14.2617 15.7734 11.8142 17.1121 10.3774 19.3539L6.91458 24.756C6.34004 25.6521 6.07495 26.6877 6.1478 27.7498C6.22065 28.8121 6.62488 29.8013 7.31661 30.6112C8.00883 31.4204 8.92351 31.9735 9.96171 32.2108C10.9999 32.4481 12.0642 32.3468 13.0393 31.9182L13.1046 31.8892C15.5582 30.8322 18.3619 30.842 20.8092 31.9182C21.4405 32.1957 22.1093 32.3361 22.7842 32.3361C23.1512 32.3361 23.5203 32.2944 23.886 32.2111C24.9243 31.974 25.8389 31.4209 26.5314 30.6115C27.2234 29.8023 27.6278 28.8128 27.7009 27.7502C27.774 26.6877 27.5089 25.6524 26.9344 24.7557L23.4716 19.3539Z"
                fill="#A6A6A6"
            />
            <path
                d="M7.84722 19.3467C8.87738 18.9511 9.66 18.1209 10.0508 17.0091C10.4229 15.9512 10.3924 14.7568 9.96481 13.6457C9.53696 12.5354 8.75873 11.629 7.77339 11.0932C6.73835 10.5306 5.60122 10.439 4.57275 10.8354C2.50364 11.631 1.55413 14.1879 2.45638 16.5364C3.1771 18.4058 4.85807 19.5858 6.55756 19.5858C6.9915 19.5858 7.42667 19.5088 7.84722 19.3467Z"
                fill="#A6A6A6"
            />
            <path
                d="M14.5661 15.1141C17.1527 15.1141 19.2571 12.7166 19.2571 9.76959C19.2571 6.82187 17.1527 4.42383 14.5661 4.42383C11.9797 4.42383 9.87549 6.82187 9.87549 9.76959C9.87549 12.7166 11.9797 15.1141 14.5661 15.1141Z"
                fill="#A6A6A6"
            />
            <path
                d="M22.668 16.4272H22.6682C23.0661 16.5592 23.472 16.6223 23.8763 16.6223C25.7626 16.6223 27.601 15.2494 28.3025 13.1393C28.7065 11.9247 28.6799 10.6404 28.2277 9.52325C27.7545 8.35372 26.8776 7.50483 25.7583 7.13277C24.6387 6.76071 23.4279 6.91592 22.3488 7.56939C21.3179 8.19363 20.528 9.2065 20.1245 10.4211C19.2729 12.9834 20.4139 15.6777 22.668 16.4272Z"
                fill="#A6A6A6"
            />
            <path
                d="M32.5466 15.8038L32.5459 15.8031C30.7621 14.4854 28.0944 15.0565 26.5986 17.0769C25.1042 19.0983 25.3379 21.8157 27.119 23.1344C27.7686 23.6156 28.5361 23.8456 29.3216 23.8456C30.6907 23.8456 32.1151 23.1468 33.0673 21.8618C34.5614 19.8404 34.328 17.123 32.5466 15.8038Z"
                fill="#A6A6A6"
            />
        </svg>
    );
};

export const PhotoIcon = () => {
    return (
        <svg width={SizePixel(37)} height={SizePixel(37)} viewBox="0 0 37 37" fill="none">
            <path
                d="M29.6597 10.461H26.4846L24.7316 7.8151C24.0702 6.82287 22.9126 6.22754 21.7219 6.22754H14.4456C13.2549 6.22754 12.0973 6.82287 11.4358 7.8151L9.6829 10.461H6.50779C4.49026 10.461 2.86963 12.0817 2.86963 14.0992V26.932C2.86963 28.9495 4.49026 30.5701 6.50779 30.5701H29.6597C31.6772 30.5701 33.2979 28.9495 33.2979 26.932V14.0992C33.2979 12.0817 31.6772 10.461 29.6597 10.461ZM18.0837 27.5934C13.5526 27.5934 9.88135 23.9222 9.88135 19.3911C9.88135 14.8599 13.5526 11.2217 18.0837 11.2217C22.6149 11.2217 26.2861 14.893 26.2861 19.4241C26.2861 23.9222 22.6149 27.5934 18.0837 27.5934ZM29.1967 15.5875C29.1636 15.5875 29.1305 15.5875 29.0644 15.5875H27.7414C27.1461 15.5544 26.683 15.0583 26.7161 14.463C26.7492 13.9007 27.1791 13.4708 27.7414 13.4377H29.0644C29.6597 13.4046 30.1558 13.8677 30.1889 14.463C30.222 15.0583 29.792 15.5544 29.1967 15.5875Z"
                fill="#A6A6A6"
            />
            <path
                d="M18.0828 14.8594C15.5692 14.8594 13.5186 16.91 13.5186 19.4236C13.5186 21.9372 15.5692 23.9548 18.0828 23.9548C20.5964 23.9548 22.647 21.9042 22.647 19.3905C22.647 16.8769 20.5964 14.8594 18.0828 14.8594Z"
                fill="#A6A6A6"
            />
        </svg>
    );
};

export const RecanBagIcon = () => {
    return (
        <svg width={SizePixel(37)} height={SizePixel(37)} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.927 4.24512V8.25252C24.927 8.67464 24.7305 9.07947 24.3807 9.37795C24.031 9.67643 23.5566 9.84411 23.062 9.84411H13.1043C12.6097 9.84411 12.1353 9.67643 11.7856 9.37795C11.4358 9.07947 11.2393 8.67464 11.2393 8.25252V4.24512H6.12744V30.279C6.12744 30.8821 6.40814 31.4604 6.90778 31.8868C7.40743 32.3132 8.08509 32.5527 8.7917 32.5527H27.3749C28.0815 32.5527 28.7591 32.3132 29.2588 31.8868C29.7584 31.4604 30.0391 30.8821 30.0391 30.279V4.24512H24.927ZM20.4811 13.9368H15.6855C15.3322 13.9368 14.9933 13.817 14.7435 13.6038C14.4937 13.3906 14.3533 13.1014 14.3533 12.7999C14.3533 12.4984 14.4937 12.2093 14.7435 11.9961C14.9933 11.7829 15.3322 11.6631 15.6855 11.6631H20.4811C20.8344 11.6631 21.1732 11.7829 21.4231 11.9961C21.6729 12.2093 21.8132 12.4984 21.8132 12.7999C21.8132 13.1014 21.6729 13.3906 21.4231 13.6038C21.1732 13.817 20.8344 13.9368 20.4811 13.9368Z"
                fill="#A6A6A6"
            />
        </svg>
    );
};
