import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Heading, IconButton, Text, useToast, Image, Pressable, Divider, HStack } from 'native-base';
import GoogleInput from './google.input';
import { useDebounce } from '../../hooks/useDebounce';
import HeaderBack from '../../containers/header/header.back';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from '../customInput/defaultInput';
import useAxiosServices from '../../hooks/axiosHooks';
import { Ar17M, Ar18M, Ar18SbBlack, Ar21Sb, Ar22M, Ar25SbBlack, Ar36B } from '../../themes/font.style';
import DefaultDown from '../dropdown/default.down';
import { TransactionAddress, TransactionBAddress, TransactionBagCase } from '../../etc/bookStatusCase';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { profileSetting } from '../../store/authReducer';
import GoogleMarker from './google.marker';
import { Keyboard, TouchableOpacity } from 'react-native';
import BackIcon from '../../assets/icons/back.icon';
import CloseIcon from '../../assets/icons/close.icon';
import ModalWrapper from '../../containers/commons/modal/modal.wrapper';
import { toast } from 'react-toastify';
import { addressNullCheck } from '../../utils/gup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Img } from 'react-image';

import postcodeImage from '../../assets/image/postcode.png';

import addressPin from '../../assets/icons/address-pin.png';
import { MAXWIDTH } from '../../themes';
import useWindowDimensions from '../../hooks/useDimensions';
import { FontPixel } from '../typographys/font.pixel';
import MapScreen from './map.screen';
import { addressConvert, RouteNameInit } from '../../utils/helper/address.helper';
import { useColors } from '../../hooks/useColors';
import TextAreaInput from '../customInput/textarea.input';
import PhotoInput from '../customInput/photo.input';
import { LongButton } from '../buttons/long.button';
import { addressAdd } from '../../store/commonReducer';
import { PageSetting } from '../../utils/pageSetting';
const GOOGLE_PACES_API_BASE_URL = 'https://maps.googleapis.com/maps/api/place';
const GOOGLE_API_KEY = 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI';
export type PredictionType = {
    description: string;
    place_id: string;
    reference: string;
    matched_substrings: any[];
    tructured_formatting: Object;
    terms: Object[];
    types: string[];
};

const AddressSchema = Yup.object().shape({
    route: Yup.string(),
    typeText: Yup.string(),
    unit: Yup.string(),
    description: Yup.string(),
});

const selectData = [
    { value: '1', label: 'House' },
    { value: '2', label: 'Townhouse' },
    { value: '3', label: 'Apartment' },
];
interface Props {
    noAuth?: boolean;
}
const GoogleSearch = ({ noAuth }: Props) => {
    const { axiosService } = useAxiosServices();
    const { mainColor, colorScheme } = useColors();
    const { addressTemp } = useSelector((state: RootState) => state.common);
    const { lot, lat, address, addressMore, postcode, route, type, idx } = addressTemp;
    const { sizePix } = useWindowDimensions();
    const routes: any = useLocation();
    const dispatch = useDispatch();

    const user = useSelector((user: RootState) => user.auth.user);
    const {
        isValid,
        setTouched,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        initialValues,
        resetForm,
        values,
    } = useFormik({
        validationSchema: AddressSchema,
        initialValues: {
            route: '',
            typeText: '',
            unit: '',
            description: '',
        },
        onSubmit: async (value) => {
            console.log(value);
            // modify ? await updateApi(value) : await insertApi(value);
        },
    });

    const [search, setSearch] = useState({ term: '', fetchPredictions: false });
    const [predictions, setPredictions] = useState<PredictionType[]>([]);
    const [showPredictions, setShowPredictions] = useState<boolean>(false);
    const [selectAddress, setSelectAddress] = useState(false);
    const [addressName, setAddressName] = useState({ address: '', addressMore: '', postcode: '', route: '', lat: 0, lot: 0 });
    const [postCodeList, setPostCodeList] = useState<any[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const settingAddressParams = () => {
        setAddressName({ address, addressMore, postcode, route: RouteNameInit(route, address ?? ''), lot, lat } as any);
    };

    const formattingAddress = (address_components) => {
        if (!address_components) {
            return {
                address: '',
                addressMore: '',
                postcode: '',
            };
        }
        let addressString = '';
        let moreString = '';
        let postcode = '';
        const componentForm = {
            street_number: ['long_name', 'main'],
            route: ['long_name', 'main'],
            locality: ['long_name', 'main'],
            sublocality_level_1: ['long_name', 'main'],
            administrative_area_level_1: ['short_name', 'more'],
            administrative_area_level_2: ['short_name', 'more'],
            postal_code: ['short_name', 'no'],
        };
        for (let i = 0; i < address_components.length; i++) {
            // warning this only gets the first type of the component. E.g. 'sublocality_level_1'
            const addressType = address_components[i].types[0];
            if (componentForm[addressType]) {
                const [addressText, type] = componentForm[addressType];
                const val = address_components[i][addressText];
                if (type === 'main') {
                    addressString += '' + val + ', ';
                }
                if (type === 'more') {
                    moreString += '' + val + ', ';
                }
                if (type === 'no') {
                    postcode += val;
                }
            }
        }
        console.log('postcode', postcode);
        return {
            address: addressString.replace(/,\s*$/, ''),
            addressMore: moreString.replace(/,\s*$/, ''),
            postcode,
        };
    };

    const navigation = useNavigate();

    const typePickHandler = (index: number, typeValue: any) => {
        console.log('DDD', typeValue);
        setFieldValue('type', Number(typeValue));
        setShowMoreText(Number(typeValue) === 3);
    };
    const [showMoreText, setShowMoreText] = useState(false);

    const [step, setStep] = useState(1);

    useEffect(() => {
        settingAddressParams();
        if (idx) {
            setModify(true);
            getAddressInfo();
        }
    }, [addressTemp]);
    console.log('idx, ', idx);
    const [modify, setModify] = useState(false);

    const getAddressInfo = async () => {
        const api = await axiosService.post('/users/app/address/info', { idx });
        const { data, status } = api.data;
        if (status) {
            const { address, addressMore, moreText, lat, lot, type, postcode, route, unitText, description, imageUrl } = data;
            setFieldValue('typeText', moreText ?? '');
            setFieldValue('unit', unitText ?? '');
            setFieldValue('description', description ?? '');
            setViewPhoto(imageUrl);
            setModify(true);
        }
    };
    const [removeOpen, setRemoveOpen] = useState(false);
    const addressChangeProfileGet = async () => {
        const getProfile = await axiosService.post('/users/app/profile');
        const { data: profileData, status: profileStatus } = getProfile.data;
        if (profileStatus) {
            dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
            // if (user?.addressCheck === 1) {
            //     navigation('/main');
            // }
        }
    };
    const deleteAddressComplete = async () => {
        try {
            const api = await axiosService.post('/users/app/address/delete', { idx: idx });
            const { status } = api.data;
            if (status) {
                await addressChangeProfileGet();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRemoveOpen(false);
        }
    };

    const moveAdjust = () => {
        // @ts-ignore
        navigation('/adjust');
    };
    const [photo, setPhoto] = useState<any>(null);
    const [visible, setVisible] = useState(false);
    const [viewPhoto, setViewPhoto] = useState<any>(null);
    const photoHandler = async (type: 'pick' | 'camera', file) => {
        const views = URL.createObjectURL(file);
        if (type === 'pick') {
            setPhoto(file);
            setViewPhoto(views);
        } else {
            setPhoto(file);
            setViewPhoto(views);
        }
    };
    const saveAPI = async () => {
        const formData = new FormData();
        if (photo) {
            formData.append('file', photo as any, 'file');
        }
        formData.append('lat', String(addressName.lat));
        formData.append('lot', String(addressName.lot));
        formData.append('address', String(addressName.address));
        formData.append('addressMore', String(addressName.addressMore));
        formData.append('postcode', String(addressName.postcode));
        formData.append('route', String(addressName.route));
        formData.append('unit', values.unit);
        formData.append('description', values.description);
        formData.append('moreText', values.typeText);

        const api = await axiosService.post('/users/app/address/insert', formData, {
            data: formData,
            transformRequest: (data, headers) => {
                // !!! override data to return formData
                // since axios converts that to string
                return formData;
            },
        });
        const { status } = api.data;
        if (status) {
            // @ts-ignore
            // noAuth ? await getProfileAPI() : navigation('/addressInfo');

            await getProfileAPI();
        }
    };
    console.log(user);

    const updateAPI = async () => {
        const formData = new FormData();
        if (photo) {
            formData.append('file', photo as any);
        }

        formData.append('idx', String(idx));
        formData.append('lat', String(addressName.lat));
        formData.append('lot', String(addressName.lot));
        formData.append('address', String(addressName.address));
        formData.append('addressMore', String(addressName.addressMore));
        formData.append('postcode', String(addressName.postcode));
        formData.append('route', String(addressName.route));
        formData.append('unit', values.unit);
        formData.append('description', values.description);
        formData.append('moreText', values.typeText);
        formData.append('imageRemove', viewPhoto ? '2' : '1');
        const api = await axiosService.post('/users/app/address/update', formData, {
            data: formData,
            transformRequest: (data, headers) => {
                // !!! override data to return formData
                // since axios converts that to string
                return formData;
            },
        });
        const { status } = api.data;
        if (status) {
            // @ts-ignore
            noAuth ? await getProfileAPI() : navigation('/addressInfo');
        }
    };
    const getProfileAPI = async () => {
        const getProfile = await axiosService.post('/users/app/profile');
        const { data: profileData, status: profileStatus } = getProfile.data;
        if (profileStatus) {
            dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
            if (profileData.profileCheck !== 1) {
                navigation('/profile');
            } else {
                navigation('/addressInfo');
            }
        }
    };
    return (
        <>
            <Box>
                <Box>
                    <HStack alignItems={'center'} justifyContent={'space-between'}>
                        <TouchableOpacity onPress={() => navigation(-1)} hitSlop={{ top: 20, left: 20, right: 20, bottom: 20 }}>
                            <BackIcon />
                        </TouchableOpacity>
                        <Box flexDirection={'row'} alignItems={'center'}>
                            {/*{step === 5 && (*/}
                            {/*    <TouchableOpacity style={{ marginRight: 20 }} onPress={() => modalHandler('close')}>*/}
                            {/*        <Text fontFamily={'Arch'} fontWeight={500} fontSize={'14px'} color={'blue.200'}>*/}
                            {/*            Cancel*/}
                            {/*        </Text>*/}
                            {/*    </TouchableOpacity>*/}
                            {/*)}*/}
                            {modify && (
                                <TouchableOpacity onPress={() => setRemoveOpen(true)}>
                                    <FontPixel {...Ar21Sb} color={'blue.200'}>
                                        Remove
                                    </FontPixel>
                                </TouchableOpacity>
                            )}
                        </Box>
                    </HStack>
                </Box>
            </Box>
            <Box {...PageSetting} w={'100vw'} flex={1} safeArea bg={'white.100'} h={'100vh'}>
                <Box maxW={MAXWIDTH} flex={1} w={'100vw'} h={'100vh'}>
                    <Box flex={1} pt={sizePix(30)}>
                        <Heading
                            paddingLeft={'10px'}
                            mb={sizePix(15)}
                            fontFamily={'Arch'}
                            fontWeight={900}
                            fontSize={'30px'}
                            color={'black.100'}
                        >
                            {'Pickup address'}
                        </Heading>
                        {lat !== 0 && <MapScreen moveAdjust={moveAdjust} marker={[{ lat, lot }]} />}
                        <Box px={sizePix(28)} pt={sizePix(20)}>
                            <Pressable
                                onPress={() => navigation(-1)}
                                _pressed={{ opacity: 0.5 }}
                                flexDirection={'row'}
                                w={'100%'}
                                pb={sizePix(20)}
                            >
                                <Box w={'100%'}>
                                    <FontPixel {...Ar18M} color={'black.100'}>
                                        {addressConvert([addressName.address, addressName.addressMore, addressName.postcode])}
                                    </FontPixel>
                                </Box>
                            </Pressable>
                            <Box w={'100%'}>
                                <CustomInput
                                    {...Ar17M}
                                    value={values.unit}
                                    error={errors.unit}
                                    touched={touched.unit}
                                    onChangeText={handleChange('unit')}
                                    placeholder={'Unit / Suite / Floor'}
                                    style={{ marginBottom: 15 }}
                                />
                                <CustomInput
                                    {...Ar17M}
                                    value={values.typeText}
                                    error={errors.typeText}
                                    touched={touched.typeText}
                                    onChangeText={handleChange('typeText')}
                                    placeholder={'Business or building name'}
                                    style={{ marginBottom: 15 }}
                                />
                            </Box>
                            <Box mt={'20px'}>
                                <FontPixel mb={sizePix(14)} {...Ar25SbBlack} color={'black.100'}>
                                    Description
                                </FontPixel>
                                <FontPixel {...Ar18M} fontSize={'15px'} color={colorScheme[100]} mb={'26px'}>
                                    {'Let us know your secure bags location\n'}(Please be specific)
                                </FontPixel>
                                <TextAreaInput
                                    onChangeText={handleChange('description')}
                                    placeholder={'e.g. Outside the front door / garage door\n'}
                                    value={values.description}
                                />
                                <Box w={'100%'} alignItems={'flex-start'}>
                                    <FontPixel textAlign={'left'} my={8} mb={0} {...Ar25SbBlack}>
                                        Upload photo
                                    </FontPixel>
                                    {viewPhoto && (
                                        <Box mt={4} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                                            <Pressable onPress={() => setVisible(true)}>
                                                <Img
                                                    style={{ borderRadius: 12, width: 100, height: 100, objectFit: 'contain' }}
                                                    src={`${viewPhoto}`}
                                                />
                                            </Pressable>
                                            <Box alignSelf={'flex-end'} ml={4}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setPhoto(null);
                                                        setViewPhoto(null);
                                                    }}
                                                >
                                                    <FontPixel {...Ar18M} color={'gray.300'}>
                                                        Remove
                                                    </FontPixel>
                                                </TouchableOpacity>
                                            </Box>
                                        </Box>
                                    )}
                                    <Box flexDirection={'row'} my={4}>
                                        <PhotoInput type={'camera'} onHandler={photoHandler} />
                                        <PhotoInput type={'pick'} onHandler={photoHandler} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={4} />
                    </Box>
                    <Box flexDirection={'row'} w={'100%'} justifyContent={'space-between'} px={'30px'}>
                        <LongButton
                            onPress={() => (!modify ? saveAPI() : updateAPI())}
                            _disabled={{ bg: 'gray.100' }}
                            width={'100%'}
                            variant={'basicButton'}
                            shadow={8}
                            bg={mainColor}
                        >
                            <FontPixel {...Ar22M} color={'white.100'}>
                                {modify ? 'Update' : 'Save'}
                            </FontPixel>
                        </LongButton>
                    </Box>
                </Box>
            </Box>
            <ModalWrapper
                open={removeOpen}
                onClose={() => setRemoveOpen(false)}
                onHandler={[
                    { text: 'Cancel', onPress: () => setRemoveOpen(false), color: 'gray.200' },
                    { text: 'Remove', onPress: deleteAddressComplete, color: 'blue.200' },
                ]}
                title={'Are you sure?'}
                content={'Are you sure you want to\n' + 'remove this location?'}
            />
            <ModalWrapper
                image={postcodeImage}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onHandler={[{ text: 'Got it!', onPress: () => setModalOpen(false), color: 'blue.200' }]}
                title={'Sorry...'}
                content={'Not available in your area right now. Please confirm the available location for pickup.'}
            />
        </>
    );
};

export default GoogleSearch;
