import React, { FunctionComponent } from 'react';
import { IPhotoListTypes } from '../../../@types/collection.types';
import { Box, Pressable, Text } from 'native-base';
import { SizePixel } from '../../../utils/pixcel.convert';
import { Ar17M, Ar17R, Ar18M, Ar18SbBlack } from '../../../themes/font.style';
import { Image as RNImage, Modal } from 'react-native';
import { Img } from 'react-image';
interface IProps {
    cardType: 'Bag' | 'Date' | 'Description' | 'Photo' | 'Pet' | 'Order';
    arrayData?: string[];
    stringData?: string;
    photoData?: IPhotoListTypes[];
    Icon: FunctionComponent;
    editPress: any;
}
export const DetailCard = ({ cardType, arrayData, photoData, stringData, Icon, editPress }: IProps) => {
    const topPadding = cardType === 'Date' ? SizePixel(28) : SizePixel(24);
    const bodyRender = () => {
        switch (cardType) {
            case 'Bag':
                return (
                    <Box
                        flexDirection={'row'}
                        pt={topPadding}
                        pb={SizePixel(20)}
                        pr={SizePixel(12)}
                        borderBottomWidth={'1px'}
                        borderColor={'gray.100'}
                        w={SizePixel(300)}
                        justifyContent={'space-between'}
                    >
                        <Box>
                            <Text mb={SizePixel(4)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)} color={'black.100'}>
                                Bag type
                            </Text>
                            {arrayData &&
                                arrayData.map((v, i) => (
                                    <Text mb={SizePixel(4)} {...Ar18M} lineHeight={SizePixel(22.86)} color={'gray.300'}>
                                        {v}
                                    </Text>
                                ))}
                        </Box>
                        <Pressable onPress={() => editPress()}>
                            <Text {...Ar17M} color={'gray.300'} lineHeight={SizePixel(19.8)}>
                                Edit
                            </Text>
                        </Pressable>
                    </Box>
                );
            case 'Date':
                return (
                    <Box
                        flexDirection={'row'}
                        pt={SizePixel(36)}
                        pb={SizePixel(36)}
                        pr={SizePixel(12)}
                        borderBottomWidth={'1px'}
                        borderColor={'gray.100'}
                        alignItems={'center'}
                        w={SizePixel(300)}
                        justifyContent={'space-between'}
                    >
                        <Text {...Ar18SbBlack} lineHeight={SizePixel(19.58)} color={'black.100'}>
                            {stringData ?? '-'}
                        </Text>
                        <Pressable onPress={() => editPress()}>
                            <Text {...Ar17M} color={'gray.300'} lineHeight={SizePixel(19.8)}>
                                Edit
                            </Text>
                        </Pressable>
                    </Box>
                );
            case 'Description':
                return (
                    <Box
                        flexDirection={'row'}
                        pt={topPadding}
                        pb={SizePixel(24)}
                        pr={SizePixel(12)}
                        borderBottomWidth={'1px'}
                        borderColor={'gray.100'}
                        w={SizePixel(300)}
                        justifyContent={'space-between'}
                    >
                        <Box maxW={SizePixel(220)}>
                            <Text mb={SizePixel(4)} {...Ar17R} lineHeight={SizePixel(22.07)} color={'gray.300'}>
                                {stringData}
                            </Text>
                        </Box>
                        <Pressable onPress={() => editPress()}>
                            <Text {...Ar17M} color={'gray.300'} lineHeight={SizePixel(19.8)}>
                                Edit
                            </Text>
                        </Pressable>
                    </Box>
                );
            case 'Order':
                return (
                    <Box
                        flexDirection={'row'}
                        pt={topPadding}
                        pb={SizePixel(20)}
                        pr={SizePixel(12)}
                        borderBottomWidth={'1px'}
                        borderColor={'gray.100'}
                        w={SizePixel(300)}
                        justifyContent={'space-between'}
                    >
                        <Box>
                            <Text mb={SizePixel(4)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)} color={'black.100'}>
                                RECAN bag order
                            </Text>
                            {arrayData &&
                                arrayData.map((v, i) => (
                                    <Text mb={SizePixel(4)} {...Ar18M} lineHeight={SizePixel(22.86)} color={'gray.300'}>
                                        {v}
                                    </Text>
                                ))}
                        </Box>
                        <Pressable onPress={() => editPress()}>
                            <Text {...Ar17M} color={'gray.300'} lineHeight={SizePixel(19.8)}>
                                Edit
                            </Text>
                        </Pressable>
                    </Box>
                );
            case 'Pet':
                return (
                    <Box
                        flexDirection={'row'}
                        pt={topPadding}
                        pb={SizePixel(20)}
                        pr={SizePixel(12)}
                        borderBottomWidth={'1px'}
                        borderColor={'gray.100'}
                        w={SizePixel(300)}
                        justifyContent={'space-between'}
                    >
                        <Box>
                            <Text mb={SizePixel(4)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)} color={'black.100'}>
                                Pet on premise
                            </Text>
                            <Text {...Ar17R} lineHeight={SizePixel(22.07)} color={'gray.300'}>
                                {stringData}
                            </Text>
                        </Box>
                        <Pressable onPress={() => editPress()}>
                            <Text {...Ar17M} color={'gray.300'} lineHeight={SizePixel(19.8)}>
                                Edit
                            </Text>
                        </Pressable>
                    </Box>
                );
            case 'Photo':
                return (
                    <Box
                        flexDirection={'row'}
                        pt={topPadding}
                        pb={SizePixel(20)}
                        pr={SizePixel(12)}
                        borderBottomWidth={'1px'}
                        borderColor={'gray.100'}
                        w={SizePixel(300)}
                        justifyContent={'space-between'}
                    >
                        <Box>
                            <Text mb={SizePixel(4)} {...Ar18SbBlack} lineHeight={SizePixel(19.58)} color={'black.100'}>
                                Photo
                            </Text>
                            <Box flexDirection={'row'}>
                                {photoData &&
                                    photoData.map((v, i) => (
                                        <Box
                                            ml={SizePixel(4)}
                                            bg={'#ADADAD'}
                                            width={SizePixel(96)}
                                            h={SizePixel(72)}
                                            overflow={'hidden'}
                                            borderRadius={'8px'}
                                        >
                                            <Img src={v.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </Box>
                                    ))}
                            </Box>
                        </Box>
                        <Pressable onPress={() => editPress()}>
                            <Text {...Ar17M} color={'gray.300'} lineHeight={SizePixel(19.8)}>
                                Edit
                            </Text>
                        </Pressable>
                    </Box>
                );
            default:
                <Box />;
        }
    };
    return (
        <Box pl={SizePixel(4)} pr={SizePixel(12)} flexDirection={'row'}>
            <Box pt={topPadding} mr={SizePixel(24)}>
                <Icon />
            </Box>
            {bodyRender()}
        </Box>
    );
};
