import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { SizePixel } from '../../utils/pixcel.convert';

export const LeftIcon = () => {
    return (
        <Svg width={SizePixel(25)} height={SizePixel(25)} viewBox="0 0 25 25" fill="none">
            <G clipPath="url(#clip0_1022_13125)">
                <Path
                    d="M8.56231 18.872C8.12421 19.3101 8.12421 20.0204 8.56231 20.4585C9.00041 20.8966 9.71071 20.8966 10.1488 20.4585L17.2323 13.375C17.8324 12.7749 17.8324 11.8021 17.2323 11.202L10.1488 4.11847C9.7107 3.68037 9.0004 3.68037 8.5623 4.11847C8.1242 4.55657 8.1242 5.26687 8.5623 5.70497L15.1458 12.2885L8.56231 18.872Z"
                    fill="#4C4C4C"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1022_13125">
                    <Rect width="24" height="24" fill="white" transform="translate(24.939 24.2881) rotate(180)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};
