import React from 'react';
import { Svg, Path } from 'react-native-svg';

const DotHorizon = () => {
    return (
        <Svg width="20" height="6" viewBox="0 0 20 6" fill="none">
            <Path
                d="M15.152 3.15592C15.152 4.48509 16.2395 5.57259 17.5687 5.57259C18.8979 5.57259 19.9854 4.48509 19.9854 3.15592C19.9854 1.82676 18.8979 0.739258 17.5687 0.739258C16.2395 0.739258 15.152 1.82676 15.152 3.15592ZM12.7354 3.15592C12.7354 1.82676 11.6479 0.739257 10.3187 0.739257C8.98952 0.739257 7.90202 1.82676 7.90202 3.15592C7.90202 4.48509 8.98952 5.57259 10.3187 5.57259C11.6479 5.57259 12.7354 4.48509 12.7354 3.15592ZM5.48535 3.15592C5.48535 1.82676 4.39785 0.739257 3.06869 0.739257C1.73952 0.739257 0.652021 1.82676 0.652021 3.15592C0.652021 4.48509 1.73952 5.57259 3.06869 5.57259C4.39785 5.57259 5.48535 4.48509 5.48535 3.15592Z"
                fill="#1C6EBA"
            />
        </Svg>
    );
};

export default DotHorizon;
