import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Pressable, Text } from 'native-base';
import AutoHeightImage from 'react-native-auto-height-image';
import { MapMarker } from '../../assets/icons/map.marker';
import { MapBackIcon } from '../../assets/icons/map.back';
import { Ar16Sb, Ar21R, Ar22M, Ar25SbBlack } from '../../themes/font.style';
import { LongButton } from '../buttons/long.button';
import { useColors } from '../../hooks/useColors';
import CloseIcon from '../../assets/icons/close.icon';
import { Dimensions } from 'react-native';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';
import { GoogleMap, Circle, useJsApiLoader } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../loading/loading.screen';
import { FontPixel } from '../typographys/font.pixel';
import { addressAdd } from '../../store/commonReducer';

interface Props {
    marker: { lat: any; lot: any }[];
}
const RADIUS = 600;
interface Props {
    noAuth?: boolean;
}

const { height, width, fontScale } = Dimensions.get('window');
const LATITUDE_DELTA = 0.011;
const LONGITUDE_DELTA = LATITUDE_DELTA * (width / height);
const mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: false,
    rotateControl: true,
    scrollwheel: true,
    panControl: true,
    scaleControl: true,
    maxZoom: 16,
    minZoom: 13,
    draggable: true,
};
const mapLib: any = ['places'];
const MapAdjust = () => {
    const mapRef = useRef<any>(null);
    const { sizePix } = useWindowDimensions();
    const { addressTemp } = useSelector((state: RootState) => state.common);
    const { lot, lat, address, addressMore, postcode, route, type } = addressTemp;
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI',
        libraries: mapLib,
    });
    const renderRefry = lat !== 0 && lot !== 0;
    const { mainColor, colorScheme } = useColors();
    const [coor, setCoor] = useState({ lat: 0, lot: 0, latitudeDelta: LATITUDE_DELTA, longitudeDelta: LONGITUDE_DELTA });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // useEffect(() => {
    //
    // }, [addressTemp]);
    const moveBack = () => {
        dispatch(addressAdd({ addressTemp: { ...addressTemp, lat: coor.lat, lot: coor.lot } }));
        navigate(-1);
        // if (params.type === 'user') {
        //     // @ts-ignore
        //     navigation.navigate('AddressSearch', { ...params, lat: coor.lat - 0.001, lot: coor.lot });
        // } else {
        // }
    };
    const [map, setMap] = React.useState<any>(null);

    const onLoad = (m) => {
        init(m);
    };
    const init = (m: google.maps.Map) => {
        // @ts-ignore
        setMap(m);
        const latitude = lat ?? 0;
        const longitude = lot ?? 0;
        makeBounds(m, latitude, longitude);
    };

    const makeBounds = (m: google.maps.Map, lat: number, lng: number): void => {
        // eslint-disable-next-line no-undef
        const make = new google.maps.LatLngBounds({ lat, lng }, { lat, lng });
        // eslint-disable-next-line no-undef
        const aosition = new google.maps.LatLng({ lat, lng });
        m.fitBounds(make);
        m.setCenter({ lat, lng });
        setCenter({ lat, lng });
        setCoor({ lat, lot: lng, latitudeDelta: LATITUDE_DELTA, longitudeDelta: LONGITUDE_DELTA });
        setMap(m);
    };

    const mapChnage = () => {
        const latitude = lat ?? 0;
        const longitude = lot ?? 0;
        // eslint-disable-next-line no-undef
        const make = new google.maps.LatLngBounds({ lat: latitude, lng: longitude }, { lat: latitude, lng: longitude });
        map.fitBounds(make);
        map.setCenter({ lat: latitude, lng: longitude });
    };

    const color = colorScheme[50];
    const arePointsNear = (checkPoint, centerPoint, km) => {
        if (!renderRefry) {
            return;
        }
        console.log('checkPoint', checkPoint);
        const ky = 40000 / 360;
        const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
        const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
        const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
        const r = Math.sqrt(dx * dx + dy * dy) <= km + 0.02;
        console.log(r);
        if (r !== disabled) {
            setDisabled(r);
        }
        setCoor({ lat: checkPoint.lat, lot: checkPoint.lng, latitudeDelta: 0.0922, longitudeDelta: 0.0421 });
    };
    const [disabled, setDisabled] = useState(true);

    const disabledColor = 'rgba(225,25,3,0.02)';
    const disabledColor2 = 'rgba(225,25,3,1)';

    const markerRef = useRef();
    const navigation = useNavigate();
    console.log('coor', coor);
    const [load, setLoad] = useState(false);
    const changeMap = (r) => {
        console.log('checkPoint', r);
        arePointsNear({ lat: r.latitude, lng: r.longitude }, { lat, lng: lot }, RADIUS * 0.001);
    };
    const [center, setCenter] = useState({
        lat: 51.512501,
        lng: -0.088137,
    });
    console.log('isLoaded', coor);
    const handleCenterChanged = () => {
        if (map) {
            const newCenter = map.getCenter();
            const newLat = newCenter.lat();
            const newLot = newCenter.lng();
            arePointsNear({ lat: newLat, lng: newLot }, { lat, lng: lot }, RADIUS * 0.001);
        }
    };

    if (renderRefry && isLoaded) {
        // @ts-ignore
        return (
            <Box flex={1} w={'100vw'} bg={'white.100'} alignItems={'center'} h={'100vh'}>
                <Box maxWidth={InitWidthSizeScreen}>
                    <Box w={'100%'} maxWidth={InitWidthSizeScreen} h={sizePix(650)} justifyContent={'center'}>
                        <GoogleMap
                            onCenterChanged={handleCenterChanged}
                            onLoad={onLoad}
                            ref={mapRef}
                            options={mapOptions}
                            mapContainerStyle={{ flex: 1 }}
                            center={center}
                        >
                            {renderRefry && <MapMarker disabled={!disabled} />}
                            {renderRefry && (
                                <Circle
                                    key={(lat ?? '0' + lot ?? 0).toString()}
                                    center={{ lat: lat ?? 0, lng: lot ?? 0 }}
                                    radius={RADIUS}
                                    options={{
                                        fillColor: 'rgba(255,255,255,0)',
                                        strokeWeight: 3,
                                        strokeColor: !disabled ? disabledColor2 : mainColor,
                                    }}
                                />
                            )}
                        </GoogleMap>

                        <Pressable
                            onPress={() => mapChnage()}
                            borderRadius={100}
                            shadow={4}
                            padding={'10px'}
                            position={'absolute'}
                            alignSelf={'flex-end'}
                            bottom={'23px'}
                            right={'23px'}
                            backgroundColor={'#fff'}
                        >
                            <MapBackIcon />
                        </Pressable>
                        <Box
                            padding={'10px'}
                            left={sizePix(28)}
                            top={sizePix(60)}
                            position={'absolute'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                        >
                            <Pressable hitSlop={{ top: 50, left: 50, right: 50, bottom: 50 }} onPress={() => navigation(-1)}>
                                <CloseIcon />
                            </Pressable>
                        </Box>
                        {!disabled && (
                            <Box
                                top={sizePix(60)}
                                position={'absolute'}
                                alignSelf={'center'}
                                borderRadius={100}
                                alignItems={'center'}
                                background={'#E11903'}
                                paddingX={sizePix(20)}
                                paddingY={sizePix(10)}
                            >
                                <FontPixel {...Ar16Sb} color={'#fff'}>
                                    Outside of adjust area
                                </FontPixel>
                            </Box>
                        )}
                    </Box>
                    <Box h={'30%'} paddingX={sizePix(33)} paddingTop={sizePix(22)} backgroundColor={'#fff'}>
                        <FontPixel {...Ar25SbBlack}>Verify the delivery address</FontPixel>
                        <FontPixel mt={'3.88px'} {...Ar21R} lineHeight={sizePix(28)} adjustsFontSizeToFit={false} allowFontScaling={false}>
                            To make it easier for drivers to pick up, move the pin to highlight the proper position.
                        </FontPixel>
                        <LongButton
                            onPress={() => moveBack()}
                            condition={!disabled}
                            mt={sizePix(21)}
                            mb={sizePix(20)}
                            borderRadius={100}
                            shadow={8}
                            w={'100%'}
                        >
                            <Text {...Ar22M} color={'#fff'}>
                                Verify Location
                            </Text>
                        </LongButton>
                    </Box>
                </Box>
                {/*{!load && <LoadingScreen />}*/}
            </Box>
        );
    } else {
        return <LoadingScreen />;
    }
};
export default MapAdjust;
