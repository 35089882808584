import { v4 as uuid } from 'uuid';

export const ProductsArray = [
    {
        key: uuid(),
        image: 'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box1.png?v=1231232',
        slide: [
            'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box1.png?v=1231232',
            'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box11.png?v=1231232',
        ],
        title: 'RECAN bin 80',
        qty: 0,
        amount: 48,
        comp: [
            'Dimensions: 40cm x 27.7cm x 65cm (WxLxH)',
            'Volume: 80 L',
            'Made from corrugated plastic (very light and durable)',
            'Waterproof and non-toxic',
            'Easy assemble and maintenance',
            '100 % recyclable after product lifespan',
            'Holds more than 100-300 containers',
        ],
        detail:
            'Replace (Faulty Product) \n' +
            '\n' +
            'We check the quality of every product prior to delivery / shipment. But if you have received a faulty product, report it to us hello@recan.co within 7 days, we will replace it without any charges including shipping cost (Australia only).   You have to prove it by sending us the photo and copy of invoice in the email. \n' +
            '\n' +
            ' \n' +
            '\n' +
            'Refund (Change of mind)\n' +
            '\n' +
            'In addition to your Statutory Rights, should you change your mind, report it to us at hello@recan.co within 7 days, we will refund providing the following conditions have been met: Product maintains the original condition and unused. Customer shall bear the $20 shipping cost. (Australia only)',
    },
    {
        key: uuid(),
        image: 'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box2.png',
        slide: ['https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box2.png'],

        title: 'RECAN bin 80 x 3',
        qty: 0,
        amount: 96,
        comp: [
            'Dimensions: 40cm x 27.7cm x 65cm (WxLxH)',
            'Volume: 80 L',
            'Made from corrugated plastic (very light and durable)',
            'Waterproof and non-toxic',
            'Easy assemble and maintenance',
            '100 % recyclable',
            'Holds more than 100-300 containers',
        ],
        detail:
            'Replace (Faulty Product) \n' +
            '\n' +
            'We check the quality of every product prior to delivery / shipment. But if you have received a faulty product, report it to us hello@recan.co within 7 days, we will replace it without any charges including shipping cost (Australia only).   You have to prove it by sending us the photo and copy of invoice in the email. \n' +
            '\n' +
            ' \n' +
            '\n' +
            'Refund (Change of mind)\n' +
            '\n' +
            'In addition to your Statutory Rights, should you change your mind, report it to us at hello@recan.co within 7 days, we will refund providing the following conditions have been met: Product maintains the original condition and unused. Customer shall bear the $20 shipping cost. (Australia only)',
    },
    {
        key: uuid(),
        image: 'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box3.png',
        slide: [
            'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box3.png?v=1231232',
            'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box31.png',
        ],

        title: 'RECAN bag x 10',
        qty: 0,
        amount: 10,
        comp: [
            'Dimensions: 69cm x 83cm (WxH)',
            'LDPE and 100% recyclable after use',
            'Waterproof and non-toxic',
            'Fits perfectly into with RECAN Bin',
            'Can hold up to 100 - 300 containers',
            'Strong enough to hold up to 25 kg',
        ],
        detail:
            'Replace (Faulty Product) \n' +
            '\n' +
            'We check the quality of every product prior to delivery / shipment. But if you have received a faulty product, report it to us hello@recan.co within 7 days, we will replace it without any charges including shipping cost (Australia only).   You have to prove it by sending us the photo and copy of invoice in the email. \n' +
            '\n' +
            ' \n' +
            '\n' +
            'Refund (Change of mind)\n' +
            '\n' +
            'In addition to your Statutory Rights, should you change your mind, report it to us at hello@recan.co within 7 days, we will refund providing the following conditions have been met: Product maintains the original condition and unused. Customer shall bear the $20 shipping cost. (Australia only)\n' +
            '\n' +
            '\n',
    },
    {
        key: uuid(),
        image: 'https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box4.png',
        slide: ['https://recandev.s3.ap-southeast-2.amazonaws.com/shop/Box4.png'],

        title: 'RECAN Wall Poster [Digital]',
        qty: 0,
        amount: 2,
        comp: [
            'All proceeds will be donated to local causes supported through RECAN',
            'Provide a clear instruction',
            'Simply download and print as needed',
            'High printing quality image suitable for both A3 and A4 size',
        ],
        detail: '',
    },
];

export type IProductsTypes = typeof ProductsArray;
export type TCreditBrandTypes = 'P' | 'M' | 'A' | 'V' | 'UnCheck';
export type TPayTypes = 'UnCheck' | 'All' | 'With' | 'Pay';

export const ProductAllAmountRender = (value: IProductsTypes) => {
    return value.reduce((acc, cur) => {
        const { amount, qty } = cur;
        const plus = amount * qty;
        console.log('plus', plus);
        return acc + plus;
    }, 0);
};

export const ProductAllQtyRender = (value: IProductsTypes) => {
    return value.reduce((acc, cur) => {
        const { amount, qty } = cur;
        return acc + qty;
    }, 0);
};

export const ProductQtyRender = (value: IProductsTypes) => {
    return value.reduce((acc, cur) => {
        const { amount, qty } = cur;

        const plus = qty > 0 ? qty : 0;
        return acc + plus;
    }, 0);
};
