import React, { useState } from 'react';
import { Box, Button, HStack, Pressable, Text } from 'native-base';
import { ScreenHeight, ScreenWidth, SizePixel } from '../../utils/pixcel.convert';
import BackIcon from '../../assets/icons/back.icon';
import { TouchableOpacity } from 'react-native';
import { Ar18SbBlack, Ar21R, Ar21Sb, Ar36B } from '../../themes/font.style';
import { useColors } from '../../hooks/useColors';
import { StripeForm } from './stripe.form';
import { useNavigate } from 'react-router-dom';
import getStripe from './get.stripe';
import { Elements } from '@stripe/react-stripe-js';
import { PageSetting } from '../../utils/pageSetting';
const PADDING = 32;
export const PaymentAddScreen = () => {
    const { mainColor } = useColors();
    const navigation = useNavigate();
    const [modify, setModify] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <Box bg={'white.100'} w={'100%'} h={'100vh'}>
            <Box {...PageSetting} h={'100%'} w={'100%'}>
                <Box pb={SizePixel(8)}>
                    <HStack
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mt={SizePixel(18.1)}
                        pl={SizePixel(20)}
                        pr={SizePixel(35)}
                    >
                        <Pressable
                            w={SizePixel(32)}
                            h={SizePixel(32)}
                            alignItems={'center'}
                            justifyContent={'center'}
                            onPress={() => navigation(-1)}
                            hitSlop={{ top: 30, left: 30, right: 30, bottom: 30 }}
                        >
                            <BackIcon />
                        </Pressable>
                        {modify && (
                            <Box flexDirection={'row'}>
                                <Pressable mr={SizePixel(28)}>
                                    <Text {...Ar21Sb} lineHeight={SizePixel(22.85)} color={'gray.200'}>
                                        Remove
                                    </Text>
                                </Pressable>
                                <Pressable>
                                    <Text {...Ar21Sb} lineHeight={SizePixel(22.85)} color={mainColor}>
                                        Edit
                                    </Text>
                                </Pressable>
                            </Box>
                        )}
                    </HStack>
                    <Text px={SizePixel(PADDING)} mt={SizePixel(59)} {...Ar36B} lineHeight={SizePixel(39.17)} color={'black.100'}>
                        Add Card
                    </Text>
                </Box>
                {/*<PaymentPaypal />*/}

                <Elements stripe={getStripe()}>
                    <StripeForm setLoading={setLoading} />
                </Elements>
            </Box>

            {/*{!loading ? (*/}
            {/*    <Box flex={1}>*/}
            {/*        <StripeProvider publishableKey={STRIPE_DEMO_KEY} merchantIdentifier="merchant.identifier">*/}
            {/*            <StripeForm setLoading={setLoading} />*/}
            {/*        </StripeProvider>*/}
            {/*    </Box>*/}
            {/*) : (*/}
            {/*<Box flex={1}>*/}
            {/*    <StripeProvider publishableKey={STRIPE_DEMO_KEY} merchantIdentifier="merchant.identifier">*/}

            {/*    <PaymentCheckout />*/}
            {/*        <*/}
            {/*</Box>*/}

            {/*<Box flex={1}>*/}
            {/*    <StripeProvider publishableKey={STRIPE_DEMO_KEY} merchantIdentifier="merchant.identifier">*/}
            {/*        <StripeForm />*/}
            {/*    </StripeProvider>*/}
            {/*</Box>*/}
        </Box>
    );
};
