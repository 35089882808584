import React from 'react';
import { Svg, Path } from 'react-native-svg';

const MenuIcon = () => {
    return (
        <Svg width="34" height="33" viewBox="0 0 34 33" fill="none">
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.28369 8.57689C4.28369 7.70718 4.98876 7.00214 5.8585 7.00214H27.9059C28.7756 7.00214 29.4807 7.70718 29.4807 8.57689C29.4807 9.4466 28.7756 10.1516 27.9059 10.1516H5.8585C4.98876 10.1516 4.28369 9.4466 4.28369 8.57689ZM4.28369 16.4507C4.28369 15.581 4.98876 14.8759 5.8585 14.8759H27.9059C28.7756 14.8759 29.4807 15.581 29.4807 16.4507C29.4807 17.3204 28.7756 18.0254 27.9059 18.0254H5.8585C4.98876 18.0254 4.28369 17.3204 4.28369 16.4507ZM4.28369 24.3244C4.28369 23.4547 4.98876 22.7497 5.8585 22.7497H27.9059C28.7756 22.7497 29.4807 23.4547 29.4807 24.3244C29.4807 25.1942 28.7756 25.8992 27.9059 25.8992H5.8585C4.98876 25.8992 4.28369 25.1942 4.28369 24.3244Z"
                fill="white"
            />
        </Svg>
    );
};

export default MenuIcon;
