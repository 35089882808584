import React, { forwardRef } from 'react';
import { Box, Input, IInputProps } from 'native-base';
import { StyleProp, ViewStyle } from 'react-native';
import useWindowDimensions from '../../hooks/useDimensions';

interface Props extends IInputProps {
    touched?: boolean | any;
    error?: string | any;
    value: any;
    style?: StyleProp<ViewStyle>;
}

const CustomInput = forwardRef<typeof Input, Props>(({ style, value, touched, error, ...props }, ref) => {
    const { sizePix } = useWindowDimensions();
    return (
        <Box
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            borderWidth={1}
            h={sizePix(54)}
            borderRadius={100}
            borderColor={error && !touched ? 'red.100' : 'black.100'}
            px={2}
            pl={4}
            overflow={'hidden'}
            style={style}
        >
            <Input
                backgroundColor={'#00ff0000'}
                value={value}
                borderWidth={0}
                isFullWidth
                width={'100%'}
                maxH={sizePix(54)}
                size="lg"
                {...{ ref }}
                {...props}
                fontSize={sizePix((props?.fontSize as number) ?? 17)}
            />
        </Box>
    );
});

export default CustomInput;
