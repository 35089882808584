import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IProductsTypes } from './temp.const';
import { ScrollView as RNScroll, Image as RNImage } from 'react-native';
import { Box, Pressable, ScrollView, Text, Image, Spinner } from 'native-base';
import { ScreenWidth, SizePixel } from '../../utils/pixcel.convert';
import Svg, { Path } from 'react-native-svg';
import { Ar19M, Ar22M, Ar25SbBlack, Ar28Bold, Ar28SB, La17B, La23N, La27B } from '../../themes/font.style';
import { ProductMinusAndPlus, ProductMinusAndPlus2 } from './plain.icon';
import { MAIN_COLLECTION_PADDING } from './index';
import WebSheet from '../../components/bottomSheet/web.sheet';
import { ScrollViewProps } from 'react-native';
import { LongButton } from '../../components/buttons/long.button';
import { BottomButton } from './bottom.button';
import { NumberFixed } from '../../utils/helper/number.helper';
import { useColors } from '../../hooks/useColors';

interface Props {
    isOpen: boolean;
    product: IProductsTypes[number];
    index: number;
    updateProduct: (type: 'up' | 'down' | 'step', index, value?: number) => void;
    onClose: Dispatch<SetStateAction<boolean>>;
}

const ProductDetailCloseIcon = () => {
    return (
        <Svg width={SizePixel(15)} height={SizePixel(15)} viewBox="0 0 15 15" fill="none">
            <Path d="M1.70117 1.70605L13.6411 13.6458" stroke="black" strokeWidth="2.5" />
            <Path d="M13.6406 1.70605L1.70068 13.6458" stroke="black" strokeWidth="2.5" />
        </Svg>
    );
};

export const ProductDetail = ({ product, index, updateProduct, onClose, isOpen }: Props) => {
    const scrollRef = useRef<RNScroll>(null);
    const [value, setValue] = useState<IProductsTypes[number] | null>(null);
    const [webOpen, setWebOpen] = useState(false);
    const [sliderIndex, setSliderIndex] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (product) {
            initProduction();
        }
    }, [product, isOpen]);

    const initProduction = () => {
        setLoading(true);
        setValue(null);
        setSliderIndex(0);
        scrollRef.current?.scrollTo({ x: 0, y: 0, animated: false });
        setValue({ ...product });
        setTimeout(() => {
            setLoading(false);
        }, 500);
        // carouselRef.current?.snapToItem(0, false);
    };

    const renderItem = useCallback(
        ({ item, index }) => {
            console.log('HART');
            return (
                <Box w={ScreenWidth} h={SizePixel(356)}>
                    <Image
                        alt={product.key as string}
                        source={{ uri: item }}
                        style={{ width: '100%', height: '100%' }}
                        resizeMode={'cover'}
                    />
                </Box>
            );
        },
        [product]
    );
    const updateQtyInSheet = (type) => {
        if (value) {
            if (type === 'up') {
                const temp = { ...value };
                temp.qty = temp.qty + 1;
                setValue({ ...temp });
            } else {
                if (value.qty > 0) {
                    const temp = { ...value };
                    temp.qty = temp.qty - 1;
                    setValue({ ...temp });
                }
            }
        }
    };
    const topSize = SizePixel(50.22);

    const closeButtonTopSize = SizePixel(20);
    const { mainColor } = useColors();
    return (
        <>
            {value && (
                <>
                    <Box h={SizePixel(80)} position={'absolute'} w={'100%'} zIndex={101}>
                        <Box
                            backgroundColor={'#FFF'}
                            h={SizePixel(64)}
                            w={'100%'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            style={{ backgroundColor: '#fff' }}
                            shadow={4}
                        >
                            <Pressable
                                zIndex={100}
                                _pressed={{ opacity: 0.5 }}
                                onPress={() => onClose(false)}
                                position={'absolute'}
                                w={SizePixel(40)}
                                h={SizePixel(40)}
                                justifyContent={'center'}
                                alignItems={'center'}
                                borderRadius={100}
                                bg={'gray.250'}
                                top={SizePixel(12)}
                                left={SizePixel(20)}
                            >
                                <ProductDetailCloseIcon />
                            </Pressable>
                            <Text {...Ar25SbBlack} lineHeight={SizePixel(27.2)} alignSelf={'center'}>
                                {product.title}
                            </Text>
                        </Box>
                    </Box>
                    <Box ref={scrollRef} zIndex={90} style={{ borderTopRightRadius: 14, borderTopLeftRadius: 14 }}>
                        <Pressable
                            zIndex={100}
                            _pressed={{ opacity: 0.5 }}
                            onPress={() => onClose(false)}
                            position={'absolute'}
                            w={SizePixel(40)}
                            h={SizePixel(40)}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={100}
                            bg={'gray.250'}
                            top={SizePixel(20)}
                            left={SizePixel(20)}
                        >
                            <ProductDetailCloseIcon />
                        </Pressable>
                        {loading ? (
                            <Box w={ScreenWidth} h={SizePixel(356)} bg={'gray.100'} alignItems={'center'} justifyContent={'center'}>
                                <Spinner color={mainColor} />
                            </Box>
                        ) : (
                            <div />
                            // <Carousel
                            //     initialNumToRender={0}
                            //     ref={carouselRef}
                            //     activeSlideOffset={0}
                            //     onSnapToItem={(slideIndex) => setSliderIndex(slideIndex)}
                            //     loop={false}
                            //     inactiveSlideScale={1}
                            //     inactiveSlideOpacity={1}
                            //     data={value.slide}
                            //     renderItem={renderItem}
                            //     sliderWidth={ScreenWidth}
                            //     itemWidth={ScreenWidth}
                            //     itemHeight={SizePixel(356)}
                            //     style={{ zIndex: 50 }}
                            //     containerCustomStyle={{ zIndex: 50 }}
                            // />
                        )}

                        <Box mt={SizePixel(17.68)} w={'100%'} flexDirection={'row'} justifyContent={'center'}>
                            {product.slide.length > 1 &&
                                product.slide.map((v, i) => (
                                    <Box
                                        style={{
                                            backgroundColor: i === (sliderIndex as number) ? '#6C6C6C' : '#D0D0D0',
                                            width: SizePixel(8.66),
                                            height: SizePixel(8.66),
                                            borderRadius: 4,
                                            marginLeft: 4,
                                            marginRight: 4,
                                            marginBottom: 4,
                                        }}
                                    />
                                ))}
                        </Box>
                        <Box
                            mt={SizePixel(25.52)}
                            ml={SizePixel(32)}
                            mr={SizePixel(40)}
                            pb={SizePixel(21.52)}
                            borderBottomWidth={'2px'}
                            borderColor={'#F3F3F3'}
                        >
                            <Text {...Ar28SB} color={'black.100'} lineHeight={SizePixel(30.46)} h={SizePixel(29.52)}>
                                {value.title}
                            </Text>
                            <Box
                                h={SizePixel(40)}
                                mt={SizePixel(21.51)}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Box height={'100%'} flexDirection={'row'}>
                                    <Pressable
                                        onPress={() => updateQtyInSheet('down')}
                                        height={'100%'}
                                        w={SizePixel(40)}
                                        borderTopLeftRadius={SizePixel(10)}
                                        borderBottomLeftRadius={SizePixel(10)}
                                        borderWidth={'1px'}
                                        borderColor={'gray.200'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        bg={'white.100'}
                                        _pressed={{ bg: 'gray.200' }}
                                    >
                                        {({ isHovered, isFocused, isPressed }) => {
                                            return <ProductMinusAndPlus2 type={'m'} color={isPressed ? '#FFF' : '#A6A6A6'} />;
                                        }}
                                    </Pressable>
                                    <Box
                                        borderColor={'gray.200'}
                                        w={SizePixel(52)}
                                        borderLeftWidth={0}
                                        borderRightWidth={0}
                                        borderWidth={'1px'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                    >
                                        <Text {...La17B} color={'black.100'} lineHeight={SizePixel(26.35)}>
                                            {value.qty}
                                        </Text>
                                    </Box>
                                    <Pressable
                                        onPress={() => updateQtyInSheet('up')}
                                        height={'100%'}
                                        w={SizePixel(40)}
                                        borderTopRightRadius={SizePixel(10)}
                                        borderBottomRightRadius={SizePixel(10)}
                                        borderWidth={'1px'}
                                        borderColor={'gray.200'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        bg={'white.100'}
                                        _pressed={{ bg: 'gray.200' }}
                                    >
                                        {({ isHovered, isFocused, isPressed }) => {
                                            return <ProductMinusAndPlus2 type={'p'} color={isPressed ? '#FFF' : '#A6A6A6'} />;
                                        }}
                                    </Pressable>
                                </Box>
                                <Box>
                                    <Text {...La27B} lineHeight={SizePixel(32.4)}>
                                        ${value.amount}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={SizePixel(23.1)} w={'100%'} px={SizePixel(32)}>
                            <Box>
                                {value.comp.map((v) => (
                                    <Text {...Ar19M}>○ {v}</Text>
                                ))}
                            </Box>
                            <Text {...Ar19M}>
                                {'\n'}
                                {value.detail}
                            </Text>
                            <Pressable onPress={() => setWebOpen(true)}>
                                <Text {...Ar19M}>
                                    {'\n'}
                                    {'Replace & Refund Policy'}
                                </Text>
                            </Pressable>
                        </Box>
                        <Box h={SizePixel(300)} />
                    </Box>
                    {value.qty !== product.qty && value.qty > 0 && (
                        <BottomButton
                            onPress={() => {
                                updateProduct('step', index, value?.qty);
                                onClose(false);
                            }}
                        >
                            <Text {...Ar22M} color={'white.100'}>
                                {`${value.qty - product.qty > 0 ? 'Add' : 'Remove'} ${Math.abs(
                                    value.qty - product.qty
                                )} to cart $${NumberFixed(value.qty * value.amount)}`}
                            </Text>
                        </BottomButton>
                    )}
                </>
            )}
            <WebSheet open={webOpen} setOpen={setWebOpen} url={'https://www.recan.shop/store-policy'} />
        </>
    );
};
