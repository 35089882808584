import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import useAxiosServices from '../hooks/axiosHooks';
import { Box, Center, Spinner, Text, useToast } from 'native-base';
import useAuth from '../hooks/useAuth';
import { useRoutes } from 'react-router-dom';
import AuthNavigation from './auth.navigation';
import UserNavigation from './user.navigation';
import ChangeNavigation from './change.navigation';
const RootNavigation = () => {
    return useRoutes([ChangeNavigation, UserNavigation, AuthNavigation]);
};
//
// {isLoading && (
//     <Center
//         style={[StyleSheet.absoluteFillObject, { width: '100%', height: '100vh', backgroundColor: 'rgba(255,255,255,0.4)' }]}
//     >
//         <Spinner size="lg" color="blue.200" />
//     </Center>
// )}

export default RootNavigation;
