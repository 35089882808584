import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { SizePixel } from '../../utils/pixcel.convert';
import { CollectionPageType, MAIN_COLLECTION_PADDING_V2 } from './index';
import { Box, Text } from 'native-base';
import { Ar36B } from '../../themes/font.style';
import DefaultDown from '../../components/dropdown/default.down';
import { TransactionPickupCase } from '../../etc/bookStatusCase';
interface Props {
    pickType: number;
    typePickHandler: (index: number, typeValue: any) => void;
    setPageType: Dispatch<SetStateAction<CollectionPageType>>;
}
export const RegularCollectionScreen = ({ pickType, typePickHandler, setPageType }: Props) => {
    useEffect(() => {
        setPageType('Regular');
    }, []);
    return (
        <Box px={SizePixel(MAIN_COLLECTION_PADDING_V2)} mt={SizePixel(64.37)}>
            <Box mb={SizePixel(41)}>
                <Text {...Ar36B} color={'black.100'}>
                    Regular Pickup?
                </Text>
            </Box>
            <Box>
                <DefaultDown
                    placeholder={''}
                    defaultButtonText={'Regular pickup'}
                    defaultValueByIndex={pickType}
                    data={[1, 2, 3]}
                    index={pickType}
                    value={pickType}
                    switchAction={TransactionPickupCase}
                    bagValueHandler={typePickHandler}
                />
            </Box>
        </Box>
    );
};
