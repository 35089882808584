import React from 'react';
import { Svg, Path } from 'react-native-svg';

const ShareMore = () => {
    return (
        <Svg width="41" height="41" viewBox="0 0 41 41" fill="none">
            <Path
                d="M20.501 24.3201C22.3419 24.3201 23.8343 22.8277 23.8343 20.9868C23.8343 19.1458 22.3419 17.6534 20.501 17.6534C18.66 17.6534 17.1677 19.1458 17.1677 20.9868C17.1677 22.8277 18.66 24.3201 20.501 24.3201Z"
                fill="white"
            />
            <Path
                d="M7.16762 24.3201C9.00857 24.3201 10.501 22.8277 10.501 20.9868C10.501 19.1458 9.00857 17.6534 7.16762 17.6534C5.32667 17.6534 3.83429 19.1458 3.83429 20.9868C3.83429 22.8277 5.32667 24.3201 7.16762 24.3201Z"
                fill="white"
            />
            <Path
                d="M33.8343 24.3201C35.6753 24.3201 37.1676 22.8277 37.1676 20.9868C37.1676 19.1458 35.6753 17.6534 33.8343 17.6534C31.9934 17.6534 30.501 19.1458 30.501 20.9868C30.501 22.8277 31.9934 24.3201 33.8343 24.3201Z"
                fill="white"
            />
        </Svg>
    );
};

export default ShareMore;
