import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Center,
    Input,
    Stack,
    IconButton,
    Button,
    HStack,
    Divider,
    ScrollView,
    Image,
    Heading,
    useToast,
    Pressable,
} from 'native-base';
import PasswordViewIcon from '../../assets/icons/passwordView.icon';
import GoogleIcon from '../../assets/icons/google.icon';
import FaceBookIcon from '../../assets/icons/facebook.icon';
import AppleIcon from '../../assets/icons/apple.icon';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ErrorHelp from '../../components/customInput/error.help';
import CustomInput from '../../components/customInput/defaultInput';
import DefaultHeader from '../header/header.default';
import SelectAccountSheet from '../../components/bottomSheet/selectAccount.sheet';
import useAxiosServices from '../../hooks/axiosHooks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, profileSetting } from '../../store/authReducer';
import { Ar36B } from '../../themes/font.style';
import { Dimensions } from 'react-native';
import jwtDecode from 'jwt-decode';
import { RootState } from '../../store';
import JoinSelect from '../../components/bottomSheet/join.select';
import { loadingEndAction } from '../../store/commonReducer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleLogin from 'react-google-login';
import PaypalLogin from '../../components/bottomSheet/paypal.sheet';
import { Img } from 'react-image';

const SingInSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').min(4, 'email Too short').required('Please enter your email'),
    password: Yup.string()
        .min(8, 'This password is too short. It must contain at least 8 characters.')
        .required('Please enter your password'),
});
import paypalIcon from '../../assets/icons/paypal.png';
import AppleLogin from 'react-apple-login';
import { MAXWIDTH } from '../../themes';
import useWindowDimensions from '../../hooks/useDimensions';
import { FontPixel } from '../../components/typographys/font.pixel';
import { InitWidthSizeScreen } from '../../utils/pixcel.convert';
import { PageSetting } from '../../utils/pageSetting';
const passwordView = require('../../assets/icons/password.png');
const { width } = Dimensions.get('window');
const LoginSignIn = () => {
    const { appToken } = useSelector((state: RootState) => state.auth);
    const { handleChange, handleBlur, handleSubmit, errors, touched, initialValues, resetForm, values } = useFormik({
        validationSchema: SingInSchema,
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (value) => {
            await joinHandler({ id: value.email, email: '', password: value.password });
        },
    });
    const navigation = useNavigate();
    const { axiosService } = useAxiosServices();
    const dispatch = useDispatch();

    const joinHandler = async (value: { id: string; email: string; password: string }) => {
        const { email, password, id } = value;
        try {
            const api = await axiosService.post('/users/login', { username: id, password });
            const { data } = api.data;
            const { result, accessToken, refreshToken, user } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', { appToken });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigation('/main', { replace: true });
            } else {
                toast('error');
            }
        } catch (e) {
            console.log(e);
            toast('Check your Email and password');
        }
    };

    const [view, setView] = useState(false);

    useEffect(() => {}, []);

    const loginHandler = async (value: { id; email: string; password: string }, type) => {
        const { id, email, password } = value;
        try {
            const api = await axiosService.post('/users/login/sns', { username: id, password });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', { appToken });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigation('/main', { replace: true });
            } else {
                toast('error');
            }
        } catch (e) {
            setSelectOpen(true);
            setTempEmail(email);
            setTempId(id);
            setTempType(type);
        }
    };

    const loginJoinHandler = async (value: { email: string; password: string }) => {
        const { email, password } = value;
        try {
            const api = await axiosService.post('/users/login/sns', { username: email, password });
            const { data } = api.data;
            const { accessToken, refreshToken } = data;
            await AsyncStorage.setItem('accessToken', accessToken);
            await AsyncStorage.setItem('refreshToken', refreshToken);
            const getProfile = await axiosService.post('/users/app/profile', { appToken });
            const { data: profileData, status: profileStatus } = getProfile.data;
            if (profileStatus) {
                dispatch(profileSetting({ user: profileData, userRole: profileData.userRole }));
                dispatch(loginAction());
                navigation('/main', { replace: true });
            } else {
                toast('error');
            }
        } catch (e) {
            console.log(e);
            toast('Not a registered member. Please sign up and proceed.');
        }
    };

    const [tempEmail, setTempEmail] = useState('');
    const [tempId, setTempId] = useState('');
    const [tempType, setTempType] = useState('1');
    const [selectOpen, setSelectOpen] = useState(false);
    const openAndJoin = async (accountType) => {
        const confrim = selectOpen;
        if (confrim) {
            dispatch(loadingEndAction());
            setSelectOpen(false);
            await join(tempId, tempEmail, tempType, accountType);
        }
    };

    const join = async (id: any, email, type, accountType) => {
        try {
            const api = await axiosService.post('/users/join/simple/sns', {
                email: email,
                password: id,
                id: id,
                type,
                accountType,
            });
            const { status, data } = api;
            if (!status) {
                toast(data);
            } else {
                await loginJoinHandler({ email: id, password: id });
            }
        } catch (e) {
            toast('error');
        } finally {
            setSelectOpen(false);
        }
    };

    const snsLoginWithGoogle = async (r) => {
        try {
            console.log(r);
            // console.log(r);
            const { googleId, profileObj } = r;
            const { email, name } = profileObj;
            if (googleId) {
                try {
                    console.log(googleId);
                    await loginHandler({ id: googleId, email, password: googleId } as any, 2);
                } catch (e) {}
            }
            // await GoogleSignin.hasPlayServices();
            // const userInfo = await GoogleSignin.signIn();
            // const {id, email} = userInfo.user;
            // await loginHandler({id, email, password: id}, 2);
        } catch (error) {
            console.log(error);
            // if (error.code === statusCodes.SIGN_IN_CANCELLED) {
            //   // user cancelled the login flow
            // } else if (error.code === statusCodes.IN_PROGRESS) {
            //   // operation (e.g. sign in) is in progress already
            // } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
            //   // play services not available or outdated
            // } else {
            //   // some other error happened
            // }
        }
    };

    const snsLoginWithApple = async (r) => {
        console.log('CODE', r);
        if (r && r.hasOwnProperty('authorization')) {
            const { authorization } = r;
            const { id_token, code } = authorization;
            const decodedToken: any = jwtDecode(id_token);
            const id = decodedToken.sub;
            const mail = decodedToken.email ?? '';
            console.log(id, mail);
            await loginHandler({ id: id, email: mail, password: id }, 2);
        }
    };

    const [paypalOpen, setPaypalOpen] = useState(false);
    const responseGoogle = (res) => {
        console.log(res);
    };
    const { sizePix: SizePixel, height } = useWindowDimensions();

    return (
        <>
            <Box flex={1} bg={'white.100'} alignItems={'center'}>
                <DefaultHeader navigation={navigation} bg={'white.100'} />
                <ScrollView contentContainerStyle={{ flex: 1 }} w={'100%'} {...PageSetting}>
                    <Box
                        flex={1}
                        px={SizePixel(28)}
                        maxW={InitWidthSizeScreen}
                        safeAreaBottom
                        flexGrow={1}
                        justifyContent={'space-between'}
                    >
                        <Box mb={SizePixel(122)} mt={SizePixel(28)} alignItems={'center'}>
                            <FontPixel {...Ar36B} color={'black.100'}>
                                Sign in
                            </FontPixel>
                        </Box>
                        <Center>
                            <Box width={'100%'}>
                                <ErrorHelp errors={errors} />
                                <CustomInput
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChangeText={handleChange('email')}
                                    placeholder="Email"
                                />
                                <Box mb={SizePixel(16)} />
                                <CustomInput
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                    onChangeText={handleChange('password')}
                                    type={view ? 'text' : 'password'}
                                    InputRightElement={
                                        <IconButton
                                            onPress={() => setView((p) => !p)}
                                            _pressed={{ bg: 'transfer', opacity: 0.5 }}
                                            icon={<PasswordViewIcon color={'#ACACAC'} boolean={view} />}
                                        />
                                    }
                                    placeholder="Password"
                                />
                                <Box mb={SizePixel(16)} />
                                <Box alignItems={'flex-end'}>
                                    <Pressable onPress={() => navigation('/passwordFind')}>
                                        <Text fontFamily={'Arch'} fontWeight={'700'} underline fontSize={'12px'}>
                                            Forgot your password?
                                        </Text>
                                    </Pressable>
                                </Box>
                            </Box>
                        </Center>
                        <Box flex={1} mt={SizePixel(71)}>
                            <Box width={'100%'}>
                                <Button
                                    h={SizePixel(53)}
                                    my={2}
                                    colorScheme={'blue.200'}
                                    onPress={() => handleSubmit()}
                                    variant={'basicButton'}
                                >
                                    <HStack alignItems={'center'}>
                                        <Box alignItems={'center'} width={'100%'}>
                                            <FontPixel color={'white.100'} fontFamily={'Arch'} fontWeight={'700'} fontSize={20}>
                                                Sign in
                                            </FontPixel>
                                        </Box>
                                    </HStack>
                                </Button>
                                <Box
                                    mt={SizePixel(34)}
                                    mb={SizePixel(22)}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    width={'100%'}
                                >
                                    <Divider bg={'black.100'} mx="1" orientation={'horizontal'} width={'30%'} borderWidth={0.5} />
                                    <Box>
                                        <FontPixel fontFamily={'Arch'} fontWeight={400} fontSize={15}>
                                            Or Sign in with
                                        </FontPixel>
                                    </Box>
                                    <Divider bg={'black.100'} mx="1" orientation={'horizontal'} width={'30%'} borderWidth={0.5} />
                                </Box>
                                <Stack
                                    space={4}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                >
                                    <GoogleLogin
                                        clientId="1053774717511-r8ojjb66ujb9nfaomi825suv37vkgi99.apps.googleusercontent.com"
                                        onSuccess={snsLoginWithGoogle}
                                        onFailure={responseGoogle}
                                        onScriptLoadFailure={(error) => console.log('ERROR', error)}
                                        uxMode={'popup'}
                                        render={(r) => (
                                            <Button
                                                w={'31%'}
                                                h={'56px'}
                                                my={2}
                                                colorScheme={'blue.200'}
                                                onPress={() => r.onClick()}
                                                variant={'basicButton'}
                                            >
                                                <HStack alignItems={'center'}>
                                                    <GoogleIcon />
                                                </HStack>
                                            </Button>
                                        )}
                                    />

                                    <PaypalLogin
                                        render={(r) => (
                                            <Button
                                                w={'30%'}
                                                my={2}
                                                colorScheme={'blue.300'}
                                                onPress={() => r.open()}
                                                variant={'basicButton'}
                                            >
                                                <HStack alignItems={'center'}>
                                                    <Img
                                                        style={{ objectFit: 'contain', marginTop: 1 }}
                                                        height={'30px'}
                                                        width={'30px'}
                                                        src={paypalIcon}
                                                        alt={'paypalIcon'}
                                                    />
                                                </HStack>
                                            </Button>
                                        )}
                                    />
                                    <AppleLogin
                                        clientId="com.recan.web"
                                        redirectURI="https://recan-app.mirable.cc/signIn"
                                        scope="email"
                                        usePopup={true}
                                        callback={(r) => {
                                            snsLoginWithApple(r);
                                        }}
                                        render={(r) => (
                                            <Button
                                                w={'30%'}
                                                my={2}
                                                colorScheme={'black.100'}
                                                onPress={() => r.onClick()}
                                                variant={'basicButton'}
                                            >
                                                <HStack mb={1} alignItems={'center'}>
                                                    <AppleIcon />
                                                </HStack>
                                            </Button>
                                        )}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </ScrollView>
                <Flex mb={SizePixel(52)} top={2}>
                    <Box justifyContent={'flex-end'}>
                        <Box justifyContent={'center'} alignContent={'center'} flexDirection={'row'}>
                            <FontPixel fontFamily={'Arch'} fontWeight={'100'} fontSize={15}>
                                Don't have an account?
                            </FontPixel>
                            <Pressable onPress={() => navigation('/signUp')}>
                                <FontPixel fontFamily={'Arch'} fontWeight={'700'} fontSize={15} ml={1} color={'black.100'}>
                                    Sign up
                                </FontPixel>
                            </Pressable>
                        </Box>
                    </Box>
                </Flex>
            </Box>
            <JoinSelect open={selectOpen} onHandler={setSelectOpen} inputHandler={openAndJoin} value={tempType} />
        </>
    );
};

export default LoginSignIn;
