import React from 'react';
import { Box, Center, Image, Text, VStack, WarningIcon } from 'native-base';
import { Dimensions } from 'react-native';
import { La15N, La15Sb, La28B } from '../../themes/font.style';
import WasteIcon from '../../assets/icons/waste.icon';
import { SizePixel } from '../../utils/pixcel.convert';
import useWindowDimensions from '../../hooks/useDimensions';

interface Props {
    Icon: any;
    value: string;
    subTitle: string;
}
const { width, height } = Dimensions.get('window');
const DashboardCard = ({ Icon, value, subTitle }: Props) => {
    const { sizePix } = useWindowDimensions();
    return (
        <VStack h={sizePix(170)} w={sizePix(170)} justifyContent={'center'} alignItems={'center'}>
            {Icon}
            <Box justifyContent={'center'} alignItems={'center'} mt={sizePix(13.07)}>
                <Text {...La28B} color={'gray.300'}>
                    {value}
                </Text>
                <Text {...La15Sb} numberOfLines={2} textAlign={'center'} color={'black.100'} mt={sizePix(5)}>
                    {subTitle}
                </Text>
            </Box>
        </VStack>
    );
};

export default DashboardCard;
