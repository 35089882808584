import React from 'react';
import { Svg, Path } from 'react-native-svg';

const ShareText = () => {
    return (
        <Svg width="35" height="36" viewBox="0 0 35 36" fill="none">
            <Path
                d="M17.5 0.486816C7.85 0.486816 0 7.21557 0 15.4868C0.0366101 17.7209 0.603552 19.9144 1.65411 21.8864C2.70467 23.8584 4.20878 25.5526 6.0425 26.8293L5.0125 34.0606C4.97655 34.3133 5.01883 34.5709 5.13366 34.7989C5.24848 35.0269 5.43034 35.2142 5.6548 35.3358C5.87925 35.4573 6.13554 35.5072 6.3892 35.4788C6.64287 35.4504 6.88176 35.345 7.07375 35.1768L13 29.9868C14.477 30.3187 15.9862 30.4864 17.5 30.4868C27.15 30.4868 35 23.7581 35 15.4868C35 7.21557 27.15 0.486816 17.5 0.486816ZM17.5 27.9868C15.9743 27.989 14.4545 27.7966 12.9775 27.4143C12.7802 27.3628 12.5732 27.3604 12.3747 27.4072C12.1762 27.4541 11.9922 27.5488 11.8387 27.6831L7.96375 31.0768L8.62625 26.4256C8.65942 26.193 8.62635 25.9557 8.53084 25.741C8.43532 25.5263 8.28124 25.3429 8.08625 25.2118C6.42049 24.1825 5.03745 22.7544 4.06213 21.0564C3.0868 19.3585 2.54992 17.4443 2.5 15.4868C2.5 8.59432 9.22875 2.98682 17.5 2.98682C25.7712 2.98682 32.5 8.59432 32.5 15.4868C32.5 22.3793 25.7712 27.9868 17.5 27.9868Z"
                fill="white"
            />
        </Svg>
    );
};

export default ShareText;
