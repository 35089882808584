import React, { createContext, useEffect, useReducer } from 'react';

// third-party

import jwtDecode from 'jwt-decode';

// reducer - state management
import { logoutAction, profileSetting, loginAction } from '../store/authReducer';

// project imports
import { userTypes } from '../@types/userTypes';
import useAxiosServices from '../hooks/axiosHooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RootState } from '../store';
import { Center, Spinner } from 'native-base';
import { StyleSheet } from 'react-native';
import { LoadingScreen } from '../components/loading/loading.screen';

const setSession = (serviceToken?: string | null) => !!serviceToken;

export type JWTContextType = {
    isLogin: boolean;
    isLoading: boolean;
    user?: userTypes | null | undefined;
    logout: () => void;
    login: (value: { id: string; email: string; password: string }) => Promise<void>;
    loginSNS: (value: { id: string; email: string; password: string }) => Promise<void>;
    updateProfile: () => void;
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const navigate = useNavigate();
    const { isLoading } = useSelector((state: RootState) => state.common);
    const { isLogin } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const logout = async () => {
        setSession(null);
        await AsyncStorage.removeItem('accessToken');
        navigate('login', { replace: true });
        dispatch(logoutAction());
    };
    const { axiosService, serviceToken } = useAxiosServices();
    const [query] = useSearchParams();
    const moveAccountChange = async () => {
        const id = query.get('id');
        const change = query.get('setting') === 'change';
        // ?setting=change&id=63312afc070ae849506fa412 chlee
        // ?setting=change&id=63312e14070ae849506fa415 naver

        if (change) {
            navigate('/accountLoginCheck', { state: id });
        } else {
            await logout();
        }
    };
    useEffect(() => {
        const init = async () => {
            try {
                const token = await AsyncStorage.getItem('accessToken');
                if (token) {
                    dispatch(loginAction());
                    await updateProfile();
                    navigate('/main', { replace: true });
                } else {
                    await moveAccountChange();
                    console.log('s', token);
                }
            } catch (err) {
                console.error(err);
                await logout();
            }
        };

        init();
    }, []);

    const login = async (value: { id: string; email: string; password: string }) => {
        const { id, email, password } = value;
        const response = await axiosService.post('/users/login', { username: id, password });
        const { data } = response.data;
        const { result, accessToken, refreshToken, user } = data;
        await AsyncStorage.setItem('accessToken', accessToken);
        await AsyncStorage.setItem('refreshToken', refreshToken);
        dispatch(loginAction());
        await updateProfile();
    };

    const loginSNS = async (value: { id: string; email: string; password: string }) => {
        const { id, email, password } = value;
        const response = await axiosService.post('/users/login/sns', { username: id, password });
        const { data } = response.data;
        const { result, accessToken, refreshToken, user } = data;
        await AsyncStorage.setItem('accessToken', accessToken);
        await AsyncStorage.setItem('refreshToken', refreshToken);
        dispatch(loginAction());
        await updateProfile();
    };

    // const register = async (email: string, password: string, firstName: string, lastName: string) => {
    //     console.log('register...');
    // };

    // const resetPassword = (email: string) => console.log(email);

    const updateProfile = async () => {
        try {
            const response = await axiosService.post('/users/app/profile');
            const { data } = response.data;
            dispatch(profileSetting({ user: data, userRole: data.userRole }));
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <JWTContext.Provider value={{ isLogin, isLoading, login, logout, loginSNS, updateProfile }}>
            <>
                {children}
                {isLoading && <LoadingScreen />}
            </>
        </JWTContext.Provider>
    );
};

export default JWTContext;
