import React from 'react';
// import { Canvas, ImageSVG, Skia, useSVG } from '@shopify/react-native-skia';
import { useColors } from '../../hooks/useColors';
import styled from 'styled-components';
import { Box } from 'native-base';
interface Props {
    disabled?: boolean;
    width?: number;
    height?: number;
}
const MarkerBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    border-radius: 100%;
    user-select: none;

    &:hover {
        z-index: 1;
    }
`;
export const MapMarker = ({ disabled, width, height }: Props) => {
    // const svg = useSVG(image);
    const disabledColor = '#E11903';
    const { mainColor } = useColors();

    // console.log('svg = ', s);
    return (
        <MarkerBox>
            <div style={{ position: 'absolute', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <svg width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_905_9278)">
                        <path
                            d="M37.8312 10.111C36.5042 7.44802 34.5408 5.16725 32.1215 3.47845C29.7022 1.78965 26.9046 0.746993 23.9859 0.446345C22.6656 0.311505 21.3353 0.311505 20.015 0.446345C17.0964 0.74719 14.2988 1.78981 11.8794 3.47842C9.45999 5.16702 7.49631 7.44745 6.1688 10.1102C4.49858 13.5076 3.85345 17.3322 4.31465 21.1021C4.77585 24.8721 6.32276 28.4188 8.76055 31.2956L20.6481 46.0066C20.8123 46.2099 21.0187 46.3736 21.2524 46.486C21.4862 46.5984 21.7415 46.6567 22 46.6567C22.2585 46.6567 22.5138 46.5984 22.7476 46.486C22.9813 46.3736 23.1877 46.2099 23.3519 46.0066L35.2395 31.2956C37.6772 28.419 39.2241 24.8724 39.6853 21.1026C40.1465 17.3328 39.5014 13.5084 37.8312 10.111ZM22 25.2812C20.6155 25.2812 19.2622 24.8634 18.111 24.0805C16.9599 23.2976 16.0627 22.1848 15.5328 20.8829C15.003 19.5809 14.8644 18.1483 15.1345 16.7662C15.4046 15.3841 16.0713 14.1146 17.0503 13.1181C18.0292 12.1217 19.2765 11.4431 20.6344 11.1681C21.9922 10.8932 23.3997 11.0343 24.6788 11.5736C25.9579 12.1129 27.0511 13.0261 27.8203 14.1978C28.5895 15.3695 29 16.747 29 18.1562C28.9978 20.0452 28.2596 21.8562 26.9474 23.1919C25.6351 24.5276 23.8559 25.279 22 25.2812V25.2812Z"
                            fill={disabled ? disabledColor : mainColor}
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_905_9278"
                            x="0.169708"
                            y="0.345215"
                            width="43.6606"
                            height="54.3115"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_905_9278" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_905_9278" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </MarkerBox>
    );
    // return <Canvas style={{ flex: 1, width: 44, height: 52 }}>{s && <ImageSVG svg={s} x={0} y={0} width={44} height={52} />}</Canvas>;
};
