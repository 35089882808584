import React from 'react';
import { Svg, Path } from 'react-native-svg';

interface Props {
    color?: string;
}

const ErrorIcon = ({ color }: Props) => {
    console.log(color);
    return (
        <Svg width="19" height="19" viewBox="0 0 19 19" fill="none">
            <Path
                d="M8.91602 11.3359H10.416V12.8359H8.91602V11.3359ZM8.91602 5.33594H10.416V9.83594H8.91602V5.33594ZM9.65852 1.58594C5.51852 1.58594 2.16602 4.94594 2.16602 9.08594C2.16602 13.2259 5.51852 16.5859 9.65852 16.5859C13.806 16.5859 17.166 13.2259 17.166 9.08594C17.166 4.94594 13.806 1.58594 9.65852 1.58594ZM9.66602 15.0859C6.35102 15.0859 3.66602 12.4009 3.66602 9.08594C3.66602 5.77094 6.35102 3.08594 9.66602 3.08594C12.981 3.08594 15.666 5.77094 15.666 9.08594C15.666 12.4009 12.981 15.0859 9.66602 15.0859Z"
                fill={color ?? '#FF0000'}
            />
        </Svg>
    );
};

export default ErrorIcon;
